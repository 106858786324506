<template>
  <main id="home-wrap">
    <q-markup-table>
      <thead>
        <tr>
          <th class="text-left">Source relyance column</th>
          <th class="text-right">Data type</th>
          <th class="text-right">ERP column</th>
          <th class="text-right">Data type</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-left">Lorem ipsome</td>
          <td class="text-right">Dolersit</td>
          <td class="text-right">
            <select class="custom-select">
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </td>
          <td class="text-right">Consectetur</td>
        </tr>
        <tr>
          <td class="text-left">Lorem ipsome</td>
          <td class="text-right">Dolersit</td>
          <td class="text-right">
            <select class="custom-select">
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </td>
          <td class="text-right">Consectetur</td>
        </tr>
        <tr>
          <td class="text-left">Lorem ipsome</td>
          <td class="text-right">Dolersit</td>
          <td class="text-right">
            <select class="custom-select">
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </td>
          <td class="text-right">Consectetur</td>
        </tr>
        <tr>
          <td class="text-left">Lorem ipsome</td>
          <td class="text-right">Dolersit</td>
          <td class="text-right">
            <select class="custom-select">
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </td>
          <td class="text-right">Consectetur</td>
        </tr>
        <tr>
          <td class="text-left">Lorem ipsome</td>
          <td class="text-right">Dolersit</td>
          <td class="text-right">
            <select class="custom-select">
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </td>
          <td class="text-right">Consectetur</td>
        </tr>
      </tbody>
    </q-markup-table>
  </main>
</template>

<style scoped>
#home-wrap {
  padding: 0px;
}
.custom-select {
  border: solid 1px #e9e9e9;
  border-radius: 5px;
  width: 100%;
  padding: 5px 10px;
}
</style>
