<template>
  <!-- Tree Card Actions -->
  <q-card :class="{ ' tree-popup  ': type == 'popup' }">
    <q-card-actions class="filterhead1 tree-label q-pa-sm explore-wrap">
      <q-space></q-space>
      <q-input
        v-model="filter"
        ref="filterRef"
        placeholder="Search"
        class="treeSearch"
        outlined
        dense
        v-if="type !== 'popup' && isSearchOpen"
      >
        <template v-slot:append>
          <q-icon
            name="clear"
            color="grey"
            class="cursor-pointer"
            @click="resetFilter"
          />
        </template>
      </q-input>

      <q-btn
        v-if="isSearchOpen == false"
        flat
        icon="fas fa-search"
        @click="toggleSearch"
        class="explore-btn"
        color="primary"
      >
        <q-tooltip anchor="bottom middle" self="top middle" :offset="[4, 4]"
          >Search</q-tooltip
        >
      </q-btn>
      <q-btn dense flat class="explore-btn" @click="handleSort()">
        <q-icon
          :class="{ sortingDefault: sortDirection === null }"
          :name="
            sortDirection === null
              ? 'fas fa-right-left'
              : sortDirection === 'asc'
              ? 'fas fa-sort-alpha-down'
              : 'fas fa-arrow-up-z-a'
          "
          :color="
            sortDirection === 'asc' || sortDirection === 'desc'
              ? 'secondary'
              : 'primary'
          "
        >
          <q-tooltip v-if="sortDirection === 'asc'">Ascending</q-tooltip>
          <q-tooltip v-if="sortDirection === 'desc'">Descending</q-tooltip>
          <q-tooltip v-if="sortDirection === null"
            >Sort by Last Updated</q-tooltip
          >
        </q-icon>
      </q-btn>
    </q-card-actions>
    <!-- Tree -->
    <q-card-section class="q-pt-none tree-section">
      <spinner-component v-if="loader" type="Tree"></spinner-component>
      <div class="q-pa-md q-gutter-sm">
        <q-tree
          v-if="!loader"
          :nodes="nodes"
          node-key="id"
          @lazy-load="onLazyLoad"
          no-nodes-label="No Data Found"
          no-results-label="No Data Found"
          v-model:expanded="expanded"
        >
          <template v-slot:default-header="props">
            <div class="flex col-12 row items-center">
              <div class="col-12 flex justify-between">
                <div class="row items-center q-pl-none">
                  <div
                    @click.stop="toggleExpandIcon(props.node)"
                    :class="{
                      'selected-node': props.node.id === selected,
                    }"
                  >
                    <q-icon
                      v-if="
                        props.node.level != 2 ||
                        (props.node.expandIcon === true &&
                          props.node.children.length != 0)
                      "
                      :name="
                        props.node.expandIcon
                          ? 'fa-solid fa-caret-down'
                          : 'fa-solid fa-caret-right'
                      "
                      color="grey"
                      size="xs"
                      :class="{
                        ' cursor text-active': props.node.label == selected,
                        ' cursor  ': props.node.label !== selected,
                      }"
                    ></q-icon>
                    {{ props.node.label }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </q-tree>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { SpinnerComponent } from "@/utils";
import { UploadService } from "@/services";
import { useMyStore } from "@/store";
import moment from "moment";
export default {
  props: {
    type: {
      type: String,
    },
  },
  components: {
    SpinnerComponent,
  },
  setup() {
    const filter = ref("");
    const filterRef = ref(null);
    const myStore = useMyStore();
    const route = useRoute();
    return {
      route,
      myStore,
      selected: ref(""),
      loader: ref(true),
      sortDirection: ref(null),
      filter,
      filterRef,
      isSearchOpen: ref(false),
    };
  },
  emits: ["changeSupplier"],
  data() {
    return {
      nodes: [], // Root nodes
      expanded: [], // Tracks expanded nodes
    };
  },
  created() {
    this.fetchRootNode();
  },
  methods: {
    moment(...args) {
      return moment(...args);
    },
    async fetchRootNode() {
      try {
        this.loader = true;
        const response = await UploadService.getSupplierTree();
        this.nodes = response.map((item, index) => ({
          id: `root-${index}`, // Unique ID for root nodes
          label: item,
          lazy: true, // Indicates this node has lazy-loaded children
          children: [], // Initialize children as an empty array
          level: 0,
        }));
        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.error("Error fetching root node:", error);
      }
    },
    async onLazyLoad({ done, fail }) {
      try {
        done([]);
      } catch (error) {
        console.error("Error loading children for node:", error);
        fail();
      }
    },

    async toggleExpandIcon(node) {
      this.selected = node.id;
      // Check if the node is already expanded

      if (this.expanded.includes(node.id)) {
        // Collapse the node
        this.expanded = this.expanded.filter((id) => id !== node.id);
        node.expandIcon = false;
      } else {
        // Expand the node and trigger lazy loading if needed
        if (node.lazy && node.children.length === 0) {
          try {
            let childArray = [];
            if (node.level === 0) {
              const response = await UploadService.getSupplierTree(node.label);
              childArray = response.map((item, index) => ({
                id: `${node.id}-child-${index}`,
                parentId: node.label,
                label: item,
                lazy: true,
                level: 1,
                children: [],
              }));
            } else if (node.level === 1) {
              const response = await UploadService.getSupplierTree(
                node.parentId,
                node.label
              );
              childArray = response.map((item, index) => ({
                id: `${node.id}-child-${index}`,
                parentId: node.parentId,
                label: `${moment(item.start_date).format(
                  "MM-DD-YYYY"
                )} - ${moment(item.end_date).format("MM-DD-YYYY")}`,
                start_date: item.start_date,
                end_date: item.end_date,
                lazy: true,
                level: 2,
                children: [],
              }));
            } else if (node.level === 2) {
              const response = await UploadService.getSupplierTree(
                node.parentId,
                undefined,
                node.start_date,
                node.end_date
              );

              localStorage.setItem(
                "documentSupplierResponse",
                JSON.stringify(response)
              );
              localStorage.setItem("SupplierResponseNodeId", node.id);

              this.$emit("changeSupplier", node.id);
            }
            node.children = childArray; // Attach loaded children to the node
          } catch (error) {
            console.error("Error loading children for node:", error);
          }
        }
        // Expand the node
        if (node.level !== 2) {
          this.expanded.push(node.id);
        }

        if (node.children.length != 0) {
          node.expandIcon = true;
        } else {
          node.expandIcon = false;
        }
      }
    },
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
      if (this.isSearchOpen) {
        this.$nextTick(() => {
          this.$refs.filterRef.focus();
        });
      }
    },
    //Clear Search
    resetFilter() {
      this.filter = "";
      this.filterRef.focus();
      this.isSearchOpen = false;
    },
    handleSort() {
      if (this.sortDirection === null) {
        this.sortDirection = "asc";
      } else if (this.sortDirection === "asc") {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = null;
      }
    },
    includeAllChildren(node) {
      if (node.children) {
        node.children.forEach((child) => {
          this.includeAllChildren(child);
        });
      }
    },
  },

  computed: {
    searchnodes() {
      if (this.filter != "") {
        return this.searchByName(this.nodes, this.filter);
      } else {
        return this.nodes;
      }
    },
    sortedNodes() {
      if (this.treeFilter !== "All") {
        const resultNodes = this.nodes;
        resultNodes.forEach(this.includeAllChildren);
        const sorted = resultNodes;
        sorted.sort((a, b) => {
          const labelA = a.name.toLowerCase();
          const labelB = b.name.toLowerCase();
          if (this.sortDirection === "asc") {
            return labelA.localeCompare(labelB);
          } else {
            return labelB.localeCompare(labelA);
          }
        });
        // return resultNodes
        return sorted;
      } else if (this.filter !== "") {
        const filteredResults = this.searchByPartialName(
          this.nodes,
          this.filter
        );
        return filteredResults;
      } else {
        const sorted = [...this.newtreeNode];
        sorted.sort((a, b) => {
          const labelA = a.name.toLowerCase();
          const labelB = b.name.toLowerCase();
          if (this.sortDirection === "asc") {
            return labelA.localeCompare(labelB);
          } else if (this.sortDirection === "desc") {
            return labelB.localeCompare(labelA);
          } else {
            return this.newtreeNode;
          }
        });

        return sorted;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.selected-node {
  color: #ff9c00;
}
</style>
