<template>
  <div class="">
    <div class="">
      <q-card flat>
        <q-card-section
          class="flex justify-between items-center bg-card-header"
          style="padding: 5px !important"
        >
          <div class="text-h6">Order Details</div>
          <div v-if="closeIcon">
            <q-icon
              name="close"
              @click="toggleClose()"
              size="sm"
              class="cursor-pointer"
            >
              <q-tooltip
                anchor="bottom middle"
                self="top middle"
                :offset="[-2, -2]"
              >
                Close
              </q-tooltip></q-icon
            >
          </div>
        </q-card-section>
        <q-card-section horizontal style="width: 100%">
          <q-card-section
            vertical
            class="my-card-section AslTable q-pa-none"
            style="width: 100%"
          >
            <q-table
              v-if="subTableData"
              flat
              :rows="newsubPartsdata"
              virtual-scroll
              class="my-sticky-virtscroll-table"
              :virtual-scroll-sticky-size-start="3"
              :columns="orderSubFields"
              style="width: 100%"
              dense
              v-model:pagination="pagination"
            >
              <template #header="props">
                <q-tr :props="props" class="bg-color text-center">
                  <th class="text-left">#</th>
                  <q-th
                    v-for="col in props.cols"
                    :key="col.key"
                    class="text-bold"
                  >
                    {{ col.label }}
                  </q-th>
                </q-tr>
              </template>

              <template #body="props">
                <q-tr :class="{ 'bg-yellow': props.row?.type === 'schedules' }">
                  <q-td key="index" class="text-left">
                    <q-icon
                      v-if="props.row?.type === 'shipping_info'"
                      name="local_shipping"
                      size="xs"
                    >
                      <q-tooltip
                        anchor="bottom middle"
                        self="top middle"
                        :offset="[0, 0]"
                      >
                        Shipping Info
                      </q-tooltip>
                    </q-icon>
                    <q-icon
                      v-if="props.row?.type === 'schedules'"
                      name="departure_board"
                      size="xs"
                    >
                      <q-tooltip
                        anchor="bottom middle"
                        self="top middle"
                        :offset="[0, 0]"
                      >
                        Schedule Info
                      </q-tooltip>
                    </q-icon>
                  </q-td>
                  <q-td key="status_msg" class="text-left">
                    {{ props.row?.status_msg }}
                  </q-td>
                  <q-td key="quantity_scheduled" class="text-center">
                    <div v-if="props.row.quantity_shipped">
                      {{ props.row.quantity_shipped }}
                    </div>
                    <div v-if="props.row?.quantity_scheduled">
                      {{ props.row?.quantity_scheduled }}
                    </div>
                  </q-td>
                  <q-td key="dispatch_date" class="text-center">
                    <div v-if="props.row?.shipped_date">
                      {{
                        props.row.shipped_date !== null
                          ? moment(props.row.shipped_date).format("MM-DD-YYYY")
                          : "Date Not Available"
                      }}
                    </div>
                    <div v-if="props.row?.dispatch_date">
                      {{
                        props.row.dispatch_date !== null
                          ? moment(props.row.dispatch_date).format("MM-DD-YYYY")
                          : "Date Not Available"
                      }}
                    </div>
                  </q-td>
                  <q-td key="tracking_number" class="text-left">
                    {{ props.row?.tracking_number }}
                  </q-td>
                  <q-td key="expected_delivery_date" class="text-center">
                    {{
                      props.row.expected_delivery_date !== null
                        ? moment(props.row.expected_delivery_date).format(
                            "MM-DD-YYYY"
                          )
                        : "Date Not Available"
                    }}
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </q-card-section>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useMyStore } from "@/store";
import moment from "moment";
export default {
  props: {
    subTableData: {
      type: Array,
    },
    closeIcon: {
      default: false,
    },
  },

  setup() {
    const orderSubFields = ref([]);
    const myStore = useMyStore();
    const pagination = ref({
      sortBy: "desc",
      descending: false,
      page: 1,
      rowsPerPage: [0],
    });
    return {
      orderSubFields,
      pagination,
      myStore,
      toggleClose1: ref(false),
    };
  },
  computed: {
    newsubPartsdata() {
      return this.subTableData;
    },
  },
  methods: {
    //closing the subtable
    toggleClose() {
      this.toggleClose1 = true;
      this.$emit("toggleClose", this.toggleClose1);
    },

    moment(date) {
      return moment(date);
    },
  },
  mounted() {
    try {
      this.orderSubFields = this.myStore.fields.order_subFields;
    } catch (error) {
      console.error("Error in component:", error);
    }
  },
};
</script>
<style scoped>
.bg-yellow {
  background-color: #f7ffd5 !important;
}
</style>
