<template>
  <!-- Tree Card Actions -->
  <q-card :class="{ ' tree-popup  ': type == 'popup' }">
    <!-- <q-card-actions align="center" class=" filterhead1 tree-label q-pa-xs" v-if="type !== 'popup' && type !== 'Sample'">
      {{myStore.userdata.role.charAt(0).toUpperCase() + myStore.userdata.role.slice(1)}} Document Explorer
    </q-card-actions> -->
    <q-card-actions class="filterhead1 tree-label q-pa-sm explore-wrap">
      <!-- <q-btn-toggle  readonly v-if="!loader" class="q-ml-sm cursor-notAllowed" size="sm" v-model="rolefilter" @update:model-value="model = $event"
        toggle-color="primary" :options="[
          { label: 'All Docs', value: 'AllDocs' },
          { label: `${this.myStore.CommonTitle.PageTitle.TreeFilter != null && this.myStore.CommonTitle.PageTitle.TreeFilter != undefined ? this.myStore.CommonTitle.PageTitle.TreeFilter : ''}`, value: 'myDocs' }
        ]" /> -->
      <q-space></q-space>
      <q-input
        v-model="filter"
        ref="filterRef"
        placeholder="Search"
        class="treeSearch"
        outlined
        dense
        v-if="type !== 'popup' && isSearchOpen"
      >
        <template v-slot:append>
          <q-icon
            name="clear"
            color="grey"
            class="cursor-pointer"
            @click="resetFilter"
          />
        </template>
      </q-input>

      <q-btn
        v-if="isSearchOpen == false"
        flat
        icon="fas fa-search"
        @click="toggleSearch"
        class="explore-btn"
        color="primary"
        :disable="myStore.bom_name == null"
      >
        <q-tooltip anchor="bottom middle" self="top middle" :offset="[4, 4]"
          >Search</q-tooltip
        >
      </q-btn>
      <q-btn
        dense
        flat
        class="explore-btn"
        @click="handleSort()"
        :disable="myStore.bom_name == null"
      >
        <q-icon
          :class="{ sortingDefault: sortDirection === null }"
          :name="
            sortDirection === null
              ? 'fas fa-right-left'
              : sortDirection === 'asc'
              ? 'fas fa-sort-alpha-down'
              : 'fas fa-arrow-up-z-a'
          "
          :color="
            sortDirection === 'asc' || sortDirection === 'desc'
              ? 'secondary'
              : 'primary'
          "
        >
          <q-tooltip v-if="sortDirection === 'asc'">Ascending</q-tooltip>
          <q-tooltip v-if="sortDirection === 'desc'">Descending</q-tooltip>
          <q-tooltip v-if="sortDirection === null"
            >Sort by Last Updated</q-tooltip
          >
        </q-icon>
      </q-btn>
      <q-btn
        flat
        dense
        icon="fa-solid fa-filter"
        :disable="myStore.bom_name == null || myStore.userdata.role == 'buyer'"
        :color="treeFilter !== 'All' ? 'secondary' : 'primary'"
        class="explore-btn"
      >
        <q-menu>
          <q-list
            dense
            v-for="(Labelkey, index) in TreeFilterJson"
            :key="index"
            :class="
              Labelkey.is_primary ? 'selectedFilterItem' : 'NonSelectedItem'
            "
          >
            <q-item
              clickable
              v-close-popup
              @click="onItemClick(Labelkey.value)"
              class="q-pa-none"
            >
              <q-item-section>
                <q-item-label>
                  <span class="q-mr-xs">
                    <q-btn
                      rounded
                      dense
                      class="TreeFilterSpinner"
                      style="font-size: 7px !important"
                      filled
                      v-if="Labelkey.id == 3"
                    >
                      <q-spinner-dots
                        size="1em"
                        color="white"
                        style="font-size: 11px !important"
                      >
                      </q-spinner-dots>
                    </q-btn>
                    <q-btn
                      rounded
                      dense
                      class="TreeFilterSpinner"
                      style="font-size: 7px !important"
                      filled
                      v-else-if="Labelkey.value == 'nosourcing'"
                    >
                      <q-icon
                        size="1em"
                        color="white"
                        name="more_horiz"
                        style="font-size: 11px !important"
                      >
                      </q-icon>
                    </q-btn>
                    <q-icon
                      v-else
                      :name="Labelkey.icon"
                      size="xs"
                      class="TreeStatusicons"
                      :color="Labelkey.color"
                    ></q-icon>
                  </span>
                  {{ Labelkey.label }}</q-item-label
                >
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
        <q-tooltip
          v-if="myStore.userdata.role == 'quoter'"
          anchor="bottom middle"
          self="top middle"
          :offset="[6, 6]"
          >Filter Based on Quote Status
        </q-tooltip>
      </q-btn>
    </q-card-actions>
    <!-- Tree -->
    <q-card-section class="q-pt-none tree-section">
      <spinner-component v-if="loader" type="Tree"></spinner-component>
      <!-- <q-scroll-area :delay="1200" style="height: 370px; max-width: 100%;"> -->
      <q-tree
        v-if="!loader && sortedNodes"
        :nodes="sortedNodes"
        node-key="name"
        accordion
        label-key="name"
        selected-color="secondary"
        class="selectedBOM"
        id="No-data"
        v-model:expanded="expanded"
        ref="menuTree"
      >
        <template v-slot:default-header="props">
          <div class="flex col-12 row items-center">
            <div
              class="col-12 flex justify-between"
              :class="{
                'selected-rowTree rounded-borders':
                  props.node.name == selected && props.node.type == 'iteration',
                'all-rowTree':
                  props.node.name !== selected &&
                  props.node.type == 'iteration',
              }"
            >
              <!-- Before Node Icons -->
              <div
                class="row items-center"
                :class="{ ' q-pa-none   q-pl-none': type == 'upload' }"
              >
                <q-icon
                  v-if="props.node.type == 'oem' && props.node.length"
                  :name="
                    props.node.expandIcon == true
                      ? 'fa-solid fa-caret-down'
                      : 'fa-solid fa-caret-right'
                  "
                  color="grey"
                  size="xs"
                  :class="{
                    ' cursor text-active': props.node.name == selected,
                    ' cursor  ': props.node.name !== selected,
                  }"
                  @click="getBomName(props.node)"
                ></q-icon>
                <q-icon
                  v-if="props.node.type == 'product' && props.node.length"
                  :name="
                    props.node.expandIcon == true
                      ? 'fa-solid fa-caret-down'
                      : 'fa-solid fa-caret-right'
                  "
                  color="grey"
                  size="xs"
                  :class="{
                    ' cursor text-active': props.node.name == selected,
                    ' cursor  ': props.node.name !== selected,
                  }"
                  @click="getBomName(props.node)"
                ></q-icon>
                <q-icon
                  v-if="props.node.type == 'iteration' && props.node.length"
                  name="fa-solid fa-caret-down"
                  :color="props.node.name == selected ? 'secondary' : 'grey'"
                  size="xs"
                  :class="{
                    ' cursor text-active': props.node.name == selected,
                    ' cursor  ': props.node.name !== selected,
                  }"
                  @click="getBomName(props.node)"
                ></q-icon>
                <q-icon
                  :name="props.node.icon || 'share'"
                  size="15px"
                  :color="
                    props.node.name === selected ? 'secondary' : 'primary'
                  "
                  :class="{
                    'q-mr-xs cursor text-active': props.node.name == selected,
                    'q-mr-xs cursor  ': props.node.name !== selected,
                    'display-none  ':
                      props.node.type == 'iteration' &&
                      (type == 'upload' || type == 'quotes'),
                  }"
                  @click="getBomName(props.node)"
                />
                <q-avatar
                  v-if="props.node.type == 'iteration'"
                  :class="
                    props.node.is_part_list == true
                      ? 'buyer_avatar'
                      : 'quoter_avatar'
                  "
                >
                  <!-- {{ props.node.is_part_list == true ? 'B' : 'Q' }} -->
                  <q-icon
                    :name="
                      props.node.is_part_list == true
                        ? 'fas fa-cart-plus'
                        : 'fas fa-chart-simple'
                    "
                    class="TreeStatusicons"
                  ></q-icon>
                  <q-tooltip
                    v-if="props.node.type === 'iteration'"
                    anchor="center left"
                    self="center right"
                    :offset="[-2, -2]"
                    >{{
                      props.node.type == "iteration" &&
                      (props.node.is_part_list ? "Buyer" : "Quoter")
                    }}</q-tooltip
                  >
                </q-avatar>
                <span>
                  <div
                    class="tree-selected"
                    style="overflow: visible !important"
                  >
                    <a
                      :class="{
                        '  text-secondary cursor tree-selected':
                          props.node.name == selected,
                        '  text-primary cursor nonselected-rowTree':
                          props.node.name !== selected,
                        'q-ml-sm ': props.node.type === 'iteration',
                      }"
                      @click="getBomName(props.node)"
                      >{{ props.node.name }}

                      <q-tooltip anchor="center right" self="center left">{{
                        props.node.name
                      }}</q-tooltip>

                      <q-btn
                        v-if="
                          props.node.notification_count > 0 &&
                          props.node.type === 'iteration'
                        "
                        icon="notifications_unread"
                        dense
                        round
                        flat
                        color="primary"
                      >
                        <q-badge
                          v-if="
                            props.node.notification_count > 0 &&
                            props.node.type === 'iteration'
                          "
                          color="red"
                          floating
                          transparent
                          >{{ props.node.notification_count }}</q-badge
                        >
                      </q-btn>
                    </a>
                  </div>
                  <div
                    v-if="props.node.type == 'iteration'"
                    class="q-ml-sm q-my-xs"
                    caption
                    style="font-size: 12px; color: grey"
                  >
                    <q-icon
                      v-if="
                        props.node.type == 'iteration' &&
                        (type == 'upload' || type == 'quotes') &&
                        props.node.lifecycle_status == 'Quoted' &&
                        props.node.order_status === 'Processing'
                      "
                      rounded
                      class="TreeStatusButtons TreeStatusicons"
                      flat
                      dense
                      color="teal"
                      name="fas fa-clock"
                      @click="getBomName(props.node)"
                    >
                      <q-tooltip
                        anchor="bottom middle"
                        self="top middle"
                        :offset="[-2, -2]"
                        >Quote Dispatched, awaiting order
                        confirmation</q-tooltip
                      >
                    </q-icon>
                    <q-icon
                      v-if="
                        props.node.type == 'iteration' &&
                        (type == 'upload' || type == 'quotes') &&
                        props.node.lifecycle_status === 'Quoted' &&
                        props.node.order_status === 'Lost'
                      "
                      color="red-3"
                      name="fa-solid fa-circle-xmark"
                      @click="getBomName(props.node)"
                      dense
                      rounded
                      class="TreeStatusButtons TreeStatusicons"
                      flat
                    >
                      <q-tooltip
                        anchor="bottom middle"
                        self="top middle"
                        :offset="[-2, -2]"
                        >Quote Lost
                      </q-tooltip>
                    </q-icon>
                    <q-icon
                      v-if="
                        props.node.type == 'iteration' &&
                        (type == 'upload' || type == 'quotes') &&
                        props.node.lifecycle_status == 'Quoted' &&
                        props.node.order_status === 'Won'
                      "
                      color="light-green"
                      name="fa-solid fa-circle-check"
                      @click="getBomName(props.node)"
                      dense
                      rounded
                      class="TreeStatusButtons TreeStatusicons"
                    >
                      <q-tooltip
                        anchor="bottom middle"
                        self="top middle"
                        :offset="[-2, -2]"
                        >Quote Won</q-tooltip
                      >
                    </q-icon>
                    <q-btn
                      v-if="
                        props.node.type == 'iteration' &&
                        (type == 'upload' || type == 'quotes') &&
                        props.node.lifecycle_status != 'Quoted' &&
                        props.node.lifecycle_status != 'In Sourcing' &&
                        props.node.lifecycle_status != 'In Quoting'
                      "
                      @click="getBomName(props.node)"
                      dense
                      rounded
                      class="TreeSpinnerButtons"
                    >
                      <q-icon
                        color="white"
                        class="TreeSpinner"
                        name="more_horiz"
                        size="14px"
                      >
                      </q-icon>
                      <q-tooltip
                        anchor="bottom middle"
                        self="top middle"
                        :offset="[-2, -2]"
                        >Not yet Sourcing</q-tooltip
                      >
                    </q-btn>
                    <q-btn
                      rounded
                      dense
                      class="TreeSpinnerButtons"
                      color="orange-4"
                      filled
                      @click="getBomName(props.node)"
                      v-if="
                        props.node.type == 'iteration' &&
                        (type == 'upload' || type == 'quotes') &&
                        (props.node.lifecycle_status == 'In Sourcing' ||
                          props.node.lifecycle_status == 'In Quoting')
                      "
                    >
                      <q-spinner-dots
                        size="1em"
                        color="white"
                        class="TreeSpinner"
                      >
                      </q-spinner-dots>
                      <q-tooltip
                        anchor="bottom middle"
                        self="top middle"
                        :offset="[-2, -2]"
                        >{{
                          props.node.lifecycle_status == "In Sourcing"
                            ? "Sourcing"
                            : "Quoting"
                        }}
                        In Progress</q-tooltip
                      >
                    </q-btn>
                    <span class="q-ml-xs" @click="getBomName(props.node)">{{
                      props.node.lifecycle_status == "In BOM Extract"
                        ? props.node.is_part_list == false
                          ? props.node.lifecycle_status
                          : "In Part List Extract"
                        : props.node.lifecycle_status
                    }}</span>
                  </div>
                </span>
                <!-- Action Buttons -->
                <q-btn
                  v-if="type == 'upload' && props.node.name == this.selected"
                  flat
                  dense
                  icon="more_vertical"
                >
                  <q-menu>
                    <q-list dense>
                      <q-item
                        clickable
                        @click="openPopup(props.node.type, 'edit')"
                        :disable="
                          (myStore.userdata.role == 'quoter' &&
                            props.node.is_part_list == true) ||
                          (myStore.userdata.role == 'buyer' &&
                            props.node.is_part_list == false)
                        "
                        class="q-pa-none"
                      >
                        <q-item-section>
                          <q-item-label>
                            <span class="q-mr-xs">
                              <q-btn
                                dense
                                flat
                                @click="openPopup(props.node.type, 'edit')"
                                class="q-mr-xs"
                                color="primary"
                                icon="fas fa-edit"
                              >
                              </q-btn> </span
                            >Edit
                          </q-item-label>
                        </q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        @click="
                          deleteNode([
                            props.node.type,
                            props.node.lifecycle_status,
                          ])
                        "
                        :disable="
                          (myStore.userdata.role == 'quoter' &&
                            props.node.is_part_list == true) ||
                          (myStore.userdata.role == 'buyer' &&
                            props.node.is_part_list == false)
                        "
                        class="q-pa-none"
                      >
                        <q-item-section>
                          <q-item-label>
                            <span class="q-mr-xs">
                              <q-btn
                                dense
                                flat
                                size="xs"
                                class="q-mr-xs"
                                color="negative"
                                icon="delete"
                              >
                              </q-btn> </span
                            >Delete
                          </q-item-label>
                        </q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        :disable="
                          (myStore.userdata.role == 'quoter' &&
                            props.node.is_part_list == true) ||
                          (myStore.userdata.role == 'buyer' &&
                            props.node.is_part_list == false)
                        "
                        @click="copyIteration('copy', props.node.name)"
                        class="q-pa-none"
                        v-if="
                          props.node.name == this.selected &&
                          props.node.type == 'iteration'
                        "
                      >
                        <q-item-section>
                          <q-item-label>
                            <span class="q-mr-xs">
                              <q-btn
                                dense
                                flat
                                @click="copyIteration('copy', props.node.name)"
                                class="q-mr-xs"
                                color="grey"
                                icon="fa-regular fa-copy"
                              >
                              </q-btn> </span
                            >Copy
                          </q-item-label>
                        </q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        :disable="
                          (myStore.userdata.role == 'quoter' &&
                            props.node.is_part_list == true) ||
                          (myStore.userdata.role == 'buyer' &&
                            props.node.is_part_list == false)
                        "
                        @click="
                          openPopup(props.node.type, 'move', props.node.name)
                        "
                        class="q-pa-none"
                        v-if="
                          (props.node.name == this.selected &&
                            props.node.type == 'iteration') ||
                          props.node.type === 'product'
                        "
                      >
                        <q-item-section>
                          <q-item-label>
                            <q-btn
                              dense
                              flat
                              @click="
                                openPopup(
                                  props.node.type,
                                  'move',
                                  props.node.name
                                )
                              "
                              class="q-mr-xs"
                              icon="fa fa-circle-arrow-right"
                              color="secondary"
                            >
                            </q-btn>
                            <span class="q-mr-xs"> Move</span>
                          </q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                    <!-- selected All -->

                    <!-- Selected Iteration -->
                  </q-menu>
                </q-btn>
              </div>
            </div>
          </div>
        </template>
      </q-tree>
      <!-- </q-scroll-area> -->
    </q-card-section>
  </q-card>
  <!-- Confirm Alert -->
  <q-dialog v-model="dialog" persistent>
    <div
      :class="{
        'popupcomponent  gdialog': commonpopupType === 'edit',
        'movepopup q-gutter-lg gdialog': commonpopupType === 'move',
      }"
    >
      <PopupComponent
        v-if="commonpopupType === 'edit'"
        popupType="edit"
        :editNode="selected"
        :node="bomType[0]"
        :close="closeDialog"
      />
      <MoveRfqPopup
        v-if="commonpopupType === 'move'"
        :close="closeDialog"
        :bomType="bomType"
        :currentoem="currentoem"
      />
      <CopyRfq v-if="commonpopupType === 'copy'" @copyButton="copyButton" />
    </div>
  </q-dialog>
  <q-dialog v-model="confirm">
    <q-card class="gdialog card-outline-primary confirm-popup">
      <q-card-section class="card-header-section1">
        <div v-if="citeration" class="text-h6 q-ml-sm">
          <q-icon
            flat
            dense
            name="report_problem"
            size="xs"
            class="q-mb-xs"
            color="warning"
          ></q-icon
          ><span class="q-ml-sm title-md"
            >Copy of
            {{
              myStore.partListStatus == false
                ? this.myStore.treeLabels[2]
                : this.myStore.treeLabels[3]
            }}
          </span>
        </div>
        <div v-if="!citeration && bomType[1] === 'New'" class="text-h6 q-ml-sm">
          <q-icon
            flat
            dense
            name="report_problem"
            color="negative"
            size="xs"
            class="q-mb-xs"
          ></q-icon
          ><span class="q-ml-sm delete-head"
            >Delete
            {{
              myStore.partListStatus == false
                ? this.myStore.treeLabels[2]
                : this.myStore.treeLabels[3]
            }}
          </span>
        </div>
        <div v-if="!citeration && bomType[1] !== 'New'" class="text-h6 q-ml-sm">
          <q-icon
            name="report_problem"
            flat
            dense
            color="negative"
            size="xs"
            class="q-mb-xs"
          ></q-icon
          ><span class="q-ml-sm delete-head"
            >Force Delete
            {{
              bomType[0] == "oem"
                ? "Customer"
                : bomType[0] == "product"
                ? "Assembly"
                : myStore.partListStatus == false
                ? this.myStore.treeLabels[2]
                : this.myStore.treeLabels[3]
            }}
          </span>
        </div>
      </q-card-section>

      <q-card-section class="descrpn-md">
        <span v-if="citeration"
          >Make a copy of all the documents in
          {{
            myStore.partListStatus == false
              ? this.myStore.treeLabels[2]
              : this.myStore.treeLabels[3]
          }}
          {{ selected }} ?</span
        >
        <span v-if="!citeration && bomType[1] === 'New'"
          >Are you sure, you want to delete this {{ selected }} ?</span
        >
        <span v-if="!citeration && bomType[1] !== 'New'"
          >Are you sure, you want to Force delete this {{ selected }} ?</span
        >
      </q-card-section>
      <q-card-actions align="right">
        <q-btn label="Ok" outline class="quote-btn" @click="handleConfirm" />
        <q-btn label="Cancel" outline class="quote-btn" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { SpinnerComponent, commonFunction } from "../utils";
import { AlertService, SettingService, UploadService } from "../services";
import PopupComponent from "./PopupComponent.vue";
import CopyRfq from "./CopyRfq.vue";
import MoveRfqPopup from "./MoveRfqPopup";
import { useMyStore } from "@/store";
export default {
  // props: ['type',"oem"],
  props: {
    type: {
      type: String,
    },
  },
  components: {
    SpinnerComponent,
    PopupComponent,
    MoveRfqPopup,
    CopyRfq,
  },
  setup() {
    const filter = ref("");
    const filterRef = ref(null);
    const myStore = useMyStore();
    const route = useRoute();
    return {
      route,
      myStore,
      treeNode: ref([]),
      TreeFilterJson: ref([]),
      selected: ref(""),
      expanded: ref([]),
      loader: ref(true),
      dialog: ref(false),
      citeration: ref(false),
      treeFilter: ref("All"),
      rolefilter: ref(localStorage.getItem("DocumentFilter")),
      sortDirection: ref(null),
      filter,
      filterRef,
      isSearchOpen: ref(false),
      confirm: ref(false),
      bomType: ref([]),
      commonpopupType: ref(""),
      bomName: ref(localStorage.getItem("bom_name")),
    };
  },
  watch: {
    updateNodeTrigger() {
      this.getTree();
    },
    // Status changed call tree
    refreshpricing() {
      this.filter = "";
      this.isSearchOpen = false;
      this.treeFilter = "All";
      this.TreeFilterJson.forEach((filt) => {
        if (filt.value === this.treeFilter) {
          filt.is_primary = true;
        } else {
          filt.is_primary = false;
        }
      });
      this.getTree();
    },
    GlobalQty(new1, old1) {
      if (new1 != old1 && new1 !== null) {
        localStorage.setItem("qty", new1);
      }
    },
    selected(new1, old1) {
      if (new1 != old1 && new1 !== null) {
        this.myStore.copyerrmsg = "";
      }
    },
    rolefilter(newval) {
      if (newval) {
        this.treeFilter = "All";
        this.TreeFilterJson.forEach((filt) => {
          if (filt.value === this.treeFilter) {
            filt.is_primary = true;
          } else {
            filt.is_primary = false;
          }
        });
        localStorage.setItem("DocumentFilter", newval);
        localStorage.setItem("bom_name", null);
        localStorage.setItem("qty", null);
        localStorage.setItem("ipn", null);
        this.getTree();
      }
    },
  },
  computed: {
    // Status changed - icon changing
    refreshpricing() {
      return this.myStore.refreshpricing;
    },
    // Set primary qty when iteration changed
    GlobalQty() {
      return this.myStore.GlobalQty;
    },

    newtreeNode() {
      //oem
      let dataoem = this.treeNode.map((obj, index) => {
        // push if child and expand
        if (
          obj.type == "oem" &&
          obj.children.length > 0 &&
          this.expanded.includes(obj.name)
        ) {
          obj.icon = "factory";
          obj.color = "";
          obj.uniqueId = obj.name + index;
          obj.length = true;
          obj.expandIcon = true;
        }
        // if it is not expand
        else if (
          obj.type == "oem" &&
          obj.children.length > 0 &&
          !this.expanded.includes(obj.name)
        ) {
          obj.icon = "factory";
          obj.color = "";
          obj.uniqueId = obj.name + index;
          obj.length = true;
          obj.expandIcon = false;
        }
        // default
        obj.icon = "factory";
        obj.color = "";
        obj.uniqueId = obj.name + index;
        //product
        obj.children.map((obj1, index) => {
          // push if child and expand
          if (
            obj1.type == "product" &&
            obj1.children.length > 0 &&
            this.expanded.includes(obj1.name)
          ) {
            obj1.icon = "book";
            obj1.length = true;
            obj1.expandIcon = true;
            obj1.color = "";
            obj1.uniqueId = obj1.name + index + 1;
          }
          // if it is not expand
          else if (
            obj1.type == "product" &&
            obj1.children.length > 0 &&
            !this.expanded.includes(obj1.name)
          ) {
            obj1.icon = "book";
            obj1.length = true;
            obj1.expandIcon = false;
            obj1.color = "";
            obj1.uniqueId = obj1.name + index + 1;
          }
          // default
          obj1.icon = "book";
          obj1.color = "";
          obj1.uniqueId = obj1.name + index + 1;

          //iteration
          obj1.children.map((obj, index) => {
            if (obj.name == "-" && obj.type == "iteration") {
              obj1.children = [];
              obj1.length = false;
            } else {
              obj.icon = "login";
              obj.color = "";
              obj.type = "iteration";
              obj.uniqueId = obj.name + index + 2;
            }
            return obj;
          });
          return obj;
        });
        return obj;
      });

      return dataoem;
    },
    searchnodes() {
      if (this.filter != "") {
        return this.searchByName(this.newtreeNode, this.filter);
      } else {
        return this.newtreeNode;
      }
    },
    sortedNodes() {
      if (this.treeFilter !== "All") {
        const resultNodes = this.filterData(this.newtreeNode);
        resultNodes.forEach(this.includeAllChildren);
        const sorted = resultNodes;
        sorted.sort((a, b) => {
          const labelA = a.name.toLowerCase();
          const labelB = b.name.toLowerCase();
          if (this.sortDirection === "asc") {
            return labelA.localeCompare(labelB);
          } else {
            return labelB.localeCompare(labelA);
          }
        });
        // return resultNodes
        return sorted;
      } else if (this.filter !== "") {
        const filteredResults = this.searchByPartialName(
          this.newtreeNode,
          this.filter
        );
        return filteredResults;
      } else {
        const sorted = [...this.newtreeNode];
        sorted.sort((a, b) => {
          const labelA = a.name.toLowerCase();
          const labelB = b.name.toLowerCase();
          if (this.sortDirection === "asc") {
            return labelA.localeCompare(labelB);
          } else if (this.sortDirection === "desc") {
            return labelB.localeCompare(labelA);
          } else {
            return this.newtreeNode;
          }
        });

        return sorted;
      }
    },
  },
  created() {
    this.getTree();
  },

  methods: {
    //Filter Tree based on Quote Order Status and Lifecycle Status
    filterData(data) {
      const filteredData = [];

      data.forEach((oem) => {
        const filteredOEM = { ...oem };
        filteredOEM.children = [];

        oem.children.forEach((product) => {
          const filteredProduct = { ...product };
          filteredProduct.children = [];

          product.children.forEach((iteration) => {
            //if click won
            if (this.treeFilter == "won") {
              if (
                iteration.order_status === "Won" &&
                iteration.lifecycle_status === "Quoted"
              ) {
                filteredProduct.children.push(iteration);
              }
            }
            //if click lost
            if (this.treeFilter == "lost") {
              if (
                iteration.order_status === "Lost" &&
                iteration.lifecycle_status === "Quoted"
              ) {
                filteredProduct.children.push(iteration);
              }
            }
            //if click await
            if (this.treeFilter == "await") {
              if (
                iteration.order_status === "Processing" &&
                iteration.lifecycle_status === "Quoted"
              ) {
                filteredProduct.children.push(iteration);
              }
            }
            //if click Progress
            if (this.treeFilter == "sourceProgress") {
              var lifestatus = ["In Sourcing"];
              if (lifestatus.includes(iteration.lifecycle_status)) {
                filteredProduct.children.push(iteration);
              }
            }
            if (this.treeFilter == "quoteProgress") {
              var quotestatus = ["In Quoting"];
              if (quotestatus.includes(iteration.lifecycle_status)) {
                filteredProduct.children.push(iteration);
              }
            }
            if (this.treeFilter == "nosourcing") {
              var status = ["New", "In RFQ", "In BOM Extract"];
              if (status.includes(iteration.lifecycle_status)) {
                filteredProduct.children.push(iteration);
              }
            }
          });

          if (filteredProduct.children.length > 0) {
            filteredOEM.children.push(filteredProduct);
          }
        });

        if (filteredOEM.children.length > 0) {
          filteredData.push(filteredOEM);
        }
      });

      return filteredData;
    },

    includeAllChildren(node) {
      if (node.children) {
        node.children.forEach((child) => {
          this.includeAllChildren(child);
        });
      }
    },
    searchByPartialName(jsonData, searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      function filterAndMapNode(node) {
        let found = node.name.toLowerCase().includes(lowerCaseSearchTerm);

        let filteredChildren = node.children
          ? node.children.map(filterAndMapNode).filter(Boolean)
          : [];

        // If an OEM matches the search term, include all its products and iterations
        if (found && node.type === "oem" && node.children) {
          filteredChildren = node.children; // Include all products and their iterations
        }
        if (found && node.type === "product" && node.children) {
          filteredChildren = node.children; // Include all iterations
        }

        if (filteredChildren.length > 0 || found) {
          return {
            ...node,
            children: filteredChildren,
          };
        }

        return null;
      }

      return jsonData.map(filterAndMapNode).filter(Boolean);
    },
    //Tree Filter based on Status
    onItemClick(value) {
      this.TreeFilterJson.forEach((qtyVal) => {
        if (qtyVal.value === value) {
          qtyVal.is_primary = true;
        } else {
          qtyVal.is_primary = false;
        }
      });

      this.treeFilter = value;
    },
    //Make a copy of Iteration
    copyIteration(type, name) {
      this.commonpopupType = type;
      this.myStore.NIter.iteration = name;
      // this.citeration = true
      this.dialog = true;
    },
    copyButton(type) {
      console.log(type);
      if (this.myStore.NIter.iteration === "") {
        this.dialog = true;
      } else {
        this.confirm = true;
        this.citeration = true;
      }
    },
    async makeCopyIteration() {
      await UploadService.copyOfIteration(
        this.selected,
        this.myStore.NIter.iteration
      )
        .then((x) => {
          AlertService.Success(x);
          localStorage.setItem("bom_name", this.myStore.NIter.iteration);
          this.getTree();
          this.confirm = false;
          this.dialog = false;
        })
        .catch((err) => {
          this.confirm = false;
          this.myStore.copyerrmsg = err;
        });
    },
    //Tree Sorting Function
    handleSort() {
      if (this.sortDirection === null) {
        this.sortDirection = "asc";
      } else if (this.sortDirection === "asc") {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = null;
      }
    },

    //open Confirmation dialog
    openPopup(type, popuptype, name) {
      this.commonpopupType = popuptype;
      this.dialog = true;
      this.bomType = [type, name];
    },
    //after node updated callback function
    // updated(newData) {
    //   this.newUpdatedData = newData;
    // },
    //click search open & close
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
      if (this.isSearchOpen) {
        this.$nextTick(() => {
          this.$refs.filterRef.focus();
        });
      }
    },
    //Clear Search
    resetFilter() {
      this.filter = "";
      this.filterRef.focus();
      this.isSearchOpen = false;
    },
    //Close Confirmation
    closeDialog() {
      this.dialog = false;
    },
    //Handling Confirmation popup
    async handleConfirm() {
      try {
        // Determine which update function to call based on the condition
        if (this.citeration) {
          this.makeCopyIteration();
        } else {
          this.handleDelete();
        }

        // Close the dialog after the action is completed
        this.confirm = false;
      } catch {
        // Handle errors if needed
        console.error("Error in Function");
      }
    },
    //Delete Node - api call
    async deleteNode(type) {
      this.bomType = type;
      this.citeration = false;
      // Show Quasar Dialog
      this.confirm = true;
    },
    async handleDelete() {
      try {
        if (this.bomType[0] === "oem") {
          await UploadService.deleteCustomer(this.selected);
        } else if (this.bomType[0] === "product") {
          await UploadService.deleteProduct(this.selected);
        } else {
          const body = {
            force_delete: this.bomType[1] !== "New" ? true : false,
          };
          console.log("body", body);
          await UploadService.deleteIteration(this.selected, body);
        }

        AlertService.Success("Delete successful");
        this.confirm = false;
        localStorage.setItem("qty", null);
        localStorage.setItem("bom_name", null);
        this.getTree();
      } catch (err) {
        AlertService.Error(err);
        this.confirm = false;
      }
    },
    //Getting Tree Data From server
    getTree() {
      return new Promise((resolve, reject) => {
        this.loader = true;
        UploadService.getStaticTree()
          .then((x) => {
            // if (this.rolefilter == 'myDocs') {
            //   this.treeNode = x.map(customer => ({
            //     ...customer,
            //     children: customer.children.map(product => ({
            //       ...product,
            //       children: product.children.filter(iteration => (this.myStore.userdata.role === 'buyer' && iteration.is_part_list) ||
            //         (this.myStore.userdata.role === 'quoter' && !iteration.is_part_list))
            //     }))
            //   }));
            // } else {
            this.treeNode = x;
            // }

            const selectedNode = localStorage.getItem("bom_name");
            //find First iteration
            if (selectedNode === "null" || selectedNode == null) {
              for (const rootNode of this.treeNode) {
                if (rootNode.type === "oem") {
                  const iterationName =
                    commonFunction.findFirstIterationInOEM(rootNode);
                  if (iterationName) {
                    this.$emit("changeBom_name", iterationName);
                    localStorage.setItem("bom_name", iterationName);
                    this.myStore.loadQuotes();
                    this.selected = iterationName;
                    this.myStore.NIter.iteration = iterationName;

                    break; // once node found
                  }
                }
              }
              //First Node Expand
              const filteredOemProductNames =
                commonFunction.filterOemProductNamesWithIteration(
                  this.treeNode
                );
              this.myStore.NOEM = filteredOemProductNames[0];
              this.myStore.NProduct.product = filteredOemProductNames[1];
              localStorage.setItem(
                "expandedNodes",
                JSON.stringify(filteredOemProductNames.slice(0, 2))
              );
              const nodes =
                JSON.parse(localStorage.getItem("expandedNodes")) || [];
              this.expanded = nodes;
            } else {
              this.selected = selectedNode;
              this.$emit("changeBom_name", this.selected);
              const expandedNodes =
                JSON.parse(localStorage.getItem("expandedNodes")) || [];
              this.expanded = expandedNodes;
            }
            const expandNode = localStorage.getItem("expandedNodes");
            if (expandNode === "null") {
              const bom = localStorage.getItem("bom_name");
              const resultNodes = commonFunction.findNodesOemProduct(
                this.treeNode,
                bom
              );
              if (resultNodes.oem && resultNodes.product) {
                this.myStore.NOEM = resultNodes.oem.name;
                this.myStore.NProduct.product = resultNodes.product.name;
                this.myStore.NIter = {
                  oem: resultNodes.oem.name,
                  product: resultNodes.product.name,
                  iteration: bom,
                };
                var r = [];
                r.push(resultNodes.oem.name), r.push(resultNodes.product.name);
                localStorage.setItem("expandedNodes", JSON.stringify(r));
              } else {
                console.log("No data found for the given iteration name.");
              }
            }
            const expandedNodes =
              JSON.parse(localStorage.getItem("expandedNodes")) || [];
            this.expanded = expandedNodes;
            // this.selected = selectedNode;
            // this.$emit("changeBom_name", this.selected);

            this.loader = false;
            resolve();
          })
          .catch((err) => {
            console.log(err);
            this.loader = false;
            document.getElementById("No-data").innerHTML = "No Data Available";
            reject(err);
          });
      });
    },
    //click to action on Node type iteraion
    async getBomName(node) {
      if (node.type === "product" && this.type === "upload") {
        const resultNodes = commonFunction.findNodesOemProduct(
          this.treeNode,
          node.name
        );
        if (resultNodes.oem) {
          this.currentoem = resultNodes.oem.name;
          this.myStore.NOEM = resultNodes.oem.name;
          this.myStore.NProduct = {
            oem: resultNodes.oem.name,
            product: node.name,
            Nodetype: node.type,
          };
        }
      }
      if (node.type === "iteration") {
        // localStorage.setItem("qty", null);
        localStorage.setItem("qty", null);
        this.selected = node.name;
        const resultNodes = commonFunction.findNodesOemProduct(
          this.treeNode,
          node.name
        );
        if (resultNodes.oem && resultNodes.product) {
          this.myStore.NOEM = resultNodes.oem.name;
          this.myStore.NProduct.product = resultNodes.product.name;
          this.myStore.NIter = {
            oem: resultNodes.oem.name,
            product: resultNodes.product.name,
            iteration: node.name,
            Nodetype: node.type,
          };
          var r = [];
          r.push(resultNodes.oem.name), r.push(resultNodes.product.name);
          localStorage.setItem("expandedNodes", JSON.stringify(r));
        } else {
          console.log("No data found for the given iteration name.");
        }
        this.myStore.bom_name = node.name;
        this.myStore.partListStatus = node.is_part_list;
        localStorage.setItem("bom_name", node.name);
        if (node.lifecycle_status == "New") {
          var bomqty = [];
          bomqty.push(node.name);
          bomqty.push(null);
          localStorage.setItem("bomname-qty", JSON.stringify(bomqty));
        }

        localStorage.setItem("qty", null);
        var quotes = {
          GroupBy: "none",
          GroupName: "None",
          Filter: { name: "All", value: "all", id: 1, selected: false },
          Filter_Field: "",
        };
        localStorage.setItem("quotespage", JSON.stringify(quotes));
        this.myStore.loadQuotes();
        if (this.type !== "upload") {
          this.myStore.isCardVisible = false;
          this.myStore.isToastVisible = false;
        }
        this.$emit("changeBom_name", node.name);
      } else {
        this.selected = node.name;
      }
    },
  },
  mounted() {
    this.getTree()
      .then(() => {
        const iterationQueryParam = this.route.query.iteration;
        if (iterationQueryParam) {
          // If the 'iteration' query parameter exists, call the findNodesForIteration function
          const iterationName = decodeURIComponent(iterationQueryParam);
          const resultNodes = commonFunction.findNodesOemProduct(
            this.treeNode,
            iterationName
          );
          if (resultNodes.oem && resultNodes.product) {
            var r = [];
            r.push(resultNodes.oem.name), r.push(resultNodes.product.name);
            localStorage.setItem("expandedNodes", JSON.stringify(r));
            const nodes =
              JSON.parse(localStorage.getItem("expandedNodes")) || [];
            this.expanded = nodes;
          }
          localStorage.setItem("bom_name", this.route.query.iteration);
          this.selected = this.route.query.iteration;
        } else {
          this.selected = localStorage.getItem("bom_name");
        }
      })
      .catch((error) => {
        // Handle error if getTree fails
        console.log("Error fetching tree data:", error);
      });
  },
  beforeMount() {
    SettingService.ViewFilters().then((x) => {
      this.TreeFilterJson = x;
    });
  },
};
</script>
