<template>
  <spinner-component v-if="loader" type="Table"></spinner-component>
  <q-card ref="fullscreenTarget" class="hierarchyCard">
    <q-card-actions class="filterhead table-card-actions" v-if="!loader">
      <div style="display: none">{{ data }}</div>
      <q-btn
        class="round-icon-btn"
        @click="toggleFullscreen1"
        flat
        round
        dense
        :icon="toggleicon()"
      >
        <q-tooltip
          anchor="bottom middle"
          self="top middle"
          :offset="[-2, -2]"
          >{{
            toggleFullscreenTip
              .replace("to", "")
              .replace("enter", "Enter")
              .replace("exit", "Exit")
          }}</q-tooltip
        ></q-btn
      >
      <q-btn
        round
        dense
        flat
        class="cursor-pointer round-icon-btn quote-refresh"
        icon="question_mark"
      >
        <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]"
          >Keyboard Shortcuts</q-tooltip
        >
        <q-menu class="keyboard-shortcut-menu">
          <div class="q-pa-sm" style="min-width: 200px">
            <div
              class="row items-center justify-between q-mb-md text-secondary"
            >
              <div class="row items-center">
                <q-icon name="priority_high" color="orange" size="xs" />
              </div>
              <span>Click on Table to change focus to the Table</span>
            </div>
            <q-item>
              <q-item-section avatar>
                <img
                  src="../../../public/buttons.png"
                  alt="Navigation"
                  width="50"
                  height="50"
                />
              </q-item-section>
              <q-item-section>Navigation in Table</q-item-section>
            </q-item>
            <q-item>
              <q-item-section avatar>
                <img
                  src="../../../public/space.png"
                  alt="Navigation"
                  width="50"
                  height="50"
                />
              </q-item-section>
              <q-item-section>Open Order Details as SubTable </q-item-section>
            </q-item>
            <q-item>
              <q-item-section avatar>
                <img
                  src="../../../public/ctrl.png"
                  alt="Navigation"
                  width="50"
                  height="50"
                />
              </q-item-section>
              <q-item-section>Navigate to First Row </q-item-section>
            </q-item>
            <q-item>
              <q-item-section avatar>
                <img
                  src="../../../public/alt.png"
                  alt="Navigation"
                  width="50"
                  height="50"
                />
              </q-item-section>
              <q-item-section>Navigate to Last Row </q-item-section>
            </q-item>
          </div>
        </q-menu>
      </q-btn>
      <q-btn
        class="round-icon-btn"
        v-if="isColumnOpen == false"
        round
        dense
        flat
        icon="more_vertical"
        @click="toggleColumn"
      >
        <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]"
          >Hide Columns</q-tooltip
        ></q-btn
      >
      <span class="visiblecolumns">
        <q-select
          v-if="isColumnOpen"
          v-model="visibleColumns"
          :options="hidecolumn"
          multiple
          outlined
          dense
          options-dense
          :display-value="$q.lang.table.columns"
          emit-value
          map-options
          option-value="key"
          options-cover
          style="min-width: 150px"
          class="q-mx-md"
        >
          <template v-slot:option="{ opt, selected, toggleOption }">
            <q-item
              :clickable="true"
              @click="onOptionToggle(opt, toggleOption)"
            >
              <q-item-section>
                {{ opt.label }}
              </q-item-section>
              <q-item-section side>
                <q-item-label>
                  <q-icon :name="selected ? icons.view : icons.hide" />
                </q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </span>
      <q-btn
        v-if="isColumnOpen"
        style="margin-left: -50px"
        flat
        dense
        color="primary"
        size="xs"
        icon="clear"
        @click="closeColumn"
      />
      <q-input
        v-if="isSearchOpen"
        v-model="search"
        label="Search > 4 letters"
        outlined
        dense
        class="treeSearch2"
      >
        <template v-slot:append>
          <q-btn
            flat
            dense
            color="primary"
            size="sm"
            icon="clear"
            @click="closeSearch"
          />
        </template>
      </q-input>
      <q-btn
        class="round-icon-btn"
        v-if="isSearchOpen == false"
        round
        dense
        flat
        icon="search"
        @click="toggleSearch"
      >
        <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]"
          >Search</q-tooltip
        ></q-btn
      >
      <q-btn
        round
        dense
        flat
        class="cursor-pointer round-icon-btn quote-refresh"
        icon="clear_all"
        @click="resetFilters()"
      >
        <q-tooltip anchor="bottom middle" self="top middle" :offset="[-2, -2]"
          >Clear Filters</q-tooltip
        ></q-btn
      >
      <q-space />
      <q-toggle
        v-model="unread_orders"
        label="Unread Notifications"
        left-label
        :true-value="true"
        :false-value="false"
        class="text-input-toggle"
        @update:model-value="handleToggleChangeOrders"
      />
      <q-toggle
        v-model="pending_delivery_date"
        label="Delivery Date Not Provided"
        left-label
        :true-value="true"
        :false-value="false"
        class="text-input-toggle"
        @update:model-value="handleToggleChange"
      />

      <q-btn-dropdown
        :label="
          is_shippedStatus && selectedShippedStatus != null
            ? `Shipped Status: ${selectedShippedStatus}`
            : 'Shipped Status'
        "
        color="primary"
        outlined
        dense
        v-model="isShipDropdownOpen"
      >
        <q-list style="min-width: 200px">
          <q-item
            v-for="option in shippedStatusOption.filter(
              (opt) => opt.label && opt.value
            )"
            :key="option.value"
            clickable
          >
            <q-checkbox
              :model-value="selectedShippedStatus === option.label"
              :val="option.label"
              :label="option.label"
              dense
              @update:model-value="
                (val) => onSelect('Shipped Status', option.label, val)
              "
            />
          </q-item>
          <q-separator />

          <q-item class="flex-row justify-end">
            <section>
              <q-btn
                label="Clear"
                color="negative"
                outline
                class="apply-btn q-mr-sm"
                @click="clearShippedFilters"
              />
              <q-btn
                label="Apply"
                color="primary"
                dense
                class="apply-btn"
                @click="applyShippedStatus"
              />
            </section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
      <!-- Order Matching Dropdown -->
      <q-btn-dropdown
        :label="
          is_OrderMatching && selectedOrderMatching != null
            ? `Order Matching: ${selectedOrderMatching}`
            : 'Order Matching'
        "
        color="primary"
        outlined
        dense
        v-model="isOrderDropdownOpen"
      >
        <q-list style="min-width: 200px">
          <q-item
            v-for="option in orderMatchingOptions.filter(
              (opt) => opt.label && opt.value
            )"
            :key="option.value"
            clickable
          >
            <q-checkbox
              :model-value="selectedOrderMatching === option.label"
              :val="option.label"
              :label="option.label"
              dense
              @update:model-value="
                (val) => onSelect('orderMatching', option.label, val)
              "
            />
            <!-- Sub-options for Price Mismatch -->
            <div
              v-if="
                option.label === 'Price Mismatch' &&
                selectedOrderMatching === 'Price Mismatch'
              "
              class="q-ml-lg q-mt-xs"
            >
              <q-toggle dense v-model="priceMismatchToggle" label="Custom" />

              <!-- Sub-options visible only when toggle is ON -->
              <div v-if="priceMismatchToggle" class="q-mt-sm">
                <q-input
                  v-model="priceMismatchValue"
                  dense
                  outlined
                  label="Enter Mismatch Threshold($)"
                  class="q-mb-sm"
                  :error="hasError.priceMismatchCustom"
                  :error-message="ErrorMessage"
                />
              </div>
            </div>
            <div
              v-if="
                option.label === 'PO/Line Item Missing in Supplier' &&
                selectedOrderMatching === 'PO/Line Item Missing in Supplier'
              "
              class="q-ml-lg q-mt-xs"
            >
              <q-toggle dense v-model="LineItemMismatchToggle" label="Custom" />

              <!-- Sub-options visible only when toggle is ON -->
              <div v-if="LineItemMismatchToggle" class="q-mt-sm">
                <q-input
                  v-model="LineItemMismatchValue"
                  dense
                  outlined
                  label="Show data Older Than (Days)"
                  class="q-mb-sm"
                  :error="hasError.LineItemMismatchCustom"
                  :error-message="ErrorMessage"
                />
              </div>
            </div>
          </q-item>

          <q-separator />

          <q-item class="flex-row justify-end">
            <section>
              <q-btn
                label="Clear"
                color="negative"
                outline
                class="apply-btn q-mr-sm"
                @click="clearOrderMatchFilters"
              />
              <q-btn
                label="Apply"
                color="primary"
                dense
                class="apply-btn"
                @click="applyOrderMatching"
              />
            </section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
      <!-- Promised Date Updates Dropdown -->
      <q-btn-dropdown
        :label="
          is_PromisedDateUpdates && selectedPromisedDateUpdates != null
            ? `Promised Date Updates: ${selectedPromisedDateUpdates}`
            : 'Promised Date Updates'
        "
        color="primary"
        outlined
        dense
        v-model="isPromisedDropdownOpen"
      >
        <q-list style="min-width: 200px">
          <q-item
            v-for="option in promisedDateUpdatesOptions.filter(
              (opt) => opt.label && opt.value
            )"
            :key="option.value"
            clickable
          >
            <q-checkbox
              :model-value="selectedPromisedDateUpdates === option.label"
              :val="option.label"
              :label="option.label"
              dense
              @update:model-value="
                (val) => onSelect('promisedDateUpdates', option.label, val)
              "
            />
          </q-item>
          <q-item clickable>
            <q-checkbox
              :model-value="selectedPromisedDateUpdates === 'Custom'"
              :val="'Custom'"
              label="Custom"
              dense
              @update:model-value="
                (val) => onSelect('CustomPromiseDate', 'Custom', val)
              "
            />
          </q-item>
          <q-item v-if="selectedPromisedDateUpdates === 'Custom'">
            <q-input
              v-model="customPromiseDateValue"
              label="Supplier Promise Date In (Days)"
              type="number"
              dense
              outlined
              :error="hasError.customPromiseDate"
              :error-message="ErrorMessage"
            />
          </q-item>
          <q-separator />

          <q-item class="flex-row justify-end">
            <section>
              <q-btn
                label="Clear"
                color="negative"
                outline
                class="apply-btn q-mr-sm"
                @click="clearPromisedDateFilters"
              />
              <q-btn
                label="Apply"
                color="primary"
                dense
                class="apply-btn"
                @click="applyPromisedDateUpdates"
              />
            </section>
          </q-item>
        </q-list>
      </q-btn-dropdown>

      <!-- Delays Dropdown -->
      <q-btn-dropdown
        :label="
          selectedDelays && selectedDelays != null
            ? `Delays: ${selectedDelays}`
            : 'Delays'
        "
        color="primary"
        outlined
        dense
        v-model="isDelayDropdownOpen"
      >
        <q-list style="min-width: 200px">
          <q-item
            v-for="option in delaysOptions.filter(
              (opt) => opt.label && opt.value
            )"
            :key="option.value"
            clickable
          >
            <q-checkbox
              :model-value="selectedDelays === option.label"
              :val="option.label"
              :label="option.label"
              dense
              @update:model-value="
                (val) => onSelect('delays', option.label, val)
              "
            />
          </q-item>
          <q-item clickable>
            <q-checkbox
              :model-value="selectedDelays === 'Custom'"
              :val="'Custom'"
              label="Custom"
              dense
              @update:model-value="(val) => onSelect('Custom', 'Custom', val)"
            />
          </q-item>
          <q-item v-if="selectedDelays === 'Custom'">
            <q-input
              v-model="customDelayValue"
              label="Delay Greater Than"
              type="number"
              dense
              outlined
              :error="hasError.customDelay"
              :error-message="ErrorMessage"
            />
          </q-item>
          <q-separator />

          <q-item class="flex-row justify-end">
            <section>
              <q-btn
                label="Clear"
                color="negative"
                outline
                class="apply-btn q-mr-sm"
                @click="clearDelayFilters"
              />
              <q-btn
                label="Apply"
                color="primary"
                dense
                class="apply-btn"
                @click="applyDelays"
              />
            </section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
      <span>
        <div class="q-btn-dropdown-wrapper">
          <q-btn-dropdown
            color="blue"
            outlined
            dense
            icon="filter_alt"
            v-model="isDropdownOpen"
          >
            <div class="filter-dropdown">
              <q-list class="filter-content">
                <!-- Date Filter -->
                <q-expansion-item
                  :label="
                    selectedDateFilter != null
                      ? `Date Filter: ${selectedDateFilter}`
                      : 'Date Filter'
                  "
                  icon="event"
                  dense
                  expand-separator
                  header-class="text-primary"
                >
                  <q-item v-for="option in dateFilter" :key="option" clickable>
                    <q-checkbox
                      :model-value="selectedDateFilter === option"
                      :val="option"
                      :label="option"
                      dense
                      @update:model-value="
                        (val) => onSelect('DateFilter', option, val)
                      "
                    />
                  </q-item>
                  <q-item
                    v-if="
                      selectedDateFilter != null &&
                      selectedDateFilter === 'Due Date'
                    "
                  >
                    <dateRangerSelector
                      v-model:model-value="dateRangeDueDate"
                      max-width="280px"
                      @invalidDate="handleInvalidDate"
                    />
                  </q-item>
                  <q-item
                    v-if="
                      selectedDateFilter != null &&
                      selectedDateFilter === 'Next Scheduled Date'
                    "
                  >
                    <dateRangerSelector
                      v-model:model-value="dateRangeNextScheduledDate"
                      max-width="280px"
                      @invalidDate="handleInvalidDate"
                    />
                  </q-item>
                  <q-item
                    v-if="
                      selectedDateFilter != null &&
                      selectedDateFilter === 'Last Updated Date'
                    "
                  >
                    <dateRangerSelector
                      v-model:model-value="dateRangeLastUpdatedDate"
                      max-width="280px"
                      @invalidDate="handleInvalidDate"
                    />
                  </q-item>
                </q-expansion-item>
                <q-separator />
                <!-- Customers Filter -->
                <q-expansion-item
                  :label="
                    selectedCustomers != null
                      ? `Customer: ${selectedCustomers}`
                      : 'Customer'
                  "
                  icon="person"
                  dense
                  expand-separator
                  header-class="text-primary"
                >
                  <q-item
                    v-for="option in customers.filter(
                      (opt) => opt.label && opt.value
                    )"
                    :key="option.value"
                    clickable
                  >
                    <q-checkbox
                      :model-value="selectedCustomers === option.label"
                      :val="option.label"
                      :label="option.label"
                      dense
                      @update:model-value="
                        (val) => onSelect('Customers', option.label, val)
                      "
                    />
                  </q-item>
                </q-expansion-item>
                <q-separator />
                <!-- Suppliers Filter -->
                <q-expansion-item
                  :label="
                    selectedSuppliers != null
                      ? `Suppliers: ${selectedSuppliers}`
                      : 'Suppliers'
                  "
                  icon="local_shipping"
                  dense
                  expand-separator
                  header-class="text-primary"
                >
                  <q-item
                    v-for="option in suppliers.filter(
                      (opt) => opt.label && opt.value
                    )"
                    :key="option.value"
                    clickable
                  >
                    <q-checkbox
                      :model-value="selectedSuppliers === option.label"
                      :val="option.label"
                      :label="option.label"
                      dense
                      @update:model-value="
                        (val) => onSelect('Suppliers', option.label, val)
                      "
                    />
                  </q-item>
                </q-expansion-item>
                <q-separator />
                <!-- Buyers Filter -->
                <q-expansion-item
                  :label="
                    selectedBuyers != null
                      ? `Buyers: ${selectedBuyers}`
                      : 'Buyers'
                  "
                  icon="shopping_cart"
                  dense
                  expand-separator
                  header-class="text-primary"
                >
                  <q-item
                    v-for="option in buyers.filter(
                      (opt) => opt.label && opt.value
                    )"
                    :key="option.value"
                    clickable
                  >
                    <q-checkbox
                      :model-value="selectedBuyers === option.label"
                      :val="option.label"
                      :label="option.label"
                      dense
                      @update:model-value="
                        (val) => onSelect('Buyers', option.label, val)
                      "
                    />
                  </q-item>
                </q-expansion-item>
                <q-separator />
              </q-list>

              <div class="filter-buttons">
                <q-btn
                  label="Clear"
                  color="negative"
                  outline
                  class="quote-btn q-mr-sm"
                  @click="clearAdvancedFilters"
                />
                <q-btn
                  label="Apply"
                  color="primary"
                  dense
                  flat
                  class="quote-btn"
                  @click="applyAdvancedFilters"
                  :disable="
                    (selectedDateFilter != null && invalidDate === true) ||
                    (selectedDateFilter === 'Last Updated Date' &&
                      dateRangeLastUpdatedDate.to === null &&
                      dateRangeLastUpdatedDate.from === null)
                  "
                />
              </div>
            </div>
          </q-btn-dropdown>
          <q-badge
            v-if="filter_count > 0"
            color="red"
            floating
            transparent
            class="badge-position"
          >
            {{ filter_count }}
          </q-badge>
        </div>
        <q-tooltip>Advanced Filters</q-tooltip>
      </span>
      <q-btn class="right-btn upload-btn" @click="exportERP">
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Export Order List
        </q-tooltip>
      </q-btn>
    </q-card-actions>
    <q-markup-table
      ref="orderTable"
      v-if="!loader"
      id="table_detail"
      separator="horizontal"
      :visible-columns="visibleColumns"
      :class="toggleClassFullscreen"
    >
      <thead>
        <tr>
          <th
            v-for="col in columns"
            :class="'text-' + col.align"
            :key="col.label"
            style="border-right: 0.5px solid rgba(0, 0, 0, 0.12)"
          >
            <!-- Add header checkbox to the '#' column -->
            <span style="white-space: wrap" v-if="col.field === 'rowindex'">
              <!-- <q-checkbox
                dense
                size="xs"
                v-model="allRowsSelected"
                @update:model-value="toggleAllRows"
                color="primary"
              >
                <q-tooltip
                  anchor="bottom middle"
                  self="top middle"
                  :offset="[-2, -2]"
                >
                  Select/Deselect All
                </q-tooltip>
              </q-checkbox> -->
              <span>#</span>
            </span>
            <span v-else>{{ col.label }}</span>
          </th>
        </tr>
      </thead>
      <tbody v-for="(row, index) in data" :key="index">
        <tr
          :key="index"
          @click="selectRow(row)"
          :class="{
            'selected-row': row.selected,
            'light-grey-background  ': !row.selected,
          }"
        >
          <td
            v-for="(col, colIndex) in columns"
            :key="colIndex"
            :data-row-index="index"
            :data-col-index="colIndex"
            tabindex="0"
            @keydown="handleArrowKeys($event, index, colIndex)"
            @focus="selectRow(row)"
            :class="
              col.field === 'erp_order_details'
                ? 'text-center light-green-background'
                : col.field === 'supplier_order_details'
                ? 'text-center yellow-background'
                : 'text-center'
            "
          >
            <span class="text-left" v-if="col.field === 'rowindex'">
              <q-checkbox
                dense
                size="xs"
                v-model="row.checkboxSelection"
                @update:model-value="updateRead(row)"
                color="positive"
              >
                <q-tooltip
                  anchor="bottom middle"
                  self="top middle"
                  :offset="[-2, -2]"
                >
                  Click to Mark as read
                </q-tooltip>
              </q-checkbox>
              {{ row.rowIndex }}
            </span>
            <span
              v-if="
                col.field === 'po_number' &&
                visibleColumns.indexOf('po_number') != -1
              "
            >
              <span
                class="supplierLabel"
                key="index"
                flat
                dense
                @click="showHideOrderSub(row)"
              >
                {{ row.po_number }}</span
              >
            </span>
            <span
              v-if="
                col.field === 'order_id' &&
                visibleColumns.indexOf('order_id') != -1
              "
              >{{ row.order_id }}</span
            >
            <span
              v-if="col.field === 'ipn' && visibleColumns.indexOf('ipn') != -1"
              >{{ row.erp_order_details?.ipn }}</span
            >
            <span
              v-if="col.field === 'mpn' && visibleColumns.indexOf('mpn') != -1"
            >
              <span
                class="supplierLabel"
                key="index"
                flat
                dense
                @click="showHideOrderSub(row)"
              >
                {{ row.mpn }}</span
              >
            </span>
            <span
              v-if="col.field === 'spn' && visibleColumns.indexOf('spn') != -1"
            >
              {{
                row.supplier_order_details &&
                row.supplier_order_details.status &&
                row.supplier_order_details?.status.supplier_status ===
                  "RESPONSE_RECEIVED"
                  ? row.supplier_order_details.spn
                  : ""
              }}
            </span>
            <!-- <span
              v-if="
                col.field === 'created_at' &&
                visibleColumns.indexOf('created_at') != -1
              "
            >
              {{
                row.created_at !== null
                  ? moment(row.created_at).format("MM-DD-YYYY")
                  : "Date Not Available"
              }}
            </span> -->
            <span
              v-if="
                col.field === 'erp_order_details' &&
                visibleColumns.indexOf('erp_order_details') != -1
              "
            >
              <div
                v-if="row.erp_order_details?.quantity_ordered"
                :style="
                  row.filter_info?.quantity_mismatch?.is_valid === true
                    ? 'background-color: #ff2e2e; color: white;'
                    : ''
                "
              >
                Quantity Required:
                {{ row.erp_order_details?.quantity_ordered }}
              </div>
              <div
                v-if="
                  row.erp_order_details?.quantity_received ||
                  row.erp_order_details?.quantity_received === 0
                "
              >
                Quantity Received:
                {{ row.erp_order_details?.quantity_received }}
              </div>
              <div v-if="row.erp_order_details?.remaining_quantity">
                Quantity Remaining:
                {{ row.erp_order_details?.remaining_quantity }}
              </div>

              <div
                v-if="row.erp_order_details?.expected_delivery_date"
                :style="
                  row.filter_info?.request_date_mismatch.is_valid === true
                    ? 'background-color: #ff2e2e; color: white;'
                    : ''
                "
              >
                Expected Delivery Date :
                {{
                  row.erp_order_details?.expected_delivery_date !== null
                    ? moment(
                        row.erp_order_details?.expected_delivery_date
                      ).format("MM-DD-YYYY")
                    : "Date Not Available"
                }}
              </div>

              <div
                v-if="
                  row.erp_order_details?.unit_price ||
                  row.erp_order_details?.unit_price === 0
                "
                :style="
                  row.filter_info?.price_mismatch?.is_valid === true
                    ? 'background-color: #ff2e2e; color: white;'
                    : ''
                "
              >
                Unit Price ($):
                {{
                  row.erp_order_details?.unit_price !== null
                    ? row.erp_order_details?.unit_price
                    : 0
                }}
              </div>
            </span>
            <span
              v-if="
                col.field === 'supplier_order_details' &&
                visibleColumns.indexOf('supplier_order_details') != -1
              "
            >
              <div
                v-if="
                  row.supplier_order_details &&
                  row.supplier_order_details.status &&
                  row.supplier_order_details?.status.supplier_status ===
                    'RESPONSE_RECEIVED'
                "
                :style="
                  row.filter_info?.quantity_mismatch?.is_valid === true
                    ? 'background-color: #ff2e2e; color: white;'
                    : ''
                "
              >
                Quantity Ordered:
                {{ row.supplier_order_details?.quantity_ordered }}
              </div>
              <div
                v-if="
                  (row.supplier_order_details &&
                    row.supplier_order_details.status &&
                    row.supplier_order_details?.status.supplier_status ===
                      'RESPONSE_RECEIVED' &&
                    row.supplier_order_details?.quantity_shipped) ||
                  row.supplier_order_details?.quantity_shipped === 0
                "
              >
                Quantity Shipped:
                {{ row.supplier_order_details?.quantity_shipped }}
              </div>
              <div
                v-if="
                  row.supplier_order_details &&
                  row.supplier_order_details.status &&
                  row.supplier_order_details?.status.supplier_status ===
                    'RESPONSE_RECEIVED' &&
                  row.supplier_order_details?.remaining_quantity
                "
              >
                Quantity Remaining:
                {{ row.supplier_order_details?.remaining_quantity }}
              </div>
              <div
                v-if="
                  row.supplier_order_details &&
                  row.supplier_order_details.status &&
                  row.supplier_order_details?.status.supplier_status ===
                    'RESPONSE_RECEIVED' &&
                  row.supplier_order_details?.expected_delivery_date
                "
                :style="
                  row.filter_info?.last_commit_date_delayed?.is_valid === true
                    ? 'background-color: #ff2e2e; color: white;'
                    : row.filter_info?.last_commit_date_advanced?.is_valid ===
                      true
                    ? 'background-color: #32A91F; color: white;'
                    : ''
                "
              >
                Expected Delivery Date :
                {{
                  row.supplier_order_details?.expected_delivery_date !== null
                    ? moment(
                        row.supplier_order_details?.expected_delivery_date
                      ).format("MM-DD-YYYY")
                    : "Date Not Available"
                }}
              </div>
              <div
                v-if="
                  (row.supplier_order_details &&
                    row.supplier_order_details.status &&
                    row.supplier_order_details?.status.supplier_status ===
                      'RESPONSE_RECEIVED' &&
                    row.supplier_order_details?.unit_price) ||
                  row.supplier_order_details?.unit_price === 0
                "
                :style="
                  row.filter_info?.price_mismatch?.is_valid === true
                    ? 'background-color: #ff2e2e; color: white;'
                    : ''
                "
              >
                Unit Price ($):
                {{
                  row.supplier_order_details?.unit_price !== null
                    ? row.supplier_order_details?.unit_price
                    : 0
                }}
              </div>
            </span>
            <span v-if="is_OrderMatching && col.field === 'mismatch'">
              <div v-if="row.filter_info?.price_mismatch?.is_valid === true">
                Price Difference ($):
                {{ row.filter_info?.price_mismatch?.difference }}
              </div>
              <div v-if="row.filter_info?.quantity_mismatch?.is_valid === true">
                Quantity Difference :
                {{ row.filter_info?.quantity_mismatch?.difference }}
              </div>
              <div
                v-if="row.filter_info?.request_date_mismatch?.is_valid === true"
                :style="
                  row.filter_info?.request_date_mismatch?.is_valid === true
                    ? 'background-color: #ff2e2e; color: white;'
                    : ''
                "
              >
                {{
                  `Supplier Required Date : ${moment(
                    row.supplier_order_details?.required_date
                      ? row.supplier_order_details?.required_date
                      : "Date Not Available"
                  ).format("MM-DD-YYYY")}`
                }}
              </div>
            </span>
            <span v-if="is_PromisedDateUpdates && col.field === 'date_updates'">
              <div
                v-if="
                  row.filter_info?.last_commit_date_delayed?.is_valid === true
                "
              >
                Date Delayed By :
                {{ row.filter_info?.last_commit_date_delayed?.difference }}
              </div>
              <div
                v-if="
                  row.filter_info?.last_commit_date_advanced?.is_valid === true
                "
              >
                Date Advanced By :
                {{ row.filter_info?.last_commit_date_advanced?.difference }}
              </div>
              <div
                v-if="
                  row.filter_info?.promise_date_not_provided?.is_valid === true
                "
              >
                Promise Date Not Provided
              </div>
            </span>
            <span
              v-if="
                col.field === 'remaining_quantity' &&
                visibleColumns.indexOf('remaining_quantity') != -1
              "
            >
              <div
                v-if="
                  row.supplier_order_details &&
                  row.supplier_order_details.status &&
                  row.supplier_order_details?.status.supplier_status ===
                    'RESPONSE_RECEIVED'
                "
              >
                {{ row.supplier_order_details?.remaining_quantity }}
              </div>
            </span>
            <span
              v-if="
                col.field === 'schedules' &&
                visibleColumns.indexOf('schedules') != -1
              "
            >
              <div
                v-if="
                  row.supplier_order_details &&
                  row.supplier_order_details.status &&
                  row.supplier_order_details?.status.supplier_status ===
                    'RESPONSE_RECEIVED' &&
                  row.supplier_order_details?.schedules &&
                  row.supplier_order_details?.schedules[
                    row.supplier_order_details.next_scheduled_date
                  ]?.quantity_ordered
                "
              >
                Next Shipment Quantity:
                {{
                  row.supplier_order_details?.schedules[
                    row.supplier_order_details.next_scheduled_date
                  ]?.quantity_ordered
                }}
              </div>
              <div
                v-if="
                  row.supplier_order_details &&
                  row.supplier_order_details.status &&
                  row.supplier_order_details?.status.supplier_status ===
                    'RESPONSE_RECEIVED' &&
                  row.supplier_order_details?.next_scheduled_date
                "
              >
                Next Shipment Date:
                {{
                  row.supplier_order_details.next_scheduled_date !== null
                    ? moment(
                        row.supplier_order_details.next_scheduled_date
                      ).format("MM-DD-YYYY")
                    : "Date Not Available"
                }}
              </div>
            </span>
            <span
              v-if="
                col.field === 'promise_date' &&
                visibleColumns.indexOf('promise_date') != -1
              "
            >
              <div
                v-if="row.erp_order_details?.promise_date !== 'None'"
                :style="
                  row.filter_info?.last_commit_date_delayed?.is_valid === true
                    ? 'background-color: #ff2e2e; color: white;'
                    : row.filter_info?.last_commit_date_advanced?.is_valid ===
                      true
                    ? 'background-color: #32A91F; color: white;'
                    : ''
                "
              >
                {{
                  row.erp_order_details?.promise_date !== null
                    ? moment(row.erp_order_details?.promise_date).format(
                        "MM-DD-YYYY"
                      )
                    : "Date Not Available"
                }}
              </div>
            </span>
            <span
              v-if="
                col.field === 'customer_name' &&
                visibleColumns.indexOf('customer_name') != -1
              "
              >{{ row.customer_name }}</span
            >
            <span
              v-if="
                col.field === 'buyer_name' &&
                visibleColumns.indexOf('buyer_name') != -1
              "
              >{{ row.erp_order_details?.buyer_name }}</span
            >
            <span
              v-if="
                col.field === 'supplier_name' &&
                visibleColumns.indexOf('supplier_name') != -1
              "
              >{{ row.supplier_name }}</span
            >
            <span
              v-if="
                col.field === 'description' &&
                visibleColumns.indexOf('description') != -1
              "
              >{{ row.erp_order_details?.description }}</span
            >
            <span
              v-if="
                col.field === 'date' && visibleColumns.indexOf('date') != -1
              "
            >
              <div
                v-if="
                  row.supplier_order_details &&
                  row.supplier_order_details?.status.updated_at
                "
              >
                {{
                  row.supplier_order_details.status.updated_at !== null
                    ? moment(
                        row.supplier_order_details.status.updated_at
                      ).format("MM-DD-YYYY")
                    : "Date Not Available"
                }}
              </div>
            </span>
            <span
              v-if="
                col.field === 'actions' &&
                visibleColumns.indexOf('actions') != -1
              "
            >
              <q-btn
                dense
                round
                flat
                color="primary"
                icon="notifications"
                @click="openNotification(row)"
              >
                <q-badge
                  v-if="row.unread_notification_count > 0"
                  color="red"
                  floating
                  transparent
                  style="font-size: 9px"
                >
                  {{ row.unread_notification_count }}</q-badge
                >
                <q-tooltip
                  anchor="bottom middle"
                  self="top middle"
                  :offset="[-2, -2]"
                >
                  Unread Notifications
                </q-tooltip>
              </q-btn>
            </span>
          </td>
        </tr>
        <q-tr v-if="isSubTableOpen[row.rowIndex]" :key="row.rowIndex">
          <q-td colspan="21" class="bg-Accordion">
            <div style="padding: 1rem 1rem; width: 100%">
              <div class="row items-start">
                <orderSubTable
                  :subTableData="subTableData"
                  @toggleClose="toggleClose(row.rowIndex)"
                  :closeIcon="true"
                />
              </div>
            </div>
          </q-td>
        </q-tr>
      </tbody>
    </q-markup-table>

    <div v-if="data.length === 0 && !loader">
      <div class="full-width row flex-center text-black q-gutter-sm">
        <q-icon size="1.5em" name="report_problem" />
        <span> No Data Found </span>
      </div>
    </div>
    <div
      class="row justify-between q-mt-sm"
      style="border-bottom-left-radius: 20px; border-bottom-right-radius: 20px"
      v-if="!loader && data.length > 0"
    >
      <div class="q-ml-lg text-grey-8 hint-fullscreen">
        <b>Tip: &nbsp;</b> Press &nbsp;<span class="fullscreenHintText">
          Shift + F</span
        >
        &nbsp; {{ toggleFullscreenTip }}
      </div>
      <div class="flex" style="font-size: 12px; margin-top: -3px">
        <div
          class="cursor-pointer q-mr-sm q-pa-xs"
          style="font-size: 12px"
          dense
          color="primary"
        >
          Records per page&nbsp;:&nbsp;
          <q-btn
            style="font-size: 12px"
            class="btn--no-hover q-pa-none"
            flat
            dense
            :label="
              pagination.rowsPerPage === -1 ? 'All' : pagination.rowsPerPage
            "
            icon-right="fa-solid fa-caret-down"
            color="primary"
          >
            <q-menu>
              <q-list
                dense
                v-for="(pag, index) in paginationOptions"
                :key="index"
              >
                <q-item
                  clickable
                  v-close-popup
                  @click="selectpagination(pag)"
                  class="q-pa-none"
                >
                  <q-item-section> {{ pag }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
        <q-pagination
          v-model="pagination.page"
          :max="pagesNumber"
          input
          class="paginationclass q-mr-sm"
          size="sm"
          direction-links
          boundary-links
          icon-first="skip_previous"
          icon-last="skip_next"
          icon-prev="fast_rewind"
          icon-next="fast_forward"
        />
      </div>
    </div>
  </q-card>
  <orderNotificationDialog
    ref="orderNotificationDialog"
    @dialog-closed="reloadOrderTable"
  ></orderNotificationDialog>
</template>

<script>
import { SpinnerComponent, commonFunction } from "@/utils";
import { ref } from "vue";
import { NotifyService, SettingService, OrderService } from "@/services";
import { useMyStore } from "@/store";
import { AppFullscreen, Loading } from "quasar";
import moment from "moment";
import orderSubTable from "@/components/orders/orderSubTable.vue";
import dateRangerSelector from "@/utils/dateRangeSelector.vue";
import orderNotificationDialog from "@/components/orders/orderNotificationDialog.vue";
export default {
  name: "OrderTable",

  components: {
    SpinnerComponent,
    orderSubTable,
    dateRangerSelector,
    orderNotificationDialog,
  },
  setup() {
    const pagination = ref({
      sortBy: "desc",
      descending: false,
      page: 1,
      rowsPerPage: 25,
    });
    const paginationOptions = ref([5, 7, 10, 15, 20, 25, 50, "All"]);

    const myStore = useMyStore();
    const QuotationRow = ref([]);
    const search = ref("");

    return {
      maximizedToggle: ref(true),
      first_call: ref(false),
      paginationOptions,
      pagination,
      myStore,
      expanded: ref([]),
      icons: ref({}),
      subTableData: ref([]),
      visibleColumns: ref([
        "po_number",
        "order_id",
        "ipn",
        "mpn",
        "spn",
        "created_at",
        "erp_order_details",
        "supplier_order_details",
        "remaining_quantity",
        "schedules",
        "promise_date",
        "supplier_name",
        "buyer_name",
        "customer_name",
        "description",
        "date",
        "actions",
      ]),
      search,
      QuotationRow,
      fullScreenMode: ref(false),
      loader: ref(true),
      QuotationColors: ref({}),
      isSearchOpen: ref(false),
      isColumnOpen: ref(false),
      isSubTableOpen: ref({}),
      selectedRow: ref({}),
    };
  },

  watch: {
    "myStore.fields.order_fields": {
      handler(newFields) {
        this.ERPColumn = newFields || [];
      },
      immediate: true, // Trigger on component load
    },
    visibleColumns() {
      if (this.visibleColumns.includes(this.currentHideKey.key) == false) {
        this.ERPColumn = this.ERPColumn.filter((column) =>
          this.visibleColumns.includes(column.key)
        );
      } else {
        let temp = [...this.ERPColumn, this.currentHideKey];
        this.ERPColumn = this.rearrangeArray(temp, this.ERPColumnStatic);
      }
    },
    search(newval) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        if (newval.length >= 4) {
          this.search_order(newval);
        } else if (newval.length === 0) {
          const body = this.createBody();
          this.getOrders(body);
        }
      }, 500);
    },
  },
  computed: {
    ERPColumnStatic() {
      return this.myStore.fields.order_fields;
    },

    columns() {
      if (!this.ERPColumn || this.ERPColumn.length === 0) {
        return [];
      }
      let datav = this.ERPColumn.map((obj) => {
        obj.field = obj.key;
        obj.format = (val) => `${val}`;
        obj.name = obj.key;
        obj.align = "center";
        obj.group = "starred";
        obj.filterable = false;
        return obj;
      });
      const schedulesIndex = datav.findIndex((col) => col.key === "schedules");
      if (this.is_OrderMatching) {
        datav.splice(schedulesIndex, 0, {
          label: `Order Mismatch`,
          field: `mismatch`,
          align: "center",
          datasource: "computed",
        });
      } else if (this.is_PromisedDateUpdates) {
        datav.splice(schedulesIndex, 0, {
          label: `Promised Date Difference (In Days)`,
          field: `date_updates`,
          align: "center",
          datasource: "computed",
        });
      }

      return datav;
    },

    hidecolumn() {
      //column Hide
      let colfilter = this.ERPColumnStatic.filter((k) => k.key !== "rowindex");
      return colfilter;
    },
    // Filtered rows after search
    filteredRows() {
      return this.responseData;
    },

    // Calculated total pages based on filtered data and rowsPerPage
    pagesNumber() {
      if (this.pagination.rowsPerPage != -1 && this.search == "") {
        const newpage = Math.ceil(
          this.filteredRows.length / this.pagination.rowsPerPage
        );
        return newpage;
      } else if (this.pagination.rowsPerPage != -1 && this.search !== "") {
        const newpage = Math.ceil(
          this.filteredRows.length / this.pagination.rowsPerPage
        );
        return newpage;
      } else {
        return 1;
      }
    },

    data() {
      //return this.paginatedData;
      if (this.pagination.rowsPerPage == -1) {
        return this.filteredRows;
      } else {
        const startIndex =
          (this.pagination.page - 1) * this.pagination.rowsPerPage;
        const endIndex = startIndex + this.pagination.rowsPerPage;
        return this.filteredRows.slice(startIndex, endIndex);
      }
    },

    toggleClassFullscreen() {
      if (AppFullscreen.isActive) {
        return "fullscreenHeight my-sticky-header-table my-sticky-header-last-column-table";
      } else {
        return "TableHeightNotify my-sticky-header-table my-sticky-header-last-column-table";
      }
    },
    toggleFullscreenTip() {
      if (AppFullscreen.isActive) {
        return "to exit Fullscreen ";
      } else {
        return "to enter Fullscreen";
      }
    },
  },
  created() {
    if (this.defaultExpandAll) {
      this.first_call = ref(true);
    }
  },

  methods: {
    updateSelectedRow(row) {
      //Logic from the selectRow function
      if (this.selectedRow) {
        this.selectedRow.selected = false;
      }
      row.selected = true;
      this.selectedRow = row;
    },
    selectRow(row) {
      this.loader = true;
      this.updateSelectedRow(row);
      this.loader = false;
    },
    showTree() {
      this.myStore.isCardVisible = true;
      this.myStore.isToastVisible = true;
    },

    selectpagination(value) {
      //Changing the Pagination variable
      if (value == "All") {
        this.pagination.rowsPerPage = -1;
      } else {
        this.pagination.rowsPerPage = parseInt(value);
      }
      this.pagination.page = 1;
    },
    handlechange(val) {
      this.search = val;
      this.pagination.page = 1;
    },
    handleKeyDown(event) {
      //Keyboard shortcuts
      if (event.shiftKey && event.keyCode === 70) {
        event.preventDefault();
        this.toggleFullscreen1();
      }
    },
    handleArrowKeys(event, rowIndex, colIndex) {
      let newRowIndex = rowIndex;
      let newColIndex = colIndex;

      const totalRows = this.data.length;
      const totalCols = this.columns.length;

      const keyCode = event.keyCode;
      event.stopPropagation();
      // Handle Tab

      // Handle Arrow and Control/Alt keys
      switch (keyCode) {
        case 37: // Left Arrow
          newColIndex -= 1;
          break;
        case 38: // Up Arrow
          newRowIndex -= 1;
          break;
        case 39: // Right Arrow
          newColIndex += 1;
          break;
        case 40: // Down Arrow
          newRowIndex += 1;
          break;
        case 17: // Control Key (Jump to first row)
          newRowIndex = 0;
          break;
        case 18: // Alt Key (Jump to last row)
          newRowIndex = totalRows - 1;
          break;
        case 32: {
          event.preventDefault();
          const item = this.data[rowIndex];
          this.showHideOrderSub(item);
          return;
        }
        default:
          return;
      }

      // Boundary check
      if (
        newRowIndex < 0 ||
        newRowIndex >= totalRows ||
        newColIndex < 0 ||
        newColIndex >= totalCols
      ) {
        return;
      }

      this.$nextTick(() => {
        const selector = `td[data-row-index="${newRowIndex}"][data-col-index="${newColIndex}"], q-td[data-row-index="${newRowIndex}"][data-col-index="${newColIndex}"]`;
        const tableElement = this.$refs.orderTable.$el;
        const nextCell = tableElement?.querySelector(selector);
        if (nextCell) {
          nextCell.focus();
        }
      });

      event.preventDefault();
      event.stopPropagation();
    },

    sortBy(col) {
      this.newQuoteRow.forEach((row1) => {
        row1.SubTableOpen = false;
      });
      if (col.sortDirection === "asc") {
        this.sort_recursive(this.QuotationRow, col, true);
        col.sortDirection = "desc";
      } else {
        this.sort_recursive(this.QuotationRow, col, false);
        col.sortDirection = "asc";
      }
      col.sorted = true;
      this.$emit("sort_column", col);
      this.resetSorting(col);
    },
    sort_recursive(elements, col, descending) {
      if (descending !== null) {
        elements.sort(this.sort_by(col, descending));
        for (let i = 0; i < elements.length; i++) {
          // if (elements[i].hasOwnProperty('children')) {
          if (typeof elements[i].children != "undefined") {
            this.sort_recursive(elements[i].children, col, descending);
          }
        }
      } else {
        const defRow = this.newQuoteRow[0];
        return this.selectRow(defRow);
      }
    },
    sort_by(col, descending) {
      if (descending === null) {
        return this.data;
      }
      let direction = !descending ? 1 : -1;
      if (
        col.sortingType === "custom" &&
        typeof col.sortingFunc === "function"
      ) {
        return function (a, b) {
          return col.sortingFunc(a, b) * direction;
        };
      }
      let key = function (x) {
        return x[col.field] === null ? "" : x[col.field];
      };
      switch (col.sortingType) {
        case "number":
          key = function (x) {
            return parseFloat(x[col.field]);
          };
          break;
        case "date":
          key = function (x) {
            return new Date(x[col.field]);
          };
          break;
      }
      return function (a, b) {
        return (a = key(a)), (b = key(b)), direction * ((a > b) - (b > a));
      };
    },
    resetSorting(sortedCol) {
      let arraySize = this.columns.length;
      for (let i = 0; i < arraySize; i++) {
        let col = this.columns[i];
        if (col.field != sortedCol.field) {
          col.sorted = ref(false);
          col.sortDirection = "none";
        }
      }
    },

    include(item, filterString, expandingProperty, colDefinitions) {
      let includeItem = false;
      let filterApplied = false;
      //first check the expandingProperty
      filterApplied = true;
      if (this.checkItem(item, filterString, expandingProperty)) {
        includeItem = true;
      }
      //then check each of the other columns
      let arraySize = colDefinitions.length;
      for (let i = 0; i < arraySize; i++) {
        let col = colDefinitions[i];
        if (col.filterable) {
          filterApplied = true;
          if (this.checkItem(item, filterString, col)) {
            includeItem = true;
          }
        }
      }
      if (filterApplied) {
        return includeItem;
      } else {
        return true;
      }
    },
    checkItem(item, filterString, col) {
      if (col.sortingType === "number") {
        if (
          item[col.field] != null &&
          parseFloat(item[col.field]) === parseFloat(filterString)
        ) {
          return true;
        }
      } else {
        if (
          item[col.field] != null &&
          item[col.field].toLowerCase().indexOf(filterString.toLowerCase()) !==
            -1
        ) {
          return true;
        }
      }
    },

    /* Hierarchy FUnctions End*/
    toggleicon() {
      if (AppFullscreen.isActive) {
        return "fullscreen_exit";
      } else {
        return "fullscreen";
      }
    },
    toggleFullscreen1() {
      const target = this.$refs.fullscreenTarget.$el;
      AppFullscreen.toggle(target)
        .then(() => {
          // success!
        })
        .catch((err) => {
          alert(err);
        });
    },
    // Format the header row Breadcrumbs
    formattedString(string) {
      const res = commonFunction.BreadcrumbFormat(string);
      return res;
    },

    toggleSearch() {
      if (this.isColumnOpen) {
        this.isColumnOpen = false;
      }
      this.isSearchOpen = !this.isSearchOpen;
    },
    closeSearch() {
      this.search = "";
      this.isSearchOpen = false;
    },
    closeColumn() {
      this.isColumnOpen = false;
    },
    toggleColumn() {
      this.isColumnOpen = !this.isColumnOpen;
    },

    getIcons() {
      SettingService.Viewicons().then((x) => {
        this.icons = x.NavIcons;
        this.QuotationColors = x.QuotationColors;
      });
    },

    options(type) {
      var arr = [];
      arr.push(this.bom_name), arr.push(type);
      localStorage.setItem("bomname-qty", JSON.stringify(arr));
      localStorage.setItem("qty", type);
      this.myStore.GlobalQty = type;
      this.myStore.refreshpricing = Math.random();
    },
    filterClr(keyvalue, rowselect = false, align) {
      if (this.ERPColumn && !rowselect) {
        let output = this.ERPColumn.filter((user) => {
          return user.key === keyvalue;
        });
        var clr = "";
        if (output[0] !== undefined) {
          if (output[0].datasource !== undefined) {
            clr = this.QuotationColors[output[0].datasource];
          }
          if (output[0].datasource === "bom") {
            clr = `QuotationColorsBom text-${align} `;
          } else if (output[0].datasource === "computed") {
            clr = `QuotationColorsComputed text-${align} `;
          } else if (output[0].datasource === "internal") {
            clr = `QuotationColorsInternal text-${align} `;
          } else if (output[0].datasource === "supplier") {
            clr = `QuotationColorsSupplier text-${align} `;
          } else if (output[0].datasource === "recommendation") {
            clr = `QuotationColorsRecommendation text-${align} `;
          } else {
            clr = `QuotationColorsgeneral text-center`;
          }
        }
        return clr;
      }
    },

    cleanupWords(description) {
      const words = commonFunction.descriptionFormat(description);
      return words;
    },

    onOptionToggle(opt, toggleOption) {
      this.currentHideKey = opt;
      toggleOption(opt);
    },

    rearrangeArray(A, B) {
      // Create a mapping from the key in array B to its index for sorting purposes
      const orderMap = {};
      B.forEach((item, index) => {
        orderMap[item.key] = index;
      });
      // Sort array A based on the order defined in array B
      A.sort((a, b) => {
        return orderMap[a.key] - orderMap[b.key];
      });
      return A;
    },

    rowHighlight(event, rowIndex, colIndex) {
      this.myStore.isCardVisible = false;
      this.myStore.isToastVisible = false;
      this.$nextTick(() => {
        const selector = `td[data-row-index="${rowIndex}"][data-col-index="${colIndex}"], q-td[data-row-index="${rowIndex}"][data-col-index="${colIndex}"]`;
        const tableElement = this.$refs.orderTable.$el;
        const nextCell = tableElement?.querySelector(selector);
        if (nextCell) {
          nextCell.focus();
        }
      });
      this.handleArrowKeys(event, rowIndex, colIndex);
    },
    async getOrders(body) {
      Loading.show();
      await OrderService.getAllOrders(body)
        .then((response) => {
          this.responseData = response.map((item, index) => {
            return {
              ...item,
              rowIndex: index + 1,
              checkboxSelection: false,
            };
          });
          this.loader = false;
          Loading.hide();
        })
        .catch((err) => {
          Loading.hide();
          console.log(err);
        });
    },
    validateNumber(value) {
      return !isNaN(value) && value !== null && value !== "" && value > 0;
    },
    resetErrors() {
      this.hasError.priceMismatchCustom = false;
      this.hasError.LineItemMismatchCustom = false;
      this.hasError.customPromiseDate = false;
      this.hasError.customDelay = false;
    },
    async applyOrderMatching() {
      this.resetErrors();
      this.isSubTableOpen = {};
      this.selectedDelays = null;
      this.selectedPromisedDateUpdates = null;
      this.is_PromisedDateUpdates = false;
      this.is_Delays = false;
      let isValid = true;
      if (
        this.selectedOrderMatching != null &&
        this.selectedOrderMatching === "Price Mismatch" &&
        this.priceMismatchToggle === true &&
        !this.validateNumber(this.priceMismatchValue)
      ) {
        this.hasError.priceMismatchCustom = true;
        isValid = false;
      } else if (
        this.selectedOrderMatching != null &&
        this.selectedOrderMatching === "PO/Line Item Missing in Supplier" &&
        this.LineItemMismatchToggle === true &&
        !this.validateNumber(this.LineItemMismatchValue)
      ) {
        this.hasError.LineItemMismatchCustom = true;
        isValid = false;
      }
      if (isValid) {
        const body = this.createBody();
        await this.getOrders(body);
        this.is_OrderMatching = true;
        this.isOrderDropdownOpen = false;
      }
    },
    async applyPromisedDateUpdates() {
      this.resetErrors();
      this.isSubTableOpen = {};
      this.selectedDelays = null;
      this.selectedOrderMatching = null;
      this.is_OrderMatching = false;
      this.is_Delays = false;
      let isValid = true;
      if (
        this.selectedPromisedDateUpdates === "Custom" &&
        this.selectedPromisedDateUpdates !== null &&
        this.customPromiseDateValue !== null &&
        !this.validateNumber(this.customPromiseDateValue)
      ) {
        this.hasError.customPromiseDate = true;
        isValid = false;
      }
      if (isValid) {
        const body = this.createBody();
        await this.getOrders(body);
        this.is_PromisedDateUpdates = true;
        this.isPromisedDropdownOpen = false;
      }
    },
    async applyShippedStatus() {
      this.isSubTableOpen = {};
      const body = this.createBody();
      await this.getOrders(body);
      this.is_shippedStatus = true;
      this.isShipDropdownOpen = false;
    },

    async applyAdvancedFilters() {
      this.isSubTableOpen = {};
      // Reset values based on conditions
      this.selectedOrderMatching = this.is_OrderMatching
        ? this.selectedOrderMatching
        : null;
      this.selectedDelays = this.is_Delays ? this.selectedDelays : null;
      this.selectedPromisedDateUpdates = this.is_PromisedDateUpdates
        ? this.selectedPromisedDateUpdates
        : null;

      // Increment filter count and set flags dynamically
      const filters = [
        { value: this.selectedBuyers, flag: "selectedBuyersFilterFlag" },
        { value: this.selectedCustomers, flag: "selectedCustomersFilterFlag" },
        { value: this.selectedSuppliers, flag: "selectedSuppliersFilterFlag" },
        {
          value: this.selectedDateFilter,
          flag: "selectedDateFilterFilterFlag",
        },
      ];

      filters.forEach(({ value, flag }) => {
        if (value != null && this[flag] !== true) {
          this.filter_count++;
          this[flag] = true;
        }
      });

      // Create request body and fetch orders
      const body = this.createBody();
      await this.getOrders(body);

      // Close the dropdown
      this.isDropdownOpen = false;
    },

    async applyDelays() {
      this.resetErrors();
      this.isSubTableOpen = {};
      this.is_OrderMatching = false;
      this.is_PromisedDateUpdates = false;
      this.selectedOrderMatching = null;
      this.selectedPromisedDateUpdates = null;
      let isValid = true;
      if (
        this.selectedDelays === "Custom" &&
        this.selectedDelays !== null &&
        this.customDelayValue !== null &&
        !this.validateNumber(this.customDelayValue)
      ) {
        this.hasError.customDelay = true;
        isValid = false;
      }
      if (isValid) {
        const body = this.createBody();
        await this.getOrders(body);
        this.isDelayDropdownOpen = false;
      }
    },
    async clearAdvancedFilters() {
      this.selectedBuyers = null;
      this.selectedCustomers = null;
      this.selectedSuppliers = null;
      this.selectedDateFilter = null;
      this.selectedBuyersFilterFlag = false;
      this.selectedCustomersFilterFlag = false;
      this.selectedSuppliersFilterFlag = false;
      this.selectedDateFilterFilterFlag = false;
      this.filter_count = 0;
      const body = this.createBody();
      await this.getOrders(body);
      this.isDropdownOpen = false;
    },
    async clearShippedFilters() {
      this.selectedShippedStatus = null;
      const body = this.createBody();
      await this.getOrders(body);
      this.isShipDropdownOpen = false;
    },
    async clearOrderMatchFilters() {
      this.selectedOrderMatching = null;
      this.is_OrderMatching = false;
      this.priceMismatchValue = "";
      this.LineItemMismatchValue = "";
      if (this.is_PromisedDateUpdates == false) {
        this.selectedPromisedDateUpdates = null;
      }

      if (this.is_Delays == false) {
        this.selectedDelays = null;
      }
      const body = this.createBody();
      await this.getOrders(body);
      this.isOrderDropdownOpen = false;
    },
    async clearPromisedDateFilters() {
      this.selectedPromisedDateUpdates = null;
      this.is_PromisedDateUpdates = false;
      this.customPromiseDateValue = "";
      if (this.is_OrderMatching == false) {
        this.selectedOrderMatching = null;
      }
      if (this.is_Delays == false) {
        this.selectedDelays = null;
      }
      const body = this.createBody();
      await this.getOrders(body);

      this.isPromisedDropdownOpen = false;
    },
    async clearDelayFilters() {
      this.selectedDelays = null;
      this.customDelayValue = "";
      if (this.is_OrderMatching == false) {
        this.selectedOrderMatching = null;
      }
      if (this.is_PromisedDateUpdates == false) {
        this.selectedPromisedDateUpdates = null;
      }

      const body = this.createBody();
      await this.getOrders(body);
      this.isDelayDropdownOpen = false;
    },

    async handleToggleChange(value) {
      this.pending_delivery_date = value;
      const body = this.createBody();
      await this.getOrders(body);
    },
    async handleToggleChangeOrders(value) {
      this.unread_orders = value;
      const body = this.createBody();
      await this.getOrders(body);
    },
    createBody() {
      let body = {
        customers: {
          name: "Customers",
          data_type: "list",
          enable: true,
          value: this.selectedCustomers != null ? [this.selectedCustomers] : [],
          options: this.customers[this.customers.length - 1].options,
        },
        suppliers: {
          name: "Suppliers",
          data_type: "list",
          enable: true,
          value: this.selectedSuppliers != null ? [this.selectedSuppliers] : [],
          options: this.suppliers[this.suppliers.length - 1].options,
        },
        buyers: {
          name: "Buyers",
          data_type: "list",
          enable: true,
          value: this.selectedBuyers != null ? [this.selectedBuyers] : [],
          options: this.buyers[this.buyers.length - 1].options,
        },
        order_match: {
          name: "Order Matching",
          data_type: "list",
          enable: true,
          value:
            this.selectedOrderMatching != null
              ? [this.selectedOrderMatching]
              : [],
          options:
            this.orderMatchingOptions[this.orderMatchingOptions.length - 1]
              .options,
          custom_value:
            this.selectedOrderMatching != null &&
            this.selectedOrderMatching === "Price Mismatch" &&
            this.priceMismatchToggle === true
              ? Number(this.priceMismatchValue)
              : this.selectedOrderMatching != null &&
                this.selectedOrderMatching ===
                  "PO/Line Item Missing in Supplier" &&
                this.LineItemMismatchToggle === true
              ? Number(this.LineItemMismatchValue)
              : null,
        },
        promise_date_updates: {
          name: "Promise Date Updates",
          data_type: "list",
          enable: true,
          value:
            this.selectedPromisedDateUpdates !== "Custom" &&
            this.selectedPromisedDateUpdates != null
              ? [this.selectedPromisedDateUpdates]
              : [],
          options:
            this.promisedDateUpdatesOptions[
              this.promisedDateUpdatesOptions.length - 1
            ].options,
          custom_value:
            this.selectedPromisedDateUpdates === "Custom" &&
            this.selectedPromisedDateUpdates !== null &&
            this.customPromiseDateValue !== null
              ? Number(this.customPromiseDateValue)
              : null,
        },
        delays: {
          name: "Delays",
          data_type: "list",
          enable: true,
          value:
            this.selectedDelays !== "Custom" && this.selectedDelays !== null
              ? [this.selectedDelays]
              : [],
          options: this.delaysOptions[this.delaysOptions.length - 1].options,
          custom_value:
            this.selectedDelays === "Custom" &&
            this.selectedDelays !== null &&
            this.customDelayValue !== null
              ? Number(this.customDelayValue)
              : null,
        },
        unread_orders: {
          name: "Unread",
          data_type: "boolean",
          enable: true,
          value: this.unread_orders,
        },
        pending_delivery_date: {
          name: "Delivery Date Not Provided",
          data_type: "boolean",
          enable: true,
          value: this.pending_delivery_date,
        },
        due_date: {
          name: "Due Date",
          data_type: "calender",
          enable: true,
          value_from:
            this.selectedDateFilter != null &&
            this.selectedDateFilter == "Due Date"
              ? this.dateRangeDueDate.from !== ""
                ? this.dateRangeDueDate.from
                : null
              : null,
          value_to:
            this.selectedDateFilter != null &&
            this.selectedDateFilter == "Due Date"
              ? this.dateRangeDueDate.to !== ""
                ? this.dateRangeDueDate.to
                : null
              : null,
        },
        next_scheduled_date: {
          name: "Next Scheduled Date",
          data_type: "calender",
          enable: true,
          value_from:
            this.selectedDateFilter != null &&
            this.selectedDateFilter == "Next Scheduled Date"
              ? this.dateRangeNextScheduledDate.from !== ""
                ? this.dateRangeNextScheduledDate.from
                : null
              : null,
          value_to:
            this.selectedDateFilter != null &&
            this.selectedDateFilter == "Next Scheduled Date"
              ? this.dateRangeNextScheduledDate.to !== ""
                ? this.dateRangeNextScheduledDate.to
                : null
              : null,
        },
        last_updated_date: {
          name: "Last Updated Date",
          data_type: "calender",
          enable: true,
          value_from:
            this.selectedDateFilter != null &&
            this.selectedDateFilter == "Last Updated Date"
              ? this.dateRangeLastUpdatedDate.from !== ""
                ? this.dateRangeLastUpdatedDate.from
                : null
              : null,
          value_to:
            this.selectedDateFilter != null &&
            this.selectedDateFilter == "Last Updated Date"
              ? this.dateRangeLastUpdatedDate.to !== ""
                ? this.dateRangeLastUpdatedDate.to
                : null
              : null,
        },
        shipped_status: {
          name: "Shipped Status",
          data_type: "list",
          enable: true,
          value:
            this.selectedShippedStatus != null
              ? [this.selectedShippedStatus]
              : [],
          options:
            this.shippedStatusOption[this.shippedStatusOption.length - 1]
              .options,
        },
      };
      return body;
    },
    moment(...args) {
      return moment(...args);
    },
    // Fetch filters from API and set options
    async getFilters() {
      await OrderService.getOrderFilter()
        .then((response) => {
          this.orderMatchingOptions = [
            ...response.order_match.options.map((item) => ({
              label: item,
              value: item,
            })),
            { options: response.order_match.options },
          ];

          this.promisedDateUpdatesOptions = [
            ...response.promise_date_updates.options.map((item) => ({
              label: item,
              value: item,
            })),
            { options: response.promise_date_updates.options },
          ];

          this.delaysOptions = [
            ...response.delays.options.map((item) => ({
              label: item,
              value: item,
            })),
            { options: response.delays.options },
          ];

          this.customers = [
            ...response.customers.options.map((item) => ({
              label: item,
              value: item,
            })),
            { options: response.customers.options },
          ];

          this.suppliers = [
            ...response.suppliers.options.map((item) => ({
              label: item,
              value: item,
            })),
            { options: response.suppliers.options },
          ];

          this.buyers = [
            ...response.buyers.options.map((item) => ({
              label: item,
              value: item,
            })),
            { options: response.buyers.options },
          ];
          this.shippedStatusOption = [
            ...response.shipped_status.options.map((item) => ({
              label: item,
              value: item,
            })),
            { options: response.shipped_status.options },
          ];
          this.dateRangeDueDate.from = response.due_date.value_from;
          this.dateRangeDueDate.to = response.due_date.value_to;
          this.dateRangeLastUpdatedDate.from =
            response.last_updated_date.value_from;
          this.dateRangeLastUpdatedDate.to =
            response.last_updated_date.value_to;
          this.dateRangeNextScheduledDate.from =
            response.next_scheduled_date.value_from;
          this.dateRangeNextScheduledDate.to =
            response.next_scheduled_date.value_to;
          this.unread_orders = response.unread_orders.value;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async resetFilters() {
      this.isSubTableOpen = {};
      this.is_OrderMatching = false;
      this.is_PromisedDateUpdates = false;
      this.is_Delays = false;
      this.is_shippedStatus = false;
      this.selectedDelays = null;
      this.selectedOrderMatching = null;
      this.selectedPromisedDateUpdates = null;
      this.selectedBuyers = null;
      this.selectedCustomers = null;
      this.selectedSuppliers = null;
      this.selectedDateFilter = null;
      this.selectedShippedStatus = null;
      this.priceMismatchValue = "";
      this.LineItemMismatchValue = "";
      this.customDelayValue = "";
      this.customPromiseDateValue = "";
      this.dateRangeDueDate = {
        from: "",
        to: "",
      };
      this.dateRangeNextScheduledDate = {
        from: "",
        to: "",
      };
      this.dateRangeLastUpdatedDate = {
        from: "",
        to: "",
      };
      this.filter_count = 0;
      const body = this.createBody();
      await this.getOrders(body);
    },
    onSelect(type, label, isSelected) {
      if (isSelected) {
        if (type === "orderMatching") {
          if (
            label !== "Price Mismatch" &&
            label !== "PO/Line Item Missing in Supplier"
          ) {
            this.priceMismatchToggle = false;
            this.priceMismatchValue = "";
            this.LineItemMismatchToggle = false;
            this.LineItemMismatchValue = "";
          }
          this.selectedOrderMatching = label;
        } else if (type === "promisedDateUpdates") {
          this.selectedPromisedDateUpdates = label;
        } else if (type === "delays") {
          if (label !== "Custom") {
            this.customDelayValue = null; // Reset custom value when another checkbox is selected
          }
          this.selectedDelays = label;
        } else if (type === "Customers") {
          this.selectedCustomers = label;
        } else if (type === "Buyers") {
          this.selectedBuyers = label;
        } else if (type === "Suppliers") {
          this.selectedSuppliers = label;
        } else if (type === "Custom") {
          this.selectedDelays = label;
        } else if (type === "DateFilter") {
          this.selectedDateFilter = label;
        } else if (type === "Shipped Status") {
          this.selectedShippedStatus = label;
        } else if (type === "CustomPromiseDate") {
          if (label !== "Custom") {
            this.customPromiseDateValue = null; // Reset custom value when another checkbox is selected
          }
          this.selectedPromisedDateUpdates = label;
        }
      }
    },

    async exportERP() {
      try {
        const body = this.createBody();
        Loading.show();
        await OrderService.getExportQuote(body).then((x) => {
          commonFunction.downloadQuote(x.data);
          Loading.hide();
        });
      } catch (error) {
        Loading.hide();
        //AlertService.Error(error.response);
      }
    },
    showHideOrderSub(row) {
      if (this.isSubTableOpen[row.rowIndex]) {
        this.isSubTableOpen[row.rowIndex] = false;
        this.openMPN = null;
        this.openPONumber = null;
      } else {
        this.isSubTableOpen = {};
        this.openMPN = row.mpn;
        this.openPONumber = row.po_number;
        this.isSubTableOpen[row.rowIndex] = true;
        this.getSubTableOrder(row);
      }
    },
    async getSubTableOrder(row) {
      // Fetch and set subtable data
      try {
        let data = await OrderService.getOrdersSubTable(row.po_number, row.mpn);
        const result = [];

        // Process shipping_info
        if (data.shipping_info) {
          for (const [key, info] of Object.entries(data.shipping_info)) {
            result.push({
              key,
              ...info,
              type: "shipping_info",
            });
          }
        }

        // Process schedules
        if (data.schedules) {
          for (const [key, schedule] of Object.entries(data.schedules)) {
            result.push({
              key,
              ...schedule,
              type: "schedules",
            });
          }
        }

        this.subTableData = result;
      } catch (err) {
        console.error(err);
      }
    },
    toggleClose(value) {
      if (value) {
        this.responseData.map((item) => {
          if (
            item.mpn === this.openMPN &&
            item.po_number === this.openPONumber
          ) {
            this.isSubTableOpen[value] = false;
          }
        });
      }
    },
    openNotification(item) {
      this.$refs.orderNotificationDialog.open(item, false);
    },
    async search_order(searchTerm) {
      await OrderService.searchOrders(searchTerm)
        .then((response) => {
          this.responseData = response.map((item, index) => {
            return {
              ...item,
              rowIndex: index + 1,
            };
          });
          this.loader = false;
          Loading.hide();
        })
        .catch((err) => {
          Loading.hide();
          console.log(err);
        });
    },
    async updateRead(row) {
      const body = {
        orders: [
          {
            po_number: row.po_number,
            mpn: row.mpn,
          },
        ],
      };
      await NotifyService.markasAllReadOrders(body);
      await this.reloadOrderTable();
    },
    toggleAllRows(value) {
      // Toggle the selection of all rows
      this.data.forEach((row) => {
        row.checkboxSelection = value;
      });
    },
    handleInvalidDate(flag) {
      this.invalidDate = flag;
    },
    async reloadOrderTable() {
      const body = this.createBody();
      await this.getOrders(body);
    },
  },
  async mounted() {
    try {
      await this.getFilters();
      setTimeout(async () => {
        const body = this.createBody();
        await this.getOrders(body);
        this.getIcons();
        document.addEventListener("keydown", this.handleKeyDown);
      }, 1000);
    } catch (error) {
      console.error("Error in component:", error);
    }
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  data() {
    return {
      ERPColumn: this.myStore.fields?.order_fields || [],
      currentHideKey: {},
      filter: [],
      responseData: [],
      index: 0,
      orderMatchingOptions: [],
      promisedDateUpdatesOptions: [],
      delaysOptions: [],
      shippedStatusOption: [],
      customers: [],
      suppliers: [],
      buyers: [],
      dateFilter: ["Due Date", "Next Scheduled Date", "Last Updated Date"],
      selectedOrderMatching: null,
      selectedPromisedDateUpdates: null,
      selectedDelays: null,
      selectedShippedStatus: null,
      customDelayValue: null,
      customPromiseDateValue: null,
      selectedCustomers: null,
      selectedSuppliers: null,
      selectedBuyers: null,
      selectedDateFilter: null,
      is_OrderMatching: false,
      is_PromisedDateUpdates: false,
      is_Delays: false,
      is_shippedStatus: false,
      openMPN: null,
      openPONumber: null,
      priceMismatchToggle: false,
      LineItemMismatchToggle: false,
      priceMismatchValue: "",
      LineItemMismatchValue: "",
      dateRangeDueDate: {
        from: "",
        to: "",
      },
      dateRangeLastUpdatedDate: {
        from: "",
        to: "",
      },
      dateRangeNextScheduledDate: {
        from: "",
        to: "",
      },
      pending_delivery_date: false,
      unread_orders: false,
      isDropdownOpen: false,
      isOrderDropdownOpen: false,
      isDelayDropdownOpen: false,
      isPromisedDropdownOpen: false,
      isShipDropdownOpen: false,
      allRowsSelected: false,
      filter_count: 0,
      selectedBuyersFilterFlag: false,
      selectedCustomersFilterFlag: false,
      selectedSuppliersFilterFlag: false,
      selectedDateFilterFilterFlag: false,
      invalidDate: false,
      hasError: {
        priceMismatchCustom: false,
        LineItemMismatchCustom: false,
        customPromiseDate: false,
        customDelay: false,
      },
      ErrorMessage: "Enter a valid number greater than 0",
    };
  },
};
</script>

<style scoped lang="scss">
.hierarchyCard .q-pagination {
  margin-top: -5px !important;
  font-size: 12px !important;
}

.q-card.hierarchyCard {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

:deep(.q-btn.btn--no-hover .q-focus-helper) {
  display: none;
}

.rotate {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(50deg);
  }

  40% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(-50deg);
  }
}

.ppn-position {
  position: relative;
}

.popover-container {
  position: absolute;
  display: inline-block;
}

.popover-button {
  color: white;
  border: none;
  margin-top: 3px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: -15px;
}

.popover-content {
  visibility: hidden;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 0px solid grey;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  transition: visibility 0.2s ease, opacity 0.2s ease;
  opacity: 0;
}

.popover-content::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

/* Show the popover on hover */
.popover-container:hover .popover-content {
  visibility: visible;
  opacity: 1;
}
.filterhead {
  display: flex;
  gap: 5px;
}
.text-input {
  width: 15%;
  border-radius: 7px;
  padding: 4px 5px 4px 10px;
  border: solid 1px #e0e0e0;
  font-size: 12px;
  color: #545766;
  font-weight: 500;
  &:focus-visible {
    outline: none;
  }
}
.text-input-toggle {
  border-radius: 7px;
  padding: 0px 5px 0px 10px;
  border: solid 1px #e0e0e0;
  font-size: 12px;
  color: #545766;
  font-weight: 500;
  &:focus-visible {
    outline: none;
  }
}
.adv-filter-wrap {
  background-color: #fff;
  h1 {
    font-size: 16px;
    font-weight: 600;
    color: #3d4050;
    border-bottom: solid 1px #e9e9e9;
    padding: 15px;
  }
}

td:focus {
  outline: none;
  border: 2px solid green !important;
}
td.copied {
  background-color: #d4edda; /* Light green background to indicate copy */
}

#home-wrap {
  padding: 0px;
}
.drawer-wrap {
  background-color: #fff;

  h1 {
    font-size: 16px;
    font-weight: 600;
    color: #3d4050;
    line-height: normal;
    margin: 0;
  }
}
.part-detail-title {
  border-bottom: solid 1px #e9e9e9;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}
.yellow-background {
  background-color: #f7ffd5;
}
.light-green-background {
  background-color: #fad2d2; /* Light green */
}
.light-grey-background {
  background: #eff0ef;
  color: var(--dark);
}
.promised-date-item {
  display: flex;
  flex-direction: column;
}
.text-input-toggle {
  border-radius: 7px;
  padding: 0px 5px 0px 10px;
  border: solid 1px #e0e0e0;
  font-size: 12px;
  color: #545766;
  font-weight: 500;
  &:focus-visible {
    outline: none;
  }
}
.apply-btn {
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.12); //new style 06
  border-radius: 10px;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 15px !important;
}
.filter-dropdown {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-height: 400px;
}

.filter-content {
  flex: 1;
  overflow-y: auto;
}

.filter-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  background: white;
  position: sticky;
  bottom: 0;
  z-index: 1;
}
.q-btn-dropdown-wrapper {
  position: relative;
  display: inline-block;
}

.badge-position {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 1;
}
</style>
