import { fetchWrapper } from "@/helpers/fetch-wrapper";
import axios from "axios";
import { API_BASE_URL } from "@/api.config";
export const UploadService = {
  // Tree
  getStaticTree,
  // Customer
  updateCustomer,
  addCustomer,
  getById,
  deleteCustomer,
  // Product
  getByProductID,
  updateProduct,
  addProduct,
  deleteProduct,
  // Iteration
  getIterationByID,
  updateIteration,
  addIteration,
  deleteIteration,
  copyOfIteration,
  // File Upload
  UploadFile,
  // Form Schema
  getSchema,
  getUiSchema,
  getiterationUiSchema,
  getiterationSchema,
  // File List & Download
  getFormTable,
  downloadFile,
  // File replace,extract,delete,rename
  ReplaceFile,
  extract,
  deleteFile,
  Rename,
  // File List and File Preview
  getFile,
  getFilePreview,
  getLifeCycle,
  getErrorBom,
  //ERP Upload
  ERPUploadFile,
  getERPFile,
  extractERP,
  ERPDownloadFile,
  ERPDeleteFile,
  //Supplier Tree
  getSupplierTree,
  //SupplierUpload
  SupplierUploadFile,
  getSupplierFile,
};
// const https = require('https')
// const apiUrl = 'http://vuelogix.sca.humalitix.com:4005/api';
const apiUrl = `${API_BASE_URL}`;
// const Baseurl='/api';
const cmuser = JSON.parse(localStorage.getItem("cmuser")) || {
  access_token: null,
};

//Customer
function updateCustomer(customer_name, body) {
  return fetchWrapper.put(`${apiUrl}/oem/${customer_name}`, body);
}
function addCustomer(body) {
  return fetchWrapper.post(`${apiUrl}/oem`, body);
}
function deleteCustomer(customer_name) {
  // return fetchWrapper.delete(`${Baseurl}/bom/${bom_name}`)
  return fetchWrapper.delete(`${apiUrl}/oem/${customer_name}`);
}
function getById(customer_name) {
  return fetchWrapper.get(`${apiUrl}/oem/${customer_name}`);
}
//Product

function updateProduct(product_name, body) {
  return fetchWrapper.put(
    `${apiUrl}/oem/{customer_name}/product/${product_name}`,
    body
  );
}
function addProduct(customer_name, body) {
  return fetchWrapper.post(`${apiUrl}/oem/${customer_name}/product`, body);
}
function deleteProduct(product_name) {
  return fetchWrapper.delete(
    `${apiUrl}/oem/{customer_name}/product/${product_name}`
  );
}
function getByProductID(product_name) {
  return fetchWrapper.get(
    `${apiUrl}/oem/{customer_name}/product/${product_name}`
  );
}
//Iteration

function updateIteration(iteration_name, body) {
  return fetchWrapper.put(
    `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}`,
    body
  );
}
function addIteration(customer_name, product_name, body) {
  return fetchWrapper.post(
    `${apiUrl}/oem/${customer_name}/product/${product_name}/iteration`,
    body
  );
}
function deleteIteration(iteration_name, body) {
  return fetchWrapper.delete(
    `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}`,
    body
  );
}
function getIterationByID(iteration_name) {
  return fetchWrapper.get(
    `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}`
  );
}
function copyOfIteration(iteration_name, new_iteration_name) {
  return fetchWrapper.post(
    `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/copy/${new_iteration_name}`
  );
}
//Bom Tree
async function getStaticTree() {
  const treeNode = await fetchWrapper.get(`${apiUrl}/tree`);
  const filtertreedata =
    treeNode &&
    treeNode.map((customer) => ({
      ...customer,
      children: customer.children.map((product) => ({
        ...product,
        children: product.children.filter(
          (iteration) =>
            (cmuser.role === "buyer" && iteration.is_part_list) ||
            (cmuser.role === "quoter" && !iteration.is_part_list)
        ),
      })),
    }));
  return filtertreedata;
  // return fetchWrapper.get('/api/staticTree.json')
}
function getLifeCycle(iteration_name) {
  return fetchWrapper.get(
    `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/lifecycle/status/`
  );
}
//Upload Table details
function getFormTable(iteration_name) {
  return fetchWrapper.get(
    `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/document`
  );
}
// File Download
async function downloadFile(iteration_name, document_name, document_type) {
  // const bom = localStorage.getItem('bom_name') || "";
  const cmuser2 = JSON.parse(localStorage.getItem("cmuser")) || {
    access_token: null,
  };
  // return fetchWrapper.get(`${apiUrl}api/quoting/${encodeIpn}/export`)

  try {
    const response = await axios.get(
      `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/document/${document_name}/download`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/json",
          "X-API-Key": cmuser2.access_token,
          "Content-Type": "application/json",
          responseType: "arraybuffer",
        },
      }
    );

    // Create a Blob from the array buffer
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    // Create a link element and trigger a download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${document_name}.${document_type}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading Excel file:", error);
  }
}
// FileUpload
function UploadFile(iteration_name, file, config) {
  let encodeIteration = encodeURIComponent(encodeURIComponent(iteration_name));
  return axios.post(
    `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${encodeIteration}/document`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        "X-API-Key": cmuser.access_token,
      },
      onUploadProgress: config.onUploadProgress,
    }
  );
}
//ERP Upload
// FileUpload
function ERPUploadFile(file, config) {
  return axios.post(`${apiUrl}/open-order/document/upload`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "X-API-Key": cmuser.access_token,
    },
    onUploadProgress: config.onUploadProgress,
  });
}
// SupplierFileUpload
function SupplierUploadFile(supplier_id, file, config) {
  return axios.post(
    `${apiUrl}/open-order/supplier_document/${supplier_id}/upload`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        "X-API-Key": cmuser.access_token,
      },
      onUploadProgress: config.onUploadProgress,
    }
  );
}
//Get ERP File
function getERPFile() {
  return fetchWrapper.get(`${apiUrl}/open-order/documents`);
}
// ERP File Download
async function ERPDownloadFile(document_name) {
  const cmuser2 = JSON.parse(localStorage.getItem("cmuser")) || {
    access_token: null,
  };

  try {
    const response = await axios.get(
      `${apiUrl}/open-order/document/${document_name}/download`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/json",
          "X-API-Key": cmuser2.access_token,
          "Content-Type": "application/json",
          responseType: "arraybuffer",
        },
      }
    );

    // Create a Blob from the array buffer
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    // Create a link element and trigger a download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${document_name}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading Excel file:", error);
  }
}
//delete ERP File
function ERPDeleteFile(document_name) {
  return fetchWrapper.delete(`${apiUrl}/open-order/document/${document_name}`);
}
//Get Supplier File
function getSupplierFile(supplier_id) {
  return fetchWrapper.get(
    `${apiUrl}/open-order/supplier_documents/${supplier_id}`
  );
}
//Replace File
function ReplaceFile(iteration_name, document_name, file, config) {
  return axios.put(
    `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/document/${document_name}`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        "X-API-Key": cmuser.access_token,
      },
      onUploadProgress: config.onUploadProgress,
    }
  );
}
// //Extract File
function extract(iteration_name, document_name) {
  // axios.post(`${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/document/${document_name}/extract`,{
  //     headers: {
  //         "Content-Type": "application/json", 'X-API-Key': cmuser.access_token

  //     },
  // })
  let data = JSON.stringify({
    iteration_name: iteration_name,
    document_name: document_name,
  });

  let config1 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/document/${document_name}/extract`,
    headers: {
      "X-API-Key": cmuser.access_token,
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config1);
}
//Rename File
function Rename(iteration_name, document_name, body) {
  return fetchWrapper.put(
    `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/rename/document/${document_name}`,
    body
  );
}
//Get File By Name
function getFile(iteration_name, document_name) {
  return fetchWrapper.get(
    `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/document/${document_name}`
  );
}
//Extract File
// function extract(iteration_name, document_name) {
//     return fetchWrapper.post(`${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/document/${document_name}/extract`)

// }
//delete File
function deleteFile(iteration_name, document_name) {
  return fetchWrapper.delete(
    `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/document/${document_name}`
  );
}
//File Preview
function getFilePreview(iteration_name, document_name) {
  return fetchWrapper.get(
    `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/document/${document_name}/preview`
  );
}
//jsom Form Schemas
function getSchema() {
  return fetchWrapper.get("data/schema.json");
}
function getUiSchema() {
  return fetchWrapper.get("data/ui_schema.json");
}
function getiterationUiSchema() {
  return fetchWrapper.get("data/iterationUiSchema.json");
}
function getiterationSchema() {
  return fetchWrapper.get("data/iterationSchema.json");
}
function getErrorBom(iteration_name, document_name) {
  // eslint-disable-next-line
  return fetchWrapper.get(
    `${apiUrl}/oem/{customer_name}/product/{product_name}/iteration/${iteration_name}/document/${document_name}/extraction/error`
  );
}
function extractERP(document_name) {
  let data = JSON.stringify({
    document_name: document_name,
  });

  let config1 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${apiUrl}/open-order/document/extract?document_name=${document_name}`,
    headers: {
      "X-API-Key": cmuser.access_token,
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config1);
}

function getSupplierTree(supplier, month, start_date, end_date) {
  // eslint-disable-next-line
  let encodedSupplier = encodeURIComponent(encodeURIComponent(supplier));

  if (start_date != undefined) {
    return fetchWrapper.get(
      `${apiUrl}/open-order/tree/${encodedSupplier}/${start_date}/${end_date}`
    );
  } else if (month != undefined) {
    return fetchWrapper.get(
      `${apiUrl}/open-order/tree/${encodedSupplier}/${month}`
    );
  } else if (supplier != undefined) {
    return fetchWrapper.get(`${apiUrl}/open-order/tree/${encodedSupplier}`);
  } else {
    return fetchWrapper.get(`${apiUrl}/open-order/tree`);
  }
}
