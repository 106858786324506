<template>
  <header class="tab-header">
    <aside class="left-menus">
      <q-btn
        @click="lifecyclestatus('Active')"
        class="left-btn mark-active"
        :disable="
          EnableOnSelection ||
          PartLibraryRows.find((row) => row.selectedRow)?.lifecycle_status ===
            'Active'
        "
      >
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Mark as Active
        </q-tooltip>
      </q-btn>
      <q-btn
        @click="lifecyclestatus('Obselete')"
        class="left-btn mark-obsolete"
        :disable="
          EnableOnSelection ||
          PartLibraryRows.find((row) => row.selectedRow)?.lifecycle_status ===
            'Obselete'
        "
      >
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Mark as Obsolete
        </q-tooltip>
      </q-btn>
      <q-btn
        class="left-btn add-watchlist"
        :disable="!isRowSelected"
        @click="fetchWatchlistSettings"
      >
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Add to watchlist
        </q-tooltip>
        <q-menu v-model="showMenu" class="watchlist-criteria-wrap">
          <q-list style="min-width: 100px" clickable>
            <h2>Watchlist Criteria</h2>
            <article class="watchlist-criteria">
              <section class="checkbox-wrap">
                <q-checkbox
                  v-model="criteria.supplier_type_preferred.value"
                  size="xs"
                  :label="criteria.supplier_type_preferred.name"
                />
                <q-checkbox
                  v-model="criteria.supplier_type_authorized.value"
                  size="xs"
                  :label="criteria.supplier_type_authorized.name"
                />
                <q-checkbox
                  v-model="criteria.supplier_type_non_authorized.value"
                  size="xs"
                  :label="criteria.supplier_type_non_authorized.name"
                />
                <div v-if="hasError.checkbox" class="q-mt-xs text-negative">
                  {{ checkboxErrorMessage }}
                </div>
              </section>

              <q-input
                outlined
                dense
                v-model.number="criteria.price_below.value"
                :label="criteria.price_below.name"
                type="number"
                :error="hasError.price"
                :error-message="priceErrorMessage"
                class="input-with-error"
              >
              </q-input>

              <section class="grid-two q-mt-md">
                <aside>
                  <q-input
                    outlined
                    dense
                    v-model.number="criteria.stock_above.value"
                    :label="criteria.stock_above.name"
                    type="number"
                    :error="hasError.stock"
                    :error-message="stockErrorMessage"
                    class="input-with-error"
                  />
                </aside>
                <aside>
                  <q-input
                    outlined-
                    dense
                    v-model.number="criteria.lead_time_below.value"
                    :label="criteria.lead_time_below.name"
                    type="number"
                    :error="hasError.leadTime"
                    :error-message="leadTimeErrorMessage"
                    class="input-with-error"
                  />
                </aside>
              </section>

              <section class="flex-end grid-two">
                <q-btn
                  class="quote-btn"
                  outline
                  label="Cancel"
                  color="negative"
                  @click="closeMenu"
                ></q-btn>
                <q-btn
                  class="quote-btn"
                  outline
                  label="Apply"
                  @click="applyChanges"
                ></q-btn>
              </section>
            </article>
          </q-list>
        </q-menu>
      </q-btn>

      <button class="left-btn remove-watchlist" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Remove from watchlist
        </q-tooltip>
      </button>
    </aside>

    <aside class="search-wrap">
      <q-input v-model="search" outlined placeholder="Search > 3 letters">
      </q-input>
      <section v-if="showSuggestions" class="search-suggestions">
        <q-inner-loading
          :showing="visible"
          label="Please wait..."
          label-class="text-teal"
          label-style="font-size: 1.1em"
        />

        <p class="text-bold">IPN :</p>
        <aside class="search-tags">
          <li v-if="autosuggest_ipn.length == 0" class="search_nodata">
            No suggestion found
          </li>
          <li
            v-for="(item, index) in autosuggest_ipn"
            :key="index"
            @click="updateSearch(item, 'ipn')"
          >
            {{ item }}
          </li>
        </aside>
        <p class="text-bold q-mt-xs">MPN :</p>
        <aside class="search-tags">
          <li v-if="autosuggest_mpn.length == 0" class="search_nodata">
            No suggestion found
          </li>
          <li
            v-for="(item, index) in autosuggest_mpn"
            :key="index"
            @click="updateSearch(item, 'mpn')"
          >
            {{ item }}
          </li>
        </aside>
        <p class="text-bold q-mt-xs">Manufacturer :</p>
        <aside class="search-tags">
          <li v-if="autosuggest_manufacturer.length == 0" class="search_nodata">
            No suggestion found
          </li>
          <li
            v-for="(item, index) in autosuggest_manufacturer"
            :key="index"
            @click="updateSearch(item, 'manufacturer')"
          >
            {{ item }}
          </li>
        </aside>
        <p class="text-bold q-mt-xs">Category :</p>
        <aside class="search-tags">
          <li v-if="autosuggest_category.length == 0" class="search_nodata">
            No suggestion found
          </li>
          <li
            v-for="(item, index) in autosuggest_category"
            :key="index"
            @click="updateSearch(item, 'category')"
          >
            {{ item }}
          </li>
        </aside>
      </section>
    </aside>

    <aside class="right-menu">
      <button class="right-btn add-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Add
        </q-tooltip>
      </button>
      <button class="right-btn update-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Updatepricing
        </q-tooltip>
      </button>
      <!-- <q-btn
        @click="deletepart()"
        class="right-btn delete-btn"
        :disable="!isRowSelected"
      > -->
      <q-btn @click="promptDeletePart()" class="right-btn delete-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Delete
        </q-tooltip>
      </q-btn>
      <q-dialog v-model="confirmDeleteDialog" persistent>
        <q-card class="bg-white">
          <q-card-section class="row items-center q-py-md">
            <q-icon name="warning" size="2.5em" color="negative" />
            <span class="q-ml-lg"
              >Are you sure you want to delete this part?</span
            >
          </q-card-section>
          <q-card-actions align="right">
            <q-btn flat label="Cancel" color="primary" v-close-popup />
            <q-btn
              flat
              label="Delete"
              color="negative"
              @click="confirmDeletion"
            />
          </q-card-actions>
        </q-card>
      </q-dialog>
      <button class="right-btn upload-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Upload
        </q-tooltip>
      </button>
      <button class="right-btn download-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Download
        </q-tooltip>
      </button>
    </aside>

    <button class="more-mob-btn">
      <q-icon name="more_vert" color="grey" class="more-mob-icon" />
      <q-menu>
        <q-list class="mob-btn-flex">
          <button class="right-btn add-btn" disabled>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              <strong>Add</strong>
            </q-tooltip>
          </button>
          <button class="right-btn update-btn" disabled>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              Update <strong>pricing</strong>
            </q-tooltip>
          </button>
          <button class="right-btn delete-btn" disabled>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              Delete
            </q-tooltip>
          </button>
          <button class="right-btn upload-btn" disabled>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              Upload
            </q-tooltip>
          </button>
          <button class="right-btn download-btn" disabled>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              Download
            </q-tooltip>
          </button>
        </q-list>
      </q-menu>
    </button>
  </header>

  <section class="table-wrap">
    <SpinnerComponent type="Table" v-if="loading" />
    <q-markup-table
      v-if="!loading"
      class="TableHeight-partlib my-sticky-header-table"
    >
      <thead>
        <tr>
          <th
            class="text-left"
            v-for="(col, index) in partslibraryFields"
            :key="index"
          >
            <span>{{ col.label }}</span>
          </th>
        </tr>
      </thead>
      <tbody v-for="(part, index) in data" :key="index">
        <tr class="text-left">
          <td
            class="text-left"
            v-for="(col, col_index) in partslibraryFields"
            :key="col_index"
            @click="
              col.key === 'ipn'
                ? open('right', index)
                : col.key === 'mpn'
                ? openMPNDialog('right', part)
                : null
            "
            :class="{
              'clickable-column-mpn-ipn':
                col.key === 'ipn' || col.key === 'mpn',
            }"
          >
            <span v-if="col.key == 'index'">
              <q-checkbox
                :model-value="isPartSelected(part)"
                size="xs"
                @update:model-value="toggleSelection(part)"
                class="q-ml-sm q-mb-md partlib"
              />
            </span>
            <span v-else-if="col.key == 'image'">
              <div class="table-img">
                <img
                  v-if="
                    part.image_url != '' &&
                    part.image_url != null &&
                    part.image_url != 'None'
                  "
                  :src="part.image_url"
                />
              </div>
            </span>
            <span v-else-if="col.key == 'mpn'">
              {{ part.mpn }}
              <q-icon
                v-if="part.is_watched_part"
                name="bookmark_added"
                class="q-ml-sm"
                size="xs"
                color="positive"
              />
            </span>
            <span v-else-if="col.key == 'description'">
              {{ part[col.key] != null ? cleanupWords(part[col.key]) : "-"
              }}<q-icon
                v-if="part[col.key] != null"
                name="more_horiz"
                class="more-icon"
              />
              <q-tooltip
                v-if="part[col.key] != null"
                anchor="bottom middle"
                self="top middle"
                :offset="[-5, -5]"
              >
                {{ part[col.key] }}
              </q-tooltip>
            </span>
            <span v-else-if="col.key == 'category'">
              {{ part[col.key] != null ? cleanupWords(part[col.key]) : "-"
              }}<q-icon
                v-if="part[col.key] != null"
                name="more_horiz"
                class="more-icon"
              />
              <q-tooltip
                v-if="part[col.key] != null"
                anchor="bottom middle"
                self="top middle"
                :offset="[-5, -5]"
              >
                {{ part[col.key] }}
              </q-tooltip>
            </span>
            <span v-else-if="col.key == 'created_at'" class="active-td">
              <span v-if="part.lifecycle_status == 'Obselete'">
                <img src="@/assets/obsolete-icon.svg" alt="" />
                <q-tooltip
                  anchor="bottom middle"
                  self="top middle"
                  :offset="[-5, -5]"
                >
                  Obsolete
                </q-tooltip>
              </span>
              <span v-else>
                <h5>Active</h5>
                <h6>
                  {{
                    part[col.key] != null
                      ? formatActivesince(part[col.key])
                      : "-"
                  }}
                </h6>
              </span>
            </span>
            <span
              v-else-if="col.key == 'no_of_suppliers'"
              :class="{
                'link-green': part[col.key] > 2,
                'link-amber': part[col.key] <= 2,
                'link-red': part[col.key] == null || part[col.key] == 0,
                'clickable-column': col.key === 'no_of_suppliers',
              }"
              @click="open('right', index)"
              >{{ part[col.key] }} Suppliers
              <q-icon
                name="link"
                class="link-icon"
                @click="open('right', index)"
            /></span>
            <span v-else-if="col.key == 'last_updated_date'"
              >{{ moment(part[col.key]).format("MM-DD-YYYY") }}
            </span>
            <span v-else>{{ part[col.key] }}</span>
          </td>
        </tr>
      </tbody>
    </q-markup-table>

    <q-dialog
      v-model="dialog"
      class="part-alternates"
      id="home-wrap"
      persistent
      :maximized="maximizedToggle"
      @keyup.esc="dialog = false"
      @hide="clearDialogData"
    >
      <q-card class="drawer-wrap">
        <header class="part-detail-title">
          <h1>
            <q-icon name="category" class="link-icon" />

            {{ selectedPartDetails.ipn }}
          </h1>

          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip class="bg-white text-primary">Close</q-tooltip>
          </q-btn>
        </header>

        <article class="q-pa-md">
          <section class="part-detail-wrap">
            <aside class="parts-thumb">
              <img src="@/assets/parts-thumb.jpg" alt="" />
            </aside>

            <aside>
              <p>
                IPN : <span>{{ selectedPartDetails.ipn }}</span>
              </p>

              <p>
                Description : <span>{{ selectedPartDetails.description }}</span>
              </p>

              <p>
                Category : <span>{{ selectedPartDetails.category }}</span>
              </p>
              <p>
                Manufacturer :
                <span>{{ selectedPartDetails.manufacturer }}</span>
              </p>

              <p>
                Status :

                <span v-if="selectedPartDetails.lifecycle_status != null">{{
                  selectedPartDetails.lifecycle_status
                }}</span>

                <span v-else>N/A</span>
              </p>
            </aside>
          </section>

          <q-tabs
            v-model="tab"
            dense
            class="home-tab-bg q-mt-md"
            active-color="primary"
            indicator-color="purple"
            align="justify"
          >
            <q-tab name="avl" label="AVL">
              <q-badge v-if="avlTotalCount != 0" color="black" floating>{{
                avlTotalCount
              }}</q-badge>
            </q-tab>

            <q-tab name="alternateparts" label="Supplier Alternatives ">
              <q-badge v-if="aslTotalCount != 0" color="black" floating>{{
                aslTotalCount
              }}</q-badge>
            </q-tab>
            <q-tab name="notifications" label="Notifications">
              <q-badge v-if="unreadCount != 0" color="black" floating>{{
                unreadCount
              }}</q-badge>
            </q-tab>
            <q-tab name="rfqlist" label="RFQ / Part list">
              <q-badge v-if="rfqTotalCount != 0" color="black" floating>{{
                rfqTotalCount
              }}</q-badge>
            </q-tab>
          </q-tabs>

          <q-tab-panels v-model="tab" animated class="drawer-tab-panel">
            <q-tab-panel name="avl">
              <PartAlternates
                :selectedPartDetails="this.selectedPartDetails"
                @avlTotalCount="handleAvlCount"
              />
            </q-tab-panel>

            <q-tab-panel name="alternateparts">
              <SupplierAlternates
                :selectedPartDetails="this.selectedPartDetails"
                @aslTotalCount="handleAslCount"
              />
            </q-tab-panel>
            <q-tab-panel name="notifications">
              <q-card-section class="q-pa-none">
                <SpinnerComponent type="Table" v-if="loader"></SpinnerComponent>
                <q-markup-table v-if="!loader" class="notifyTable">
                  <thead>
                    <tr>
                      <th
                        class="text-left"
                        v-for="(col, index) in Notifyheader"
                        :key="index"
                      >
                        {{ col.label }}
                        <q-icon
                          v-if="col.sortable"
                          :name="
                            col.sortDirect == null || col.sortDirect == 'asc'
                              ? 'arrow_upward'
                              : 'arrow_downward'
                          "
                          @click="customSort(col)"
                        ></q-icon>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(all, index) in dataNotification"
                      :key="index"
                      :class="all.is_read ? 'text-grey' : 'text-black'"
                    >
                      <td class="text-left">
                        <q-checkbox
                          :disable="all.is_read"
                          dense
                          size="xs"
                          :model-value="all.is_read"
                          @update:model-value="updateRead(all, index)"
                          color="positive"
                        >
                          <q-tooltip
                            anchor="bottom middle"
                            self="top middle"
                            :offset="[-2, -2]"
                            >{{
                              all.is_read
                                ? "Already marked as read"
                                : "Click to Mark as read"
                            }}
                          </q-tooltip>
                        </q-checkbox>
                      </td>
                      <td class="text-left">
                        <span v-if="all.more_info.bom_name != null">
                          <a
                            class="notify-bom"
                            href="#"
                            @click="updateRead(all, index)"
                            >{{ all.more_info.bom_name }}</a
                          >
                        </span>

                        <span v-else>- </span>
                      </td>
                      <td class="text-left">
                        <span v-if="all.more_info.ipn != null">
                          <p class="notify-ipn">
                            {{
                              all.more_info.ipn != null
                                ? all.more_info.ipn
                                : "-"
                            }}
                          </p>
                        </span>
                        <span v-else>- </span>
                      </td>
                      <td class="text-left">
                        {{ formatDate(all.created_at) }}
                      </td>
                      <td class="text-left" style="width: 800px">
                        <div class="message-wrapper">
                          <span>{{ all.message }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </q-markup-table>
                <div v-if="dataNotification.length === 0 && !loader">
                  <div
                    class="full-width row flex-center text-black q-gutter-sm"
                  >
                    <q-icon size="1.5em" name="report_problem" />
                    <span> No Data Found </span>
                  </div>
                </div>
              </q-card-section>
              <div class="row justify-between q-mt-sm">
                <div class="flex" style="font-size: 12px; margin-top: 0px">
                  <div
                    class="cursor-pointer q-mr-sm q-pa-sm"
                    style="font-size: 12px"
                    dense
                    color="primary"
                  >
                    Records per page&nbsp;:&nbsp;
                    <q-btn
                      style="font-size: 12px"
                      class="btn--no-hover q-pa-none"
                      flat
                      dense
                      :label="
                        pagination.rowsPerPage === -1
                          ? 'All'
                          : pagination.rowsPerPage
                      "
                      icon-right="fa-solid fa-caret-down"
                      color="primary"
                    >
                      <q-menu>
                        <q-list
                          dense
                          v-for="(pag, index) in paginationOptions"
                          :key="index"
                        >
                          <q-item
                            clickable
                            v-close-popup
                            @click="selectpagination(pag)"
                            class="q-pa-none"
                          >
                            <q-item-section> {{ pag }}</q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-btn>
                  </div>
                  <q-pagination
                    v-model="pagination.page"
                    :max="pagesNumberNotification"
                    input
                    class="paginationclass q-mr-sm"
                    size="sm"
                    direction-links
                    boundary-links
                    icon-first="skip_previous"
                    icon-last="skip_next"
                    icon-prev="fast_rewind"
                    icon-next="fast_forward"
                  />
                </div>
              </div>
            </q-tab-panel>
            <q-tab-panel name="rfqlist">
              <section class="rfq-list-wrap">
                <h6>RFQ / Part list</h6>
                <q-markup-table>
                  <thead>
                    <th
                      v-for="col in associatedBomsColumns"
                      :class="'text-' + col.align"
                      :key="col.label"
                    >
                      <span>
                        {{ col.label }}
                      </span>
                    </th>
                  </thead>
                  <tbody>
                    <tr v-for="(bom, index) in associatedBomdata" :key="index">
                      <td class="text-left">{{ bom.index }}</td>
                      <td class="text-left">{{ bom.customer }}</td>
                      <td class="text-left">{{ bom.assembly }}</td>
                      <td class="text-left">{{ bom.bom_name }}</td>
                      <td class="text-right">
                        <q-icon
                          name="link"
                          class="link-icon"
                          @click="navigateToNotification(bom)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </q-markup-table>
                <div v-if="associatedBoms.length === 0 && !loader">
                  <div
                    class="full-width row flex-center text-black q-pa-sm q-gutter-sm"
                  >
                    <q-icon size="1.5em" name="report_problem" />
                    <span> No Data Found </span>
                  </div>
                </div>
                <div
                  class="row justify-between q-mt-sm"
                  style="
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                  "
                  v-if="!loader && associatedBoms.length > 0"
                >
                  <div class="q-ml-lg text-grey-8 hint-fullscreen"></div>
                  <div class="flex" style="font-size: 12px; margin-top: -3px">
                    <div
                      class="cursor-pointer q-mr-sm q-mt-xs q-pa-xs"
                      style="font-size: 12px"
                      dense
                      color="primary"
                    >
                      Records per page&nbsp;:&nbsp;
                      <q-btn
                        style="font-size: 12px"
                        class="btn--no-hover q-pa-none"
                        flat
                        dense
                        :label="
                          paginationAssciatedBoms.rowsPerPage === -1
                            ? 'All'
                            : paginationAssciatedBoms.rowsPerPage
                        "
                        color="primary"
                      >
                        <q-menu>
                          <q-list
                            dense
                            v-for="(
                              pag, index
                            ) in paginationOptionsAssciatedBoms"
                            :key="index"
                          >
                            <q-item
                              clickable
                              v-close-popup
                              @click="selectpaginationAssociatedBom(pag)"
                              class="q-pa-none"
                            >
                              <q-item-section> {{ pag }}</q-item-section>
                            </q-item>
                          </q-list>
                        </q-menu>
                      </q-btn>
                    </div>
                    <q-pagination
                      v-model="paginationAssciatedBoms.page"
                      :max="pagesNumberAssociatedBoms"
                      input
                      class="paginationclass q-mr-sm"
                      size="sm"
                      direction-links
                      boundary-links
                      icon-first="skip_previous"
                      icon-last="skip_next"
                      icon-prev="fast_rewind"
                      icon-next="fast_forward"
                    />
                  </div>
                </div>
              </section>
            </q-tab-panel>
          </q-tab-panels>
        </article>
      </q-card>
    </q-dialog>
    <PartAlternatesMPNDialog
      ref="partAlternatesDialog"
      :selectedPartDetails="this.selectedPartDetails"
    />
    <div v-if="PartLibraryRows.length === 0 && !loading">
      <div class="full-width row flex-center text-black q-gutter-sm">
        <q-icon size="1.5em" name="report_problem" />
        <span> No Data Found </span>
      </div>
    </div>
    <div
      class="row justify-between"
      style="border-bottom-left-radius: 20px; border-bottom-right-radius: 20px"
      v-if="!loading && PartLibraryRows.length > 0"
    >
      <div class="q-ml-lg text-grey-8 hint-fullscreen">
        <!-- <b>Tip: &nbsp;</b> Press &nbsp;<span  class="fullscreenHintText"> Shift + F</span> &nbsp; {{ toggleFullscreenTip }} -->
      </div>
      <div class="flex" style="font-size: 12px">
        <div
          class="cursor-pointer q-mr-sm q-pa-xs"
          style="font-size: 12px; margin-top: 3px"
          dense
          color="primary"
        >
          Records per page&nbsp;:&nbsp;
          <q-btn
            style="font-size: 12px"
            class="btn--no-hover q-pa-none"
            flat
            dense
            :label="
              pagination.rowsPerPage === -1 ? 'All' : pagination.rowsPerPage
            "
            icon-right="fa-solid fa-caret-down"
            color="primary"
          >
            <q-menu>
              <q-list
                dense
                v-for="(pag, index) in paginationOptions"
                :key="index"
              >
                <q-item
                  clickable
                  v-close-popup
                  @click="selectpagination(pag)"
                  class="q-pa-none"
                >
                  <q-item-section> {{ pag }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
        <q-pagination
          v-model="pagination.page"
          :max="pagesNumber"
          input
          class="paginationclass q-mr-sm"
          size="sm"
          direction-links
          boundary-links
          icon-first="skip_previous"
          icon-last="skip_next"
          icon-prev="fast_rewind"
          icon-next="fast_forward"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { ref, defineAsyncComponent } from "vue";
import { useMyStore } from "@/store";
import {
  AlterPartService,
  AlertService,
  NotifyService,
  UploadService,
} from "@/services";
import { SpinnerComponent, commonFunction } from "@/utils";
import moment from "moment";
import PartAlternatesMPNDialog from "../quotes/PartAlterNatives/PartAlternatesMPNDialog.vue";
import { Loading } from "quasar";

export default {
  setup() {
    const myStore = useMyStore();
    const pagination = ref({
      sortBy: "desc",
      descending: false,
      page: 1,
      rowsPerPage: 25,
    });
    const paginationOptions = ref([5, 7, 10, 15, 20, 25, 50, "All"]);
    const dialog = ref(false);
    const position = ref("top");
    const selectedPartDetails = ref({});
    const aslData = ref([]);
    const alternatePartsData = ref([]);
    const avlTotalCount = ref("");
    const aslTotalCount = ref("");
    const rfqTotalCount = ref("");
    const showSuggestions = ref(false);
    const debounceTimeout = ref(null);
    const associatedBomsColumns = ref([]);
    const paginationAssciatedBoms = ref({
      sortBy: "desc",
      descending: false,
      page: 1,
      rowsPerPage: 20,
    });
    const paginationOptionsAssciatedBoms = ref([5, 10, 15, 20, 25, 50, "All"]);
    return {
      val: ref(false),
      avlTotalCount,
      aslTotalCount,
      rfqTotalCount,
      pagination,
      paginationOptions,
      myStore,
      search: ref(""),
      PartLibraryRows: ref([]),
      autosuggest_ipn: ref([]),
      autosuggest_mpn: ref([]),
      autosuggest_manufacturer: ref([]),
      autosuggest_category: ref([]),
      loading: ref(false),
      dialog,
      position,
      tab: ref("avl"),
      selectedPartDetails,
      aslData,
      alternatePartsData,
      maximizedToggle: ref(true),
      showSuggestions,
      debounceTimeout,
      confirmDeleteDialog: ref(false),
      partToDelete: ref(null),
      allNotification: ref([]),
      unreadNotification: ref([]),
      sortDirection: ref(null),
      sortBy: ref(""),
      confirm: ref(false),
      loader: ref(false),
      resetconfirm: ref(false),
      associatedBomsColumns,
      paginationAssciatedBoms,
      paginationOptionsAssciatedBoms,
    };
  },
  components: {
    SpinnerComponent,
    PartAlternates: defineAsyncComponent(() =>
      import("@/components/quotes/PartAlterNatives/PartAlternates.vue")
    ),
    SupplierAlternates: defineAsyncComponent(() =>
      import("@/components/quotes/PartAlterNatives/SupplierAlternates.vue")
    ),
    PartAlternatesMPNDialog,
  },
  watch: {
    search(newval) {
      clearTimeout(this.debounceTimeout);

      this.debounceTimeout = setTimeout(() => {
        if (newval.length >= 3) {
          this.autosuggest_ipn = [];
          this.autosuggest_mpn = [];
          this.autosuggest_manufacturer = [];
          this.autosuggest_category = [];
          if (this.showSuggestions == false) {
            this.search_auto_complete();
          }
        } else {
          this.autosuggest_ipn = [];
          this.autosuggest_mpn = [];
          this.autosuggest_manufacturer = [];
          this.autosuggest_category = [];
          this.showSuggestions = false;
        }
      }, 500);
    },
    "criteria.supplier_type_preferred.value"() {
      this.clearCheckboxError();
    },
    "criteria.supplier_type_authorized.value"() {
      this.clearCheckboxError();
    },
    "criteria.supplier_type_non_authorized.value"() {
      this.clearCheckboxError();
    },
  },
  computed: {
    associatedBomdata() {
      if (this.paginationAssciatedBoms.rowsPerPage == -1) {
        return this.associatedBoms;
      } else {
        const startIndex =
          (this.paginationAssciatedBoms.page - 1) *
          this.paginationAssciatedBoms.rowsPerPage;
        const endIndex = startIndex + this.paginationAssciatedBoms.rowsPerPage;
        return this.associatedBoms.slice(startIndex, endIndex);
      }
    },
    pagesNumberAssociatedBoms() {
      if (this.paginationAssciatedBoms.rowsPerPage != -1 && this.search == "") {
        const newpage = Math.ceil(
          this.associatedBoms.length / this.paginationAssciatedBoms.rowsPerPage
        );
        return newpage;
      } else if (
        this.paginationAssciatedBoms.rowsPerPage != -1 &&
        this.search !== ""
      ) {
        const newpage = Math.ceil(
          this.associatedBoms.length / this.paginationAssciatedBoms.rowsPerPage
        );
        return newpage;
      } else {
        return 1;
      }
    },
    unreadCount() {
      return this.allNotification.filter(
        (notification) => !notification.is_read
      ).length;
    },
    toggleicon() {
      if (this.sortDirection == "asc") {
        return "arrow_upward";
      } else {
        return "arrow_downward";
      }
    },

    Notifyheader() {
      return this.myStore.fields.NotifyFields;
    },
    pagesNumberNotification() {
      if (this.pagination.rowsPerPage != -1 && this.search == "") {
        const newpage = Math.ceil(
          this.allNotification.length / this.pagination.rowsPerPage
        );
        return newpage;
      } else if (this.pagination.rowsPerPage != -1 && this.search !== "") {
        const newpage = Math.ceil(
          this.paginatedData.length / this.pagination.rowsPerPage
        );
        return newpage;
      } else {
        return 1;
      }
    },
    dataNotification() {
      if (this.pagination.rowsPerPage == -1) {
        return this.paginatedData;
      } else {
        const startIndex =
          (this.pagination.page - 1) * this.pagination.rowsPerPage;
        const endIndex = startIndex + this.pagination.rowsPerPage;
        return this.paginatedData.slice(startIndex, endIndex);
      }
    },
    paginatedData() {
      const searchTerm = this.search.toLowerCase();
      if (searchTerm !== null && searchTerm !== undefined && searchTerm != "") {
        return this.allNotification.filter((row) => {
          this.pagination.page = 1;
          const bomcheck =
            row.more_info.bom_name &&
            row.more_info.bom_name.toLowerCase().includes(searchTerm);
          const message =
            row.message && row.message.toLowerCase().includes(searchTerm);
          const ipnCheck =
            row.more_info.ipn &&
            row.more_info.ipn.toLowerCase().includes(searchTerm);

          return bomcheck || message || ipnCheck;
        });
      } else if (this.sortDirection != null) {
        const sorted = [...this.allNotification];
        sorted.sort((a, b) => {
          const labelA = a.more_info[this.sortBy]
            ? a.more_info[this.sortBy].toLowerCase()
            : "";
          const labelB = b.more_info[this.sortBy]
            ? b.more_info[this.sortBy].toLowerCase()
            : "";
          const dateA = new Date(a[this.sortBy]);
          const dateB = new Date(b[this.sortBy]);
          if (this.sortBy != "created_at") {
            if (this.sortDirection === "asc") {
              return labelA.localeCompare(labelB);
            } else if (this.sortDirection === "desc") {
              return labelB.localeCompare(labelA);
            } else {
              return this.allNotification;
            }
          } else if (this.sortBy == "created_at") {
            if (this.sortDirection === "asc") {
              return dateA - dateB;
            } else if (this.sortDirection === "desc") {
              return dateB - dateA;
            } else {
              return this.allNotification;
            }
          } else {
            return this.allNotification;
          }
        });
        return sorted;
      } else {
        return this.allNotification;
      }
    },
    partslibraryFields() {
      return this.myStore.fields.partslibrary;
    },
    pagesNumber() {
      if (this.pagination.rowsPerPage != -1 && this.search == "") {
        const newpage = Math.ceil(
          this.PartLibraryRows.length / this.pagination.rowsPerPage
        );
        return newpage;
      } else if (this.pagination.rowsPerPage != -1 && this.search !== "") {
        const newpage = Math.ceil(
          this.filteredRows.length / this.pagination.rowsPerPage
        );
        return newpage;
      } else {
        return 1;
      }
    },
    data() {
      if (this.pagination.rowsPerPage == -1) {
        return this.filteredRows;
      } else {
        const startIndex =
          (this.pagination.page - 1) * this.pagination.rowsPerPage;
        const endIndex = startIndex + this.pagination.rowsPerPage;
        return this.filteredRows.slice(startIndex, endIndex);
      }
    },
    filterSuggestion1() {
      const searchTerm = this.search ? this.search.toLowerCase() : null;
      if (searchTerm !== null && searchTerm !== undefined) {
        const seen = new Set();
        return this.PartLibraryRows.filter((row) => {
          const ipnCheck =
            row.ipn && row.ipn.toLowerCase().includes(searchTerm);
          const mpnCheck =
            row.mpn && row.mpn.toLowerCase().includes(searchTerm);
          const manufacturerCheck =
            row.manufacturer &&
            row.manufacturer.toLowerCase().includes(searchTerm);
          const categoryCheck =
            row.category && row.category.toLowerCase().includes(searchTerm);
          if (
            (ipnCheck && !seen.has(row.ipn)) ||
            (mpnCheck && !seen.has(row.mpn)) ||
            (manufacturerCheck && !seen.has(row.manufacturer)) ||
            (categoryCheck && !seen.has(row.category))
          ) {
            seen.add(row.ipn);
            seen.add(row.mpn);
            seen.add(row.manufacturer);
            seen.add(row.category);
            return true;
          }

          return false;
        });
      } else {
        return [];
      }
    },
    filteredRows() {
      const searchTerm = this.search ? this.search.toLowerCase() : null;
      if (searchTerm !== null && searchTerm !== undefined) {
        return this.PartLibraryRows.filter((row) => {
          const ipnCheck =
            row.ipn && row.ipn.toLowerCase().includes(searchTerm);
          const mpnCheck =
            row.mpn && row.mpn.toLowerCase().includes(searchTerm);
          const manufacturerCheck =
            row.manufacturer &&
            row.manufacturer.toLowerCase().includes(searchTerm);
          const categoryCheck =
            row.category && row.category.toLowerCase().includes(searchTerm);

          return ipnCheck || mpnCheck || manufacturerCheck || categoryCheck;
        });
      } else {
        return [];
      }
    },
    isRowSelected() {
      if (
        this.PartLibraryRows.some((row) => row.selectedRow) &&
        this.selectedParts.length <= 1
      ) {
        return this.PartLibraryRows.some(
          (row) => row.selectedRow
          //&& row.is_watched_part
        );
      } else {
        return false;
      }
    },
    EnableOnSelection() {
      if (
        this.filteredRows.some((row) => row.selectedRow) &&
        this.selectedParts.length <= 1
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    moment(...args) {
      return moment(...args);
    },
    async navigateToNotification(data) {
      var treeNode = [];
      Loading.show();
      await UploadService.getStaticTree().then((x) => {
        treeNode = x;
        const iterationNames = treeNode.flatMap((oem) =>
          oem.children.flatMap((product) =>
            product.children
              .filter((child) => child.type === "iteration")
              .map((iteration) => iteration.name)
          )
        );
        if (iterationNames.includes(data.bom_name)) {
          const resultNodes = commonFunction.newfindoemproduct(
            treeNode,
            data.bom_name
          );
          if (resultNodes) {
            var r1 = [];
            r1.push(resultNodes.oemName), r1.push(resultNodes.productName);
            localStorage.setItem("bom_name", resultNodes.iterationName);
            localStorage.setItem("expandedNodes", JSON.stringify(r1));
            localStorage.setItem("qty", null);
            localStorage.setItem("ipn", null);
            var quotes = {
              GroupBy: "none",
              GroupName: "None",
              Filter: { name: "All", value: "all", id: 1, selected: false },
              Filter_Field: "",
            };
            localStorage.setItem("quotespage", JSON.stringify(quotes));
            this.myStore.loadQuotes();
            this.myStore.refreshquotes = Math.random();
            Loading.hide();
            this.$router.push(
              this.myStore.userdata.role == "buyer" ? "/partlist" : "/quotes"
            );
          } else {
            console.log("No data found for the given iteration name.");
          }
        } else {
          Loading.hide();
          AlertService.Error("Item Not Found");
          this.myStore.refreshquotes = Math.random();
        }
      });
    },
    selectpaginationAssociatedBom(value) {
      //Changing the Pagination variable
      if (value == "All") {
        this.paginpaginationAssciatedBomsation.rowsPerPage = -1;
      } else {
        this.paginationAssciatedBoms.rowsPerPage = parseInt(value);
      }
      this.paginationAssciatedBoms.page = 1;
    },

    clearDialogData() {
      this.selectedPartDetails = {};
      this.avlTotalCount = "";
      this.aslTotalCount = "";
      this.rfqTotalCount = "";
      this.alternatePartsData = [];
      this.tab = "avl";
      this.dialog = false;
    },
    async open(pos, row) {
      this.position = pos;
      this.dialog = true;
      this.selectedPartDetails = this.filteredRows[row];
      this.openNotification(this.selectedPartDetails);
      this.getAssociatedBoms(this.filteredRows[row]);
      this.dialog = true;
    },
    openMPNDialog(pos, item) {
      this.$refs.partAlternatesDialog.open(pos, item);
    },
    handleAvlCount(count) {
      this.avlTotalCount = count; // Update the local state with the received quoteId
    },
    handleAslCount(count) {
      this.aslTotalCount = count; // Update the local state with the received quoteId
    },
    async partLibrary(input_type) {
      this.loading = true;
      let key = input_type !== undefined ? input_type : "Manufacturer";
      await AlterPartService.partlibrarysearch(this.search, key)
        .then((x) => {
          this.PartLibraryRows = x;
          this.PartLibraryRows.map((row, index) => {
            row.selectedRow = false;
            row.ipnIndex = row.ipn + index;
            row.rowIndex = index;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.PartLibraryRows = [];
          this.loading = false;
        });
    },
    async search_auto_complete() {
      this.showSuggestions = true;
      this.visible = true;
      await AlterPartService.autocomplete(this.search)
        .then((x) => {
          this.autosuggest_ipn = x.ipn;
          this.autosuggest_mpn = x.mpn;
          this.autosuggest_manufacturer = x.manufacturer;
          this.autosuggest_category = x.category;
        })
        .catch((err) => {
          console.log(err);
        });
      this.visible = false;
    },

    promptDeletePart() {
      // delete parts from partlibrary
      const result = this.filteredRows.find((row) => row.selectedRow === true);
      if (!result) {
        AlertService.Error(
          "Please Select one row to delete parts from partlibrary"
        );
        return;
      }
      this.partToDelete = result;
      this.confirmDeleteDialog = true;
    },
    confirmDeletion() {
      if (this.partToDelete) {
        this.deletepart(this.partToDelete);
        this.confirmDeleteDialog = false;
        this.partToDelete = null;
      }
    },
    async deletepart(result) {
      const { ipn, mpn, manufacturer } = result;
      AlterPartService.deletepartlibrary(ipn, mpn, manufacturer)
        .then((response) => {
          AlertService.Success(response);
          this.partLibrary();
        })
        .catch((error) => {
          AlertService.Error("Failed to delete this part: " + error.message);
        });
    },
    cleanupWords(description) {
      const words = commonFunction.descriptionFormat(description);
      return words;
    },
    formatActivesince(date) {
      const fdate = moment(date).format("YYYY/MM/DD");
      return fdate;
    },
    selectpagination(value) {
      //Changing the Pagination variable
      if (value == "All") {
        this.pagination.rowsPerPage = -1;
      } else {
        this.pagination.rowsPerPage = parseInt(value);
      }
      this.pagination.page = 1;
    },
    async updateSearch(options, key) {
      //part library from auto suggestion

      this.search = options;

      this.loading = true;
      await AlterPartService.partlibrarysearch(this.search, key)
        .then((x) => {
          this.PartLibraryRows = x;
          this.inputType_Key = key;
          this.PartLibraryRows.map((row, index) => {
            row.selectedRow = false;
            row.ipnIndex = row.ipn + index;
            row.rowIndex = index;
          });
        })
        .catch((err) => {
          this.PartLibraryRows = [];
          console.log(err);
        });
      this.loading = false;
      this.showSuggestions = false;
    },
    async lifecyclestatus(status) {
      //Update the life cycle status
      const result = this.PartLibraryRows.find(
        (row) => row.selectedRow === true
      );
      if (result) {
        const { ipn, mpn, manufacturer } = result;
        await AlterPartService.updatepartlifecycle(
          ipn,
          mpn,
          manufacturer,
          status
        )
          .then((x) => {
            AlertService.Success(x);
            this.selectedParts = [];
            this.partLibrary();
          })
          .catch((err) => {
            AlertService.Error(err);
          });
      } else {
        AlertService.Error("Please Select one row to update  Status");
      }
    },
    isPartSelected(part) {
      return this.selectedParts.includes(part);
    },
    toggleSelection(part) {
      if (this.isPartSelected(part)) {
        this.PartLibraryRows.forEach((data) => {
          if (data.ipnIndex == part.ipnIndex) {
            data.selectedRow = !data.selectedRow;
          }
        });
        this.selectedParts = this.selectedParts.filter(
          (selectedPart) => selectedPart !== part
        );
      } else {
        this.PartLibraryRows.forEach((data) => {
          if (data.ipnIndex == part.ipnIndex) {
            data.selectedRow = !data.selectedRow;
          }
        });
        this.selectedParts.push(part);
      }
    },

    async fetchWatchlistSettings() {
      const firstTag = this.myStore.userdata.username;
      await AlterPartService.watchlistSettings(
        this.selectedParts[0]?.ipn,
        this.selectedParts[0]?.mpn,
        this.selectedParts[0]?.manufacturer,
        firstTag
      )
        .then((response) => {
          this.criteria = response;
          // Show the menu after the data is loaded
          this.showMenu = true;
        })
        .catch((err) => {
          AlertService.Error(err);
        });
    },
    applyChanges() {
      this.resetErrors();

      let isValid = true;

      // Validate checkboxes
      if (
        !this.criteria.supplier_type_preferred.value &&
        !this.criteria.supplier_type_authorized.value &&
        !this.criteria.supplier_type_non_authorized.value
      ) {
        this.hasError.checkbox = true;
        this.checkboxErrorMessage = "Select at least a supplier type";
        isValid = false;
      }
      if (!this.validateNumber(this.criteria.price_below.value)) {
        this.hasError.price = true;
        this.priceErrorMessage = "Enter a valid number or 0 ";
        isValid = false;
      }
      if (!this.validateNumber(this.criteria.stock_above.value)) {
        this.hasError.stock = true;
        this.stockErrorMessage = "Enter a valid number or 0";
        isValid = false;
      }
      if (!this.validateNumber(this.criteria.lead_time_below.value)) {
        this.hasError.leadTime = true;
        this.leadTimeErrorMessage = "Enter a valid number or 0";
        isValid = false;
      }

      if (isValid) {
        const updatedCriteria = {
          price_below: {
            name: this.criteria.price_below.name,
            value: this.criteria.price_below.value,
            data_type: "numeric",
          },
          stock_above: {
            name: this.criteria.stock_above.name,
            value: this.criteria.stock_above.value,
            data_type: "numeric",
          },
          lead_time_below: {
            name: this.criteria.lead_time_below.name,
            value: this.criteria.lead_time_below.value,
            data_type: "numeric",
          },
          supplier_type_preferred: {
            name: this.criteria.supplier_type_preferred.name,
            value: this.criteria.supplier_type_preferred.value,
            data_type: "bool",
          },
          supplier_type_authorized: {
            name: this.criteria.supplier_type_authorized.name,
            value: this.criteria.supplier_type_authorized.value,
            data_type: "bool",
          },
          supplier_type_non_authorized: {
            name: this.criteria.supplier_type_non_authorized.name,
            value: this.criteria.supplier_type_non_authorized.value,
            data_type: "bool",
          },
        };
        // Make API call to update
        this.addtowatchlist(updatedCriteria);
      }
    },
    resetErrors() {
      this.hasError.price = false;
      this.hasError.stock = false;
      this.hasError.leadTime = false;
      this.priceErrorMessage = "";
      this.stockErrorMessage = "";
      this.leadTimeErrorMessage = "";
      this.checkboxErrorMessage = "";
    },
    validateNumber(value) {
      return !isNaN(value) && value !== null && value !== "" && value >= 0;
    },
    clearCheckboxError() {
      // Clear checkbox error when any checkbox is selected
      if (
        this.criteria.supplier_type_preferred.value ||
        this.criteria.supplier_type_authorized.value ||
        this.criteria.supplier_type_non_authorized.value
      ) {
        this.hasError.checkbox = false;
        this.checkboxErrorMessage = "";
      }
    },
    async addtowatchlist(body) {
      if (body) {
        const firstTag = this.myStore.userdata.username;
        await AlterPartService.addwatchlist(
          this.selectedParts[0]?.ipn,
          this.selectedParts[0]?.mpn,
          this.selectedParts[0]?.manufacturer,
          firstTag,
          body
        )
          .then(() => {
            AlertService.Success("Added to Watchlist");
            this.showMenu = false;
            this.selectedParts = [];
            this.partLibrary(this.inputType_Key);
          })
          .catch((err) => {
            AlertService.Error(err);
          });
      } else {
        AlertService.Error("Please Select one row to add watchlist");
      }
    },
    closeMenu() {
      this.resetErrors();
      this.showMenu = false;
    },
    async getNotifyAll(queryParams) {
      this.loader = true;
      if (queryParams) {
        const [allnotify, unreadNotify, Cunread] = await Promise.all([
          NotifyService.getNotificationIPN(queryParams.ipn),
          NotifyService.UnreadNotification(),
          NotifyService.Notificationcount(),
        ]);

        this.allNotification = allnotify.sort(
          (a, b) => new Date(b["created_at"]) - new Date(a["created_at"])
        );

        this.unreadNotification = unreadNotify.sort(
          (a, b) => new Date(b["created_at"]) - new Date(a["created_at"])
        );
        this.myStore.unreadcount = Cunread;
      }
      this.loader = false;
    },
    formatDate(date) {
      var d = moment.utc(date).utcOffset("+05:30").fromNow();
      return d;
    },

    openconfirm(type) {
      if (type == "mark") {
        this.resetconfirm = false;
      } else {
        this.resetconfirm = true;
      }
      this.confirm = true;
    },

    customSort(column) {
      this.search = "";
      this.sortBy = column.key;
      if (this.sortDirection == null) {
        column.sortDirect = "asc";
        this.sortDirection = "asc";
      } else if (this.sortDirection == "asc") {
        this.sortDirection = "desc";
        column.sortDirect = "desc";
      } else {
        column.sortDirect = null;
        this.sortDirection = null;
      }
    },
    async updateReadAll() {
      if (this.myStore.unreadcount > 0) {
        this.resetconfirm = false;
        this.myStore.unreadcount = 0;
        const body = {
          uids: this.unreadNotification.map((msg) => msg.uid),
        };
        await NotifyService.markasAllRead(body).then((x) => {
          console.log(x);
        });
        this.allNotification.map((unread) => {
          unread.read = true;
        });
        AlertService.Success("All Notifications are  Marked as Read ");
        this.myStore.refreshquotes = Math.random();
        await this.getNotifyAll();
        this.confirm = false;
      } else {
        AlertService.Error(
          "All notifications have already been marked as read."
        );
        this.confirm = false;
      }
    },
    async updateRead(data, index) {
      if (!data.is_read) {
        // var treeNode = []
        this.myStore.unreadcount =
          this.myStore.unreadcount > 0 ? this.myStore.unreadcount - 1 : 0;
        this.allNotification[index].read = true;
        await NotifyService.markasRead(data.uid).then((x) => {
          console.log(x);
        });
        AlertService.Success("Notification has Marked as Read");
        if (data.more_info.bom_name) {
          this.selectingBomIpn(data);
        }
        await this.getNotifyAll(this.queryParams);
        // }, 5000)
      } else {
        AlertService.Error("Notification Already Marked as Read");
        if (data.more_info.bom_name) {
          this.selectingBomIpn(data);
        }
      }
    },
    async selectingBomIpn(data) {
      var treeNode = [];
      Loading.show();
      await UploadService.getStaticTree().then((x) => {
        treeNode = x;
        const iterationNames = treeNode.flatMap((oem) =>
          oem.children.flatMap((product) =>
            product.children
              .filter((child) => child.type === "iteration")
              .map((iteration) => iteration.name)
          )
        );
        if (iterationNames.includes(data.more_info.bom_name)) {
          const resultNodes = commonFunction.newfindoemproduct(
            treeNode,
            data.more_info.bom_name
          );
          if (resultNodes) {
            var r1 = [];
            r1.push(resultNodes.oemName), r1.push(resultNodes.productName);
            localStorage.setItem("bom_name", resultNodes.iterationName);
            localStorage.setItem("expandedNodes", JSON.stringify(r1));
            localStorage.setItem("qty", null);
            localStorage.setItem("ipn", null);
            var quotes = {
              GroupBy: "none",
              GroupName: "None",
              Filter: { name: "All", value: "all", id: 1, selected: false },
              Filter_Field: "",
            };
            localStorage.setItem("quotespage", JSON.stringify(quotes));
            this.myStore.loadQuotes();
            this.myStore.refreshquotes = Math.random();
            Loading.hide();
            this.$router.push(
              this.myStore.userdata.role == "buyer" ? "/partlist" : "/quotes"
            );
          } else {
            console.log("No data found for the given iteration name.");
          }
        } else {
          Loading.hide();
          AlertService.Error("Item Not Found");
          this.myStore.refreshquotes = Math.random();
        }
      });
    },
    async getAssociatedBoms(item) {
      this.loader = true;
      const ipn = item.ipn;
      const mpn = item.mpn;
      const manufacturer = item.manufacturer;
      try {
        const x = await AlterPartService.getAssociatedBoms(
          ipn,
          mpn,
          manufacturer
        );
        this.associatedBoms = x.map((bom, index) => ({
          ...bom,
          index: index + 1,
        }));
        this.rfqTotalCount = this.associatedBoms.length;

        this.loader = false;
      } catch (err) {
        console.log(err);
        this.loader = false;
        this.associatedBoms = [];
      }
    },
    async openNotification(item) {
      this.queryParams = item;
      await this.getNotifyAll(item);
    },
  },
  data() {
    return {
      selectedParts: [], // Array to keep track of selected rows
      showMenu: false,
      visible: false,
      criteria: {
        price_below: { name: "", value: "", data_type: "numeric" },
        stock_above: { name: "", value: "", data_type: "numeric" },
        lead_time_below: { name: "", value: "", data_type: "numeric" },
        supplier_type_preferred: { name: "", value: true, data_type: "bool" },
        supplier_type_authorized: { name: "", value: true, data_type: "bool" },
        supplier_type_non_authorized: {
          name: "",
          value: false,
          data_type: "bool",
        },
      },
      hasError: {
        price: false,
        stock: false,
        leadTime: false,
        checkbox: false,
      },
      priceErrorMessage: "",
      stockErrorMessage: "",
      leadTimeErrorMessage: "",
      queryParams: "",
      associatedBoms: [],
      inputType_Key: "",
    };
  },
  mounted() {
    this.associatedBomsColumns = this.myStore.fields.associatedBom;
  },
};
</script>
<style scoped lang="scss">
.link-icon {
  cursor: pointer;
}

.drawer-wrap {
  //width: 1500px;
  //height: 100%;
  background-color: #fff;
  //padding: 15px;

  h1 {
    font-size: 16px;
    font-weight: 600;
    color: #3d4050;
    line-height: normal;
    margin: 0;
    // border-bottom: solid 1px #e9e9e9;
    // padding-bottom: 10px;
    // padding-left: 15px;
    // margin-left: -15px;
    // margin-right: -15px;
  }
}

.part-detail-wrap {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 15px;
  // margin-top: 15px;

  p {
    font-size: 13px;
    color: #777984;
    span {
      color: #3d4050;
    }
  }
}

.parts-thumb {
  img {
    width: 230px;
    height: 165px;
    object-fit: cover;
    border-radius: 10px;
  }
}

.trend-chart-wrap {
  border-radius: 10px;
  margin-top: 15px;
  background-color: #f6f6f7;
  border: solid 1px #e9e9e9;
  padding: 15px;

  h2 {
    color: #3d4050;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 15px;
  }
}

.drawer-tab-panel {
  border: solid 1px #e9e9e9 !important;
  padding: 15px;
  border-radius: 0px 0px 10px 10px !important;
}

.part-detail-title {
  border-bottom: solid 1px #e9e9e9;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}

#home-wrap {
  padding: 0px;
}

.clickable-column {
  cursor: pointer;
}
.clickable-column-mpn-ipn {
  cursor: pointer;
  color: #007bff; /* Example color for hover effect */
  text-decoration: underline; /* Add underline on hover */
}
.watchlist-criteria-wrap {
  h2 {
    color: #3d4050;
    font-weight: 600;
    font-size: 14px;
    border-bottom: solid 1px #eaeaea;
    padding: 15px;
  }
}
.watchlist-criteria {
  padding: 15px;
}
.checkbox-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding-bottom: 15px;
}
.grid-two {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 15px;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.notifyTable .glyphicon {
  visibility: hidden;
}
.message-wrapper {
  white-space: normal;
  overflow-wrap: break-word;
}
.rfq-list-wrap {
  margin-top: 15px;
  h6 {
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    color: var(--primary);
    margin-bottom: 5px;
  }
}
</style>
