<template>
  <spinner-component v-if="loader" type="Table"></spinner-component>

  <q-card ref="fullscreenTarget" class="BomTabCard">
    <q-card-actions class="filterhead table-card-actions" v-if="!loader">
      <q-btn
        @click="toggleFullScreen"
        flat
        round
        dense
        :icon="toggleicon"
        class="round-icon-btn"
      >
        <q-tooltip anchor="bottom middle" self="top middle" :offset="[4, 4]">{{
          toggleFullscreenTip
            .replace("to", "")
            .replace("enter", "Enter")
            .replace("exit", "Exit")
        }}</q-tooltip>
      </q-btn>
      <q-btn
        round
        dense
        flat
        color="primary"
        icon="more_vertical"
        disable
        class="round-icon-btn"
      />
      <q-input
        v-if="isSearchOpen"
        v-model="search"
        label="Search"
        outlined
        dense
        class="treeSearch2"
        ref="searchInput"
      >
        <template v-slot:append>
          <q-btn
            flat
            dense
            color="primary"
            size="sm"
            icon="clear"
            @click="closeSearch"
          />
        </template>
      </q-input>
      <q-btn
        v-if="isSearchOpen == false"
        round
        dense
        flat
        color="primary"
        icon="search"
        @click="toggleSearch"
        class="round-icon-btn"
      >
        <q-tooltip anchor="bottom middle" self="top middle">Search</q-tooltip>
      </q-btn>
      <!-- <q-space />
      <q-breadcrumbs align="center" style="font-weight: 600;" class="qsummarybread">
        <q-breadcrumbs-el :label="formattedString(titledata[0])" icon="factory">
          <q-tooltip anchor="bottom middle" self="top middle" :offset="[-1, -1]">
            {{ myStore.NOEM }}
          </q-tooltip>
        </q-breadcrumbs-el>
        <q-breadcrumbs-el :label="formattedString(titledata[1])" icon="book"> <q-tooltip anchor="bottom middle"
            self="top middle" :offset="[-1, -1]">
            {{ myStore.NProduct.product }}
          </q-tooltip></q-breadcrumbs-el>
        <q-breadcrumbs-el  @click="showTree()" class="text-info cursor-pointer" :label="formattedString(titledata[2])" icon="login"> <q-tooltip anchor="bottom middle"
            self="top middle" :offset="[-1, -1]">
            {{ myStore.NIter.iteration }}
          </q-tooltip></q-breadcrumbs-el>
      </q-breadcrumbs> -->
      <q-space />
      <q-btn-dropdown
        v-if="myStore.partListStatus == false"
        outline
        color="primary"
        sixe="md"
        dense
        :label="selectedQtyLabel"
        class="custom-button"
      >
        <q-list dense>
          <q-item
            v-for="(qty, index) in rfq"
            :key="index"
            clickable
            v-close-popup
            @click="options(qty.value)"
          >
            <q-item-section>
              {{ qty.value }}
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>

      <q-btn
        size="md"
        dense
        outline
        label="Submit"
        :disable="
          (myStore.userdata.role == 'quoter' &&
            myStore.partListStatus == true) ||
          (myStore.userdata.role == 'buyer' &&
            myStore.partListStatus == false) ||
          AlifeCycle == 'New'
        "
        class="custom-button"
        color="primary"
        @click="UpdateNextStep"
        icon="save"
      ></q-btn>
    </q-card-actions>

    <q-table
      flat
      :rows="filteredRows"
      :columns="BOMColumn"
      row-key="mpn"
      v-if="!loader"
      v-model:pagination="pagination"
      class="my-sticky-header-table my-sticky-header-last-column-tableList"
      :expanded="expanded"
      v-model:fullscreen="fullScreenMode"
      :class="toggleClassFullscreen"
    >
      <template v-slot:top-left="props">
        <q-btn
          v-if="fullScreenMode"
          flat
          round
          dense
          :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
          @click="props.toggleFullscreen"
        />
        <q-input
          v-if="fullScreenMode && isSearchOpen"
          v-model="search"
          label="Search"
          outlined
          dense
          class="treeSearch2"
        >
          <template v-slot:append>
            <q-btn
              flat
              dense
              color="primary"
              size="sm"
              icon="clear"
              @click="closeSearch"
            />
          </template>
        </q-input>
        <q-btn
          v-if="isSearchOpen == false && fullScreenMode"
          rounded
          dense
          flat
          color="primary"
          icon="search"
          @click="toggleSearch"
        />
      </template>

      <template v-slot:header="props">
        <q-tr :props="props" class="text-center">
          <th>#</th>
          <q-th v-for="col in props.cols" :key="col.key" class="text-bold">
            {{ col.label }}
          </q-th>
        </q-tr>
        <!-- :class=" { 'selected-row': props.row.selected }" -->
      </template>

      <template v-slot:body="props">
        <template v-if="BomRows.length > 0">
          <q-tr
            :props="props"
            @click="selectRow(props.row)"
            :class="{ 'selected-row ': props.row.selected }"
          >
            <td style="width: 5%" class="text-center">
              {{ props.rowIndex + 1 }}
            </td>
            <q-td key="ppn" class="text-center">
              {{ props.row.ppn !== null ? props.row.ppn : "-" }}
            </q-td>
            <q-td
              key="ipn"
              class="text-center"
              @click="
                getVendorTable({
                  ipn: props.row.ipn,
                  is_avl_available: props.row.is_avl_available,
                }),
                  openSubTable(props.row)
              "
            >
              <q-icon
                v-show="props.row.is_avl_available"
                name="lan_out_lined"
                @click="toggleIpnExpansion(props.row)"
                color="blue"
                class="cursor-pointer"
              />

              <q-btn
                key="props.rowIndex"
                flat
                dense
                v-if="props.row.is_avl_available == true"
                class="selectedRowParts"
                @click="toggleIpnExpansion(props.row)"
              >
                {{ props.row.ipn || "null" }}</q-btn
              >
              <span
                key="props.rowIndex"
                flat
                dense
                v-if="props.row.is_avl_available == false"
              >
                {{ props.row.ipn || "null" }}
              </span>
            </q-td>
            <q-td key="manufacturer" class="text-center">
              {{ props.row.manufacturer }}
            </q-td>
            <q-td
              key="mpn"
              @click="
                getBomMpn({ mpn: props.row.mpn, qty_err: props.row.qty_err })
              "
              class="text-center"
            >
              <q-icon
                v-show="props.row.qty_err != null"
                name="error"
                color="red"
                @click="toggleMpnExpansion(props.row)"
                class="cursor-pointer"
              />

              <q-btn
                key="props.rowIndex"
                flat
                dense
                v-if="props.row.qty_err != null"
                class="selectedRowParts"
                @click="toggleMpnExpansion(props.row)"
              >
                {{ props.row.mpn || "null" }}
              </q-btn>

              <span
                key="props.rowIndex"
                flat
                dense
                v-if="props.row.qty_err == null"
              >
                {{ props.row.mpn || "null" }}
              </span>
            </q-td>
            <q-td key="description" class="text-center">
              {{ cleanupWords(props.row.description) }}...

              <q-tooltip
                anchor="bottom middle"
                self="top middle"
                :offset="[-5, -5]"
              >
                {{ props.row.description }}
              </q-tooltip>
            </q-td>
            <q-td key="required_quantity" class="text-center">
              {{ props.row.required_quantity }}
            </q-td>
            <q-td key="uom" class="text-center">
              {{ props.row.uom }}
            </q-td>
          </q-tr>
          <q-tr v-show="props.row.expandMpn">
            <q-td colspan="100%" class="bg-Accordion">
              <div style="padding: 1rem 8rem">
                <q-card>
                  <q-card-section
                    class="flex justify-between items-center bg-card-header"
                    style="padding: 5px !important"
                  >
                    <div class="text-h6">
                      Alternate MPN Suggestion For MPN: {{ props.row.mpn }}
                    </div>
                    <div>
                      <q-icon
                        name="close"
                        @click="toggleMpnExpansion(props.row)"
                        size="sm"
                      >
                        <q-tooltip
                          anchor="bottom middle"
                          self="top middle"
                          :offset="[-2, -2]"
                        >
                          Close
                        </q-tooltip>
                      </q-icon>
                    </div>
                  </q-card-section>
                  <q-separator />
                  <q-card-section style="padding: 0px !important">
                    <mpn-suggest :alternateMPN="alternateMPN"></mpn-suggest>
                  </q-card-section>
                  <q-card-actions align="right" class="q-px-lg">
                    <q-btn
                      dense
                      color="secondary text-black"
                      @click="
                        updateMpn(this.bom_name, { ipn: props.row.ipn }),
                          toggleMpnExpansion(props.row)
                      "
                      >Submit</q-btn
                    >
                    <q-btn
                      dense
                      class="cursor-pointer"
                      @click="toggleMpnExpansion(props.row)"
                      color="primary text-white"
                      >Cancel</q-btn
                    >
                  </q-card-actions>
                </q-card>
              </div>
            </q-td>
          </q-tr>

          <q-tr v-show="props.row.expandIpn">
            <q-td colspan="100%" class="bg-Accordion">
              <div style="padding: 1rem 8rem">
                <q-card>
                  <q-card-section
                    class="flex justify-between items-center q-px-sm bg-card-header"
                  >
                    <div class="text-h6">
                      Alternate Vendor List for IPN: {{ props.row.ipn }}
                    </div>
                    <div>
                      <q-icon
                        name="close"
                        @click="toggleIpnExpansion(props.row)"
                        size="sm"
                        class="cursor-pointer"
                      >
                        <q-tooltip
                          anchor="bottom middle"
                          self="top middle"
                          :offset="[-2, -2]"
                        >
                          Close
                        </q-tooltip>
                      </q-icon>
                    </div>
                  </q-card-section>

                  <q-separator />
                  <q-card-section style="padding: 0px !important">
                    <!-- <asl-table @selectedSpn="updateSpn" @Quantity="QuantityData" :mpn="mpn" :spn="spn"
                                :bom_name="bom_name" :subtabledata="VendorList" :priceBreakData="priceBreakData"
                                :defaultQty="defaultPrimaryQty1" :selectedQty="selectedQty" :defaultimgSrc="defaultimgSrc"
                                :defaultDataSheet="defaultDataSheet" :primaryManufacturer="primaryManufacturer"
                                :manualEmit="manualEmit" @toggleClose="toggleClose"></asl-table> -->

                    <vendor-table
                      :VendorList="VendorList"
                      :description="props.row.description"
                    ></vendor-table>
                  </q-card-section>
                  <!-- <q-card-actions align="right" class="q-px-lg">
                  <q-btn dense color="secondary text-black" :loading="loading"
                    @click="UpdateAlternateVendor({ mpn: props.row.mpn, ipn: props.row.ipn, rows: props.row }), toggleIpnExpansion(props.row)">Submit</q-btn>
                  <q-btn dense @click="toggleIpnExpansion(props.row)" color="primary text-white">Cancel</q-btn>

                </q-card-actions> -->
                </q-card>
              </div>
            </q-td>
          </q-tr>
        </template>
        <template v-else> No data Found </template>
      </template>
    </q-table>
    <div
      class="row justify-between q-mt-sm"
      v-if="!loader && filteredRows.length > 0"
    >
      <div class="q-ml-md text-grey-8 hint-fullscreenBom">
        <b>Tip: &nbsp;</b> Press &nbsp;<span class="fullscreenHintText">
          Shift + F</span
        >
        &nbsp; {{ toggleFullscreenTip }}
      </div>
    </div>
  </q-card>
</template>

<script>
import { ref, computed, watch, reactive } from "vue";
import { SpinnerComponent, commonFunction } from "@/utils";
import VendorTable from "@/components/quotes/subtable/VendorTable.vue";
// import AslTable from "../quotes/subtable/AslTable.vue"
import { QuotesService, AlertService } from "@/services";
import MpnSuggest from "@/components/quotes/subtable/MpnSuggest.vue";
import { useMyStore } from "@/store";
import { AppFullscreen } from "quasar";
export default {
  name: "BomExtract",
  props: ["bom_name", "AlifeCycle"],
  components: {
    MpnSuggest,
    SpinnerComponent,
    VendorTable,
    // AslTable
  },

  setup() {
    const myStore = useMyStore();
    const pagination = ref({
      sortBy: "desc",
      descending: false,
      page: 1,
      rowsPerPage: 25,
    });

    // Watch for changes in rowsPerPage and update local storage
    watch(
      () => pagination.value.rowsPerPage,
      (newRowsPerPage) => {
        localStorage.setItem("rowsPerPage", newRowsPerPage);
      }
    );

    // Load rowsPerPage from local storage on component mount
    const initializeData = () => {
      const storedRowsPerPage = localStorage.getItem("rowsPerPage");
      if (storedRowsPerPage) {
        pagination.value.rowsPerPage = parseInt(storedRowsPerPage, 10);
      }
    };

    // Call the initialization method
    initializeData();

    const search = ref("");
    const BOMColumn = ref([]);
    // const rfq = ref([]);
    // const defaultPrimaryQty1 = ref("");
    const BomRows = ref([]);
    const lazy = ref(false);
    const Bomheader = ref([]);
    const filteredRows = computed(() => {
      const searchTerm = search.value.toLowerCase();
      return BomRows.value.filter((row) => {
        const ppnCheck = row.ppn && row.ppn.toLowerCase().includes(searchTerm);
        const ipnCheck = row.ipn && row.ipn.toLowerCase().includes(searchTerm);
        const mpnCheck = row.mpn && row.mpn.toLowerCase().includes(searchTerm);
        const manufacturerCheck =
          row.manufacturer &&
          row.manufacturer.toLowerCase().includes(searchTerm);
        const desCheck =
          row.description && row.description.toLowerCase().includes(searchTerm);
        return (
          ppnCheck || ipnCheck || mpnCheck || manufacturerCheck || desCheck
        );
      });
    });

    return {
      pagination,
      myStore,
      expanded: ref([]),
      BOMColumn,
      BomRows,
      search,
      lazy,
      filteredRows,
      selectedRow: null,
      Bomheader,
      alternateMPN: ref([]),
      VendorList: ref([]),
      loader: ref(true),
      initialPagination: {
        sortBy: "desc",
        descending: true,
        page: 1,
        rowsPerPage: 10,
      },
      selectedIpn: null,
      isSearchOpen: ref(false),
      fullScreenMode: ref(false),
      BomlifeStatus: ref("In Sourcing"),
    };
  },
  emits: [
    "backToRfq",
    "bomIpn",
    "updateSource",
    "disablePrice",
    "bom-ipn",
    "updateBom",
  ],
  created() {
    this.getBomName();
  },
  computed: {
    titledata() {
      var oemExpand = [];
      oemExpand = JSON.parse(localStorage.getItem("expandedNodes"));
      oemExpand.push(this.bom_name);
      return oemExpand;
    },
    rfq() {
      return this.myStore.newArray;
    },
    toggleClassFullscreen() {
      if (AppFullscreen.isActive) {
        return "fullscreenHeightBom my-sticky-header-table my-sticky-header-last-column-table";
      } else {
        return "TableHeightBom my-sticky-header-table my-sticky-header-last-column-tableList";
      }
    },
    toggleFullscreenTip() {
      if (AppFullscreen.isActive) {
        return "to exit Fullscreen ";
      } else {
        return "to enter Fullscreen";
      }
    },
    toggleicon() {
      if (AppFullscreen.isActive) {
        return "fullscreen_exit";
      } else {
        return "fullscreen";
      }
    },
    selectedQtyLabel() {
      return `Qty:${this.myStore.GlobalQty}`;
    },
    refreshpricing() {
      return this.myStore.refreshpricing;
    },
    selectedQty() {
      return this.myStore.GlobalQty;
    },
  },
  watch: {
    bom_name(newBomName, oldBomName) {
      if (newBomName !== oldBomName) {
        this.pagination.page = 1;
        this.search = "";
        this.isSearchOpen = false;
        setTimeout(() => {
          this.getBomName();
        }, 300);
      }
    },
    refreshpricing() {
      this.getBomName();
    },
  },
  beforeUnmount() {
    // Remove event listener when component is unmounted
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    handleKeyDown(event) {
      // Check if F11 key is pressed
      if (event.shiftKey && event.key == "F") {
        event.preventDefault(); // Prevent default F11 behavior (like browser fullscreen)
        this.toggleFullScreen();
      }
    },
    showTree() {
      this.myStore.isCardVisible = true;
      this.myStore.isToastVisible = true;
    },
    // Format the header row Breadcrumbs
    formattedString(string) {
      const res = commonFunction.BreadcrumbFormat(string);
      return res;
    },
    toggleExpanded(val) {
      this.expanded = this.expanded[0] === val ? [] : [val];
    },
    toggleRowExpansion(row) {
      const rowIndex = this.expanded.indexOf(row.mpn);

      if (rowIndex !== -1) {
        // close Expand row
        this.expanded.splice(rowIndex, 1);
      } else {
        //open selected one
        this.expanded = [row.mpn];
      }
    },

    toggleIpnExpansion(row) {
      row = reactive(row);

      // close Expand row ipn
      this.BomRows.forEach((otherRow) => {
        if (otherRow !== row) {
          otherRow = reactive(otherRow);
          otherRow.expandIpn = false;
        }
      });

      // Toggle the clicked row
      row.expandIpn = !row.expandIpn;

      if (row.expandIpn) {
        // close Expand row mpn
        this.BomRows.forEach((otherRow) => {
          if (otherRow !== row) {
            otherRow = reactive(otherRow);
            otherRow.expandMpn = false;
          }
        });
      }
    },

    toggleMpnExpansion(row) {
      row = reactive(row);
      row.expandMpn = !row.expandMpn;

      if (row.expandMpn) {
        // Close other expanded ipn rows
        this.BomRows.forEach((otherRow) => {
          if (otherRow !== row) {
            otherRow = reactive(otherRow);
            otherRow.expandIpn = false;
          }
        });
      }
    },
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
      if (this.isSearchOpen) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      }
    },
    closeSearch() {
      this.search = "";
      this.isSearchOpen = false;
    },
    toggleFullScreen() {
      // this.fullScreenMode = !this.fullScreenMode;
      const target = this.$refs.fullscreenTarget.$el;
      AppFullscreen.toggle(target)
        .then(() => {
          // success!
        })
        .catch((err) => {
          alert(err);
        });
    },
    options(type) {
      localStorage.setItem("qty", type);
      this.myStore.GlobalQty = type;
      this.myStore.refreshpricing = Math.random();
    },
    getBomMpn(data) {
      (this.mpn = data.mpn), (this.qty_err = data.qty_err);

      console.log("mpnn", this.mpn);

      if (this.mpn != null && this.qty_err != null) {
        QuotesService.getMpnSuggest("mpn", this.mpn).then((x) => {
          this.alternateMPN = x;
          console.log(" this.alternateMPN", this.alternateMPN);
        });
      }
    },
    async UpdateNextStep() {
      if (this.AlifeCycle == "In BOM Extract") {
        await QuotesService.UpdateLifeCycle(
          this.bom_name,
          this.BomlifeStatus
        ).then((x) => {
          console.log(x);
          this.myStore.refreshpricing = Math.random();
          AlertService.Success(
            this.myStore.partListStatus == false
              ? `${this.myStore.treeLabels[4]} Submitted Successfully`
              : `${this.myStore.treeLabels[3]} Submitted Successfully`
          );
        });
        //await QuotesService.updatePricingBom(this.bom_name).then((p) => { console.log(p); })
        this.$emit("updateBom", 3, this.BomlifeStatus);
      } else {
        this.$emit("updateBom", 3);
      }
    },
    getVendorTable(data) {
      if (data.ipn != null && data.is_avl_available == true) {
        QuotesService.getVendorList(this.bom_name, data.ipn, this.lazy).then(
          (x) => {
            this.VendorList = x;
          }
        );
      }
    },
    openSubTable(selectedBox) {
      try {
        this.selectedIpn = selectedBox.ipn;
        this.$emit("selectedIpn", this.selectedIpn);
      } catch {
        console.log("Not Found");
      }
    },

    updateMpn(bom_name, data) {
      const body = {
        mpn: data.mpn,
      };
      QuotesService.updateAltenateMpn(bom_name, data.ipn, body)
        .then((x) => AlertService.Success(x))
        .catch((err) => AlertService.Error(err));
    },
    UpdateAlternateVendor(data) {
      this.loading = true;
      const body = {
        mpn: data.mpn,
        ppn: data.rows.ppn,
        ipn: data.rows.ipn,
        description: data.rows.description,
        quantity: data.rows.quantity,
        uom: data.rows.uom,
      };
      QuotesService.UpdateVendor(this.bom_name, data.ipn, body)
        .then((x) => {
          this.loading = false;
          AlertService.Success(x);
        })
        .catch((err) => {
          this.loading = false;
          AlertService.Error(err);
        });
    },

    async getBomName() {
      try {
        var ignorefields = ["mfn_err", "qty_err"];
        this.BOMColumn = this.myStore.fields.bom_fields.filter(
          (field) => !ignorefields.includes(field.key)
        );
        this.loader = true;
        const BomQty = JSON.parse(localStorage.getItem("bomname-qty")) || [];
        if (this.bom_name != "" && this.bom_name != null) {
          const x = await QuotesService.getBomExtract(
            this.bom_name,
            this.lazy,
            BomQty[1]
          );
          if (x.length > 0) {
            this.BomRows = x;
            const firstRow = localStorage.getItem("ipn");
            if (firstRow !== "null" && firstRow !== undefined) {
              const Qrow =
                this.BomRows.find((row) => row.ipn === firstRow) || null;

              if (Qrow) {
                this.selectRow(Qrow);
              } else {
                const SRow = this.BomRows[0];
                this.selectRow(SRow);
              }
            } else {
              const SRow = this.BomRows[0];
              this.selectRow(SRow);
            }
          } else {
            this.BomRows = [];
          }
          this.loader = false;
        }
      } catch (err) {
        this.loader = false;
        this.BomRows = [];
        console.error(err);
      }
    },

    cleanupWords(description) {
      const words = commonFunction.descriptionFormat(description);
      return words;
    },
    selectRow(row) {
      this.$emit("bom-ipn", row.ipn);
      localStorage.setItem("ipn", row.ipn);
      // Unselect previously selected row
      if (this.selectedRow) {
        this.selectedRow.selected = false;
      }
      // Select the clicked row
      row.selected = true;
      this.selectedRow = row;
    },
  },
  mounted() {
    try {
      setTimeout(() => {
        this.getBomName();
        document.addEventListener("keydown", this.handleKeyDown);
      }, 1000);
    } catch (error) {
      console.error("Error in component:", error);
    }
  },
};
</script>

<style scoped>
.bg-card-header {
  background: #ffc189;
  color: black;
  padding: 5px 10px;
}

.fullScreenHeightBom {
  height: 93%;
}

.TableHeightBom {
  height: 85%;
}
/* new style 08 */
.filterhead {
  display: flex;
  gap: 5px;
}
</style>
