<template>
  <q-page class="q-pa-sm layout">
    <div class="page-heading q-pa-sm">Orders</div>
    <OrderTable />
  </q-page>
</template>

<script>
import { useMyStore } from "@/store";
import OrderTable from "@/components/orders/OrderTable.vue";

export default {
  setup() {
    const myStore = useMyStore();

    return {
      myStore,
    };
  },
  components: {
    OrderTable,
  },

  methods: {},
  mounted() {},
};
</script>
