<script>
import { computed, nextTick, ref } from "vue";
import moment from "moment";

export default {
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    maxWidth: {
      type: String,
      default: "100%",
    },
  },
  emits: ["update:modelValue", "invalidDate"],

  setup(props, { emit }) {
    const from = computed({
      get() {
        return props.modelValue.from;
      },
      set(value) {
        emit("update:modelValue", { ...props.modelValue, from: value });
      },
    });

    const to = computed({
      get() {
        return props.modelValue.to;
      },
      set(value) {
        emit("update:modelValue", { ...props.modelValue, to: value });
      },
    });

    const fromToError = ref("");
    const hasError = ref({
      from_date: false,
      to_date: false,
    });
    const invalid_date = ref(false);

    function validateDate(value) {
      return moment(value, "YYYY-MM-DD", true).isValid();
    }

    function checkBeforeStartDate(value, from) {
      const start = moment(from.value, "YYYY-MM-DD", true);
      const to = moment(value, "YYYY-MM-DD", true);
      return to.isBefore(start);
    }

    function onFromDateUpdated(value) {
      if (validateDate(value)) {
        fromToError.value = "";
        hasError.value.from_date = false;
        invalid_date.value = false;
        emit("invalidDate", invalid_date.value);
        from.value = value;
        if (value) {
          nextTick(() => {
            to.value = ""; // Reset the "to" value
          });
        }
      } else {
        hasError.value.from_date = true;
        fromToError.value = "Enter a valid date (YYYY-MM-DD)";
        invalid_date.value = true;
        emit("invalidDate", invalid_date.value);
      }
    }

    function onToTimeUpdated(value) {
      if (validateDate(value) && !checkBeforeStartDate(value, from)) {
        fromToError.value = "";
        hasError.value.to_date = false;
        invalid_date.value = false;
        emit("invalidDate", invalid_date.value);
        if (value) {
          const fromDateTime = moment(from.value, "YYYY-MM-DD HH:mm");
          const fromDate = fromDateTime.format("YYYY-MM-DD");
          const datePart = value.split(" ")[0];
          const timePart = value.split(" ")[1];
          if (fromDate === datePart) {
            if (timePart === "00:00") {
              const incrementedTime = fromDateTime.clone().add(1, "hours");
              if (
                incrementedTime.isAfter(
                  moment(`${datePart} 23:59`, "YYYY-MM-DD HH:mm")
                )
              ) {
                const newDatePart = incrementedTime.format("YYYY-MM-DD");
                const newTimePart = incrementedTime.format("HH:mm");
                to.value = `${newDatePart} ${newTimePart}`;
              } else {
                to.value = `${datePart} ${incrementedTime.format("HH:mm")}`;
              }
            }
          } else {
            to.value = value;
          }
        } else {
          to.value = ""; // Reset if empty
        }
      } else {
        hasError.value.to_date = true;
        if (checkBeforeStartDate(value, from)) {
          fromToError.value = "End date cannot be less than start date";
        } else {
          fromToError.value = "Enter a valid date (YYYY-MM-DD)";
        }
        // fromToError.value = "Enter a valid date (YYYY-MM-DD)";
        invalid_date.value = true;
        emit("invalidDate", invalid_date.value);
      }
    }

    function optionsFn(date) {
      const fromDate = moment(from.value).format("YYYY/MM/DD");
      return date >= fromDate;
    }

    return {
      from,
      to,
      fromToError,
      hasError,
      onFromDateUpdated,
      onToTimeUpdated,
      optionsFn,
      invalid_date,
    };
  },
};
</script>

<template>
  <div class="dateRange-wrapper">
    <!-- from date -->
    <q-input
      outlined
      v-model="from"
      :style="{
        maxWidth,
      }"
      dense
      label="Start Date"
      placeholder="YYYY-MM-DD"
      @blur="onFromDateUpdated(from)"
      :error="hasError.from_date"
      :error-message="fromToError"
    >
      <template v-slot:prepend>
        <q-icon name="event" class="cursor-pointer">
          <q-popup-proxy cover transition-show="scale" transition-hide="scale">
            <q-date
              v-model="from"
              @update:model-value="onFromDateUpdated"
              mask="YYYY-MM-DD"
            >
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Ok" color="primary" flat />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>

    <!-- to date -->
    <q-input
      outlined
      v-model="to"
      :style="{
        maxWidth,
      }"
      dense
      label="End Date"
      placeholder="YYYY-MM-DD"
      @blur="onToTimeUpdated(to)"
      :error="hasError.to_date"
      :error-message="fromToError"
    >
      <template v-slot:prepend>
        <q-icon name="event" class="cursor-pointer">
          <q-popup-proxy cover transition-show="scale" transition-hide="scale">
            <q-date
              v-model="to"
              mask="YYYY-MM-DD"
              :options="optionsFn"
              @update:model-value="onToTimeUpdated"
            >
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Ok" color="primary" flat />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>
  </div>
</template>

<style lang="scss">
.dateRange-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.text-align {
  text-align: center;
  color: #4f6f77;
}
</style>
