import { fetchWrapper } from "@/helpers/fetch-wrapper";
import { API_BASE_URL } from "@/api.config";
import axios from "axios";
//import axios from "axios";
// import { useMyStore } from '@/store';
export const OrderService = {
  getAllOrders,
  getOrderFilter,
  getOrdersSubTable,
  getExportQuote,
  searchOrders,
};

const apiUrl = `${API_BASE_URL}`;

// get orders
function getAllOrders(body) {
  return fetchWrapper.post(`${apiUrl}/open-order/list`, body);
}
// fetch filters
function getOrderFilter() {
  return fetchWrapper.get(`${apiUrl}/open-order/filters`);
}
// Export Order List
async function getExportQuote(body) {
  const cmuser2 = JSON.parse(localStorage.getItem("cmuser")) || {
    access_token: null,
  };

  try {
    const response = await axios.post(
      `${apiUrl}/open-order/list/export`,
      body,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/json",
          "X-API-Key": cmuser2.access_token,
          "Content-Type": "application/json",
          responseType: "arraybuffer",
        },
      }
    );
    // Extract filename from Content-Disposition header
    let filename = "OrderList.xlsx"; // Default filename
    const contentDisposition = response.headers["content-disposition"];
    if (contentDisposition && contentDisposition.includes("filename")) {
      const filenameMatch = contentDisposition.match(
        /filename[^;=\n]*=(['"]?)([^'"\n]*)\1/
      );
      if (filenameMatch && filenameMatch[2]) {
        filename = filenameMatch[2];
      }
    }
    // Create a Blob from the array buffer
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    // Create a link element and trigger a download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading Excel file:", error);
  }
}
//OrderSubTable
function getOrdersSubTable(po_number, mpn) {
  let newMpn = encodeURIComponent(encodeURIComponent(mpn));
  return fetchWrapper.get(
    `${apiUrl}/open-order/supplier/response/${po_number}/${newMpn}`
  );
}
//search order
function searchOrders(search_input) {
  return fetchWrapper.post(
    `${apiUrl}/open-order/search?search_input=${search_input}`
  );
}
